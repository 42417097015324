import { useMsal } from "@azure/msal-react";
import React, { useState } from "react";
import { Link } from "react-router-dom";

const UserNotFound = () => {
  const { instance } = useMsal();

  const [toggleHover, setToggleHover] = useState(false);

  const handletoggleHover = () => {
    setToggleHover((prev) => !prev);
  };

  const clearCustomToken = () => {
    localStorage.removeItem("customToken");
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
        overflow: "hidden",
        backgroundColor: "#fff",
      }}
    >
      <div className="row">
        <div className="col-md-12 align-self-center text-center">
          <div className="d-flex justify-content-center align-items-center">
            <h1 style={{ color: "#ff9b44" }}>404 - USER NOT FOUND</h1>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-md-12 align-self-center text-center">
          <div className="d-flex justify-content-center align-items-center">
            <h2 style={{ color: "#ff9b44" }}>Oops, User not found.</h2>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-md-12 align-self-center text-center">
          <div className="d-flex justify-content-center align-items-center">
            <p style={{ color: "#ff9b44" }}>
              Sorry, the user you are trying to access could not be found. Our
              system has returned a status code of 404, indicating that the
              <br></br>
              requested resource could not be found on the server. Please try
              again later or check your input to make sure it is correct.
            </p>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-md-12 align-self-center text-center">
          <div className="d-flex justify-content-center align-items-center">
            <div
              style={{
                margin: "30px",
                fontWeight: 700,
                border: "2px solid #ff9b44",
                textDecoration: "none",
                padding: "15px",
                textTransform: "uppercase",
                color: "#354f7eff",
                borderRadius: "26px",
                transition: "all 0.2s ease-in-out",
                display: "inline-block",
              }}
              onClick={() => {
                clearCustomToken();
                instance.logoutRedirect({
                  postLogoutRedirectUri: "/",
                });
              }}
            >
              <Link
                to={"/logout"}
                style={
                  toggleHover ? { color: "#ff9b44" } : { color: "#ff9b44" }
                }
                onMouseEnter={handletoggleHover}
                onMouseLeave={handletoggleHover}
              >
                Go to LoginPage
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserNotFound;
