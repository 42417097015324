/*eslint-disable*/
import React, { useContext, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Accordion } from "react-bootstrap";
import Loader from "./Loader";
import { UserContext } from "../hooks/UserContext";
import UserService from "../services/User.service";
import SignOutButton from "./SignOutButton";
import ProfileModal from "./ProfileModal";

import { getCustomToken } from "../helpers/utils";

const packageJson = require("../../package.json");

function Sidebar() {
  const { currentUser } = useContext(UserContext);

  const customToken = getCustomToken();

  const [isLoading, setIsLoading] = useState(true);
  const [isProfileModalVisible, setIsProfileModalVisible] = useState(false);

  // useEffect(() => {
  //   if (currentUser) {
  //     // If currentUser is defined, set isLoading to false
  //     setIsLoading(false);
  //   }
  // }, []);

  const handleProfileIconClick = (e) => {
    e.preventDefault(); // This will prevent navigating away
    setIsProfileModalVisible(true); // Open the modal
  };

  let navigate = useNavigate();

  const activeClass = (path) => {
    const result =
      window.location.pathname === path
        ? { color: "#ff9b44" }
        : { color: "black" };

    return result;
  };

  const routeHome = () => {
    let path = `/home`;
    navigate(path);
  };
  const routeredeem = () => {
    let path = `/redeem`;
    navigate(path);
  };
  const routeUsers = () => {
    let path = `/users`;
    navigate(path);
  };
  const routeBanner = () => {
    let path = `/banners`;
    navigate(path);
  };
  const routeReports = () => {
    let path = `/reports`;
    navigate(path);
  };
  const routeHistory1 = () => {
    let path = `/history`;
    navigate(path);
  };
  const routeHistory2 = () => {
    let path = `/rewards/history`;
    navigate(path);
  };

  const routeReward1 = () => {
    let path = `/rewards`;
    navigate(path);
  };

  const routeReward2 = () => {
    let path = `/rewards/users`;
    navigate(path);
  };
  const routecreategroup = () => {
    let path = `/groups`;
    navigate(path);
  };

  // const { accounts } = useMsal();
  // eslint-disable-next-line no-unused-vars
  const [graphData, setGraphData] = useState(null);

  // const name = accounts[0] && accounts[0].name;

  // const [theme, setTheme] = useState("light");
  // const toggleTheme = () => {
  //   if (theme === "light") {
  //     setTheme("dark");
  //   } else {
  //     setTheme("light");
  //   }
  // };
  // useEffect(() => {
  //   document.documentElement.className = theme;
  // }, [theme]);

  // const [notify, setNotify] = useState("");
  // const [NotificationCount, setNotificationCount] = useState(5);

  // const toggleNotifi = () => {
  //   if (notify === "") {
  //     setNotify("notify-sidebar-open");
  //   } else {
  //     setNotify("");
  //   }
  //   setNotificationCount(0);
  // };

  // useEffect(() => {
  //   document.body.className = notify;
  //   //document.querySelectorAll("aside.control-sidebar.control-sidebar-light")[0] = notify;
  // }, [notify]);

  // eslint-disable-next-line no-unused-vars
  // const [loading, setLoading] = useState(true);
  // eslint-disable-next-line no-unused-vars
  const [emailFromToken, setEmailFromToken] = useState("");
  // eslint-disable-next-line no-unused-vars
  const [profileData, setProfileData] = useState("");

  const ProfilePicBase =
    "https://evolutyzblobimages.blob.core.windows.net/unmaze/";

  const defaultAvatarURL =
    "https://ezcorner.evolutyz.com/uploadimages/images/User.png";

  const fetchData = async () => {
    console.log("currentUser->:", currentUser);

    // You can access currentUser here
    // const userEmail = currentUser ? currentUser.email : null;

    // if (userEmail === null) {
    //   alert("User does not exist in the database.");
    //   setLoading(false);
    // } else {

    if (currentUser.email !== undefined) {
      UserService.getProfile()
        .then((response) => {
          console.log("jsonabc:", response);
          setProfileData(response.data);
        })
        .catch((error) => {
          console.error(error.message);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  };

  useEffect(() => {
    if (customToken) fetchData();
  }, [currentUser]);

  const [isError, setisError] = useState(false);

  return (
    <div className="sidebar" id="sidebar">
      {isLoading ? (
        <div className="sidebar-inner" style={{ backgroundColor: "#fff" }}>
          {/* <Loader /> */}
        </div>
      ) : (
        <div className="sidebar-inner">
          <Accordion id="sidebar-menu" className="sidebar-menu">
            <ul>
              {/* <Accordion.Item as="li" className="submenu mt-3" eventKey="0">
                <Accordion.Button as="a" onClick={routeHome} style={{}}>
                  <i className="la la-home" style={activeClass("/home")}></i>{" "}
                  <span style={activeClass("/home")}>Home</span>
                </Accordion.Button>
              </Accordion.Item> */}

              {/* <Accordion.Item as="li" className="submenu mt-0" eventKey="0">
              <Accordion.Button as="a" onClick={routeredeem} style={{}}>
                <i
                  className="las la-rupee-sign"
                  style={activeClass("/redeem")}
                ></i>{" "}
                <span style={activeClass("/redeem")}>Redeem</span>
              </Accordion.Button>
            </Accordion.Item> */}

              {["ADMIN", "USER"].includes(currentUser?.role) && (
                <>
                  <li className="profile-icon" onClick={handleProfileIconClick}>
                    <Link className="p-0 table-avatar d-flex align-items-center justify-content-center mt-2">
                      <div className="avatar p-0 mr-0">
                        <img
                          src={
                            isError
                              ? defaultAvatarURL
                              : profileData
                              ? ProfilePicBase + profileData.employeeID + ".jpg"
                              : defaultAvatarURL
                          }
                          alt={
                            isError
                              ? "Default avatar image"
                              : profileData
                              ? profileData.employeeID + "'s profile picture"
                              : "Default avatar image"
                          }
                          onError={() => {
                            setisError(true);
                          }}
                        />
                      </div>
                    </Link>

                    <span className="d-block h6 ml-2 mt-1 mb-0 text-center">
                      {profileData?.display_name}
                    </span>
                    <span className="d-block text-muted h6 mb-0 text-center">
                      {profileData?.department}
                    </span>
                  </li>
                  {isProfileModalVisible && (
                    <ProfileModal
                      profileData={profileData}
                      onClose={() => setIsProfileModalVisible(false)}
                    />
                  )}

                  <Accordion.Item as="li" className="submenu mt-3" eventKey="0">
                    <Accordion.Button as="a" onClick={routeHome} style={{}}>
                      <i
                        className="la la-home"
                        style={activeClass("/home")}
                      ></i>{" "}
                      <span style={activeClass("/home")}>Home</span>
                    </Accordion.Button>
                  </Accordion.Item>

                  <Accordion.Item as="li" className="submenu mt-0" eventKey="0">
                    <Accordion.Button as="a" onClick={routeredeem} style={{}}>
                      <i
                        className="las la-rupee-sign"
                        style={activeClass("/redeem")}
                      ></i>{" "}
                      <span style={activeClass("/redeem")}>Redeem</span>
                    </Accordion.Button>
                  </Accordion.Item>
                  <Accordion.Item as="li" eventKey="1">
                    <Accordion.Button as="a" onClick={routeHistory1}>
                      <i
                        className="las la-history"
                        style={activeClass("/history")}
                      ></i>{" "}
                      <span style={activeClass("/history")}>History</span>
                    </Accordion.Button>
                  </Accordion.Item>
                </>
              )}
              {currentUser?.role === "ADMIN" && (
                <>
                  <div className="sidebar-divider"></div>
                  <Accordion.Item as="li" className="submenu mt-0" eventKey="1">
                    <Accordion.Button as="a" onClick={routeUsers}>
                      <i
                        className="la la-users-cog"
                        style={activeClass("/users")}
                      ></i>{" "}
                      <span style={activeClass("/users")}>Users</span>
                    </Accordion.Button>
                  </Accordion.Item>
                  <Accordion.Item as="li" className="submenu" eventKey="1">
                    <Accordion.Button as="a" onClick={routecreategroup}>
                      <i
                        className="la la-group"
                        style={activeClass("/groups")}
                      ></i>{" "}
                      <span style={activeClass("/groups")}>Groups</span>
                    </Accordion.Button>
                  </Accordion.Item>
                  <Accordion.Item as="li" className="submenu" eventKey="3">
                    <Accordion.Button as="a">
                      <i className="la la-award"></i>
                      <span>Rewards Hub</span>{" "}
                      <span className="menu-arrow"></span>
                    </Accordion.Button>
                    <Accordion.Body
                      as="ul"
                      className="submenu"
                      style={{ display: "block" }}
                    >
                      <Accordion.Item
                        as="li"
                        className="submenu mt-0"
                        eventKey="0"
                      >
                        <Accordion.Button
                          as="a"
                          onClick={routeReward1}
                          style={{}}
                        >
                          <i
                            className="la la-trophy mr-1"
                            style={activeClass("/rewards")}
                          ></i>{" "}
                          <span style={activeClass("/rewards")}>
                            Rewards Management
                          </span>
                        </Accordion.Button>
                      </Accordion.Item>

                      <Accordion.Button
                        as="a"
                        onClick={routeReward2}
                        style={{}}
                      >
                        <i
                          className="la la-medal mr-1"
                          style={activeClass("/rewards/users")}
                        ></i>{" "}
                        <span style={activeClass("/rewards/users")}>
                          Assign Reward
                        </span>
                      </Accordion.Button>
                      {/* 
                      <Accordion.Button
                        as="a"
                        onClick={routeHistory2}
                        style={{}}
                      >
                        <i
                          className="la la-history mr-1"
                          style={activeClass("/rewards/history")}
                        ></i>{" "}
                        <span style={activeClass("/rewards/history")}>
                          Reward History
                        </span>
                      </Accordion.Button> */}

                      {/* <li>
                        <Link onClick={routeHistory2}>
                          <i
                            className="la la-history mr-1"
                            style={activeClass("/rewards/users")}
                          ></i>{" "}
                          <span style={activeClass("/rewards/users")}>
                            Reward Reports
                          </span>
                        </Link>
                      </li> */}
                    </Accordion.Body>
                  </Accordion.Item>

                  <Accordion.Item as="li" className="submenu" eventKey="1">
                    <Accordion.Button as="a" onClick={routeBanner}>
                      <i
                        className="la la-image"
                        style={activeClass("/banners")}
                      ></i>{" "}
                      <span style={activeClass("/banners")}>Banners</span>
                    </Accordion.Button>
                  </Accordion.Item>

                  <Accordion.Item as="li" className="submenu" eventKey="1">
                    <Accordion.Button as="a" onClick={routeReports}>
                      <i
                        className="la la-file-alt"
                        style={activeClass("/reports")}
                      ></i>{" "}
                      <span style={activeClass("/reports")}>Reports</span>
                    </Accordion.Button>
                  </Accordion.Item>
                </>
              )}

              {currentUser?.role === "USER" && <></>}
            </ul>
          </Accordion>

          {["ADMIN", "USER"].includes(currentUser?.role) && (
            <div className="sidebar-menu">
              <ul className="logout-mode">
                <li>
                  <SignOutButton />
                </li>

                <div
                  style={{
                    fontFamily: "Roboto, sans-serif",
                    fontSize: "0.8rem",
                    color: "#8d97ad",
                    textAlign: "center",
                    // marginTop: "1rem",
                    fontWeight: "bold",
                  }}
                >
                  version: {packageJson.version}
                </div>
              </ul>
            </div>
          )}
        </div>
      )}
    </div>
  );
}

export default Sidebar;
