import React, { useState, useContext, useEffect } from "react";
import { Link } from "react-router-dom";
import RedeemService from "../services/Redeem.service"; // Adjust import as per your project structure
import Skeleton from "react-loading-skeleton"; // If you are using this for loading states
import { UserContext } from "../hooks/UserContext"; // Adjust import as per your project structure
import ClipboardCopy from "../components/ClipboardCopy"; // Adjust import as per your project structure

function History() {
  const { currentUser } = useContext(UserContext);

  // State variables specific to history
  const [isLoadingHistory, setIsLoadingHistory] = useState(true);
  const [PointHistory, setPointHistory] = useState([]);

  const Options = [
    {
      id: 1,
      name: "All",
    },

    {
      id: 2,
      name: "Redeemed",
    },

    {
      id: 3,
      name: "Earned",
    },
  ];

  const [optionSelected, setOption] = useState(1);

  const fetchData = async () => {
    if (currentUser.email !== undefined) {
      RedeemService.getProfileHistory()
        .then((response) => {
          setPointHistory(response.data);
        })
        .catch((error) => {
          console.error("Error fetching history:", error.message);
        })
        .finally(() => {
          setIsLoadingHistory(false);
        });
    }
  };

  // Other functions (filterPointHistory, getTimeDifference) go here

  useEffect(() => {
    fetchData();
  }, [currentUser]);

  // Filter history data based on event type
  const filterPointHistory = (data, eventType) => {
    const filterMapper = {
      1: (item) => true, // Return all items
      2: (item) => item.event_type === "DB", // Return credited items
      3: (item) => item.event_type === "CR", // Return debited items
    };

    return data.filter(filterMapper[eventType] || ((item) => false));
  };

  // Function to calculate time difference
  function getTimeDifference(givenTime) {
    // Parse the given time string into a Date object

    const givenDate = new Date(givenTime);

    console.log(`givenDate: ${givenDate}`);

    // Get the current time

    const currentTime = new Date();

    // Calculate the time difference in milliseconds

    const timeDifference = currentTime - givenDate;

    // Calculate hours and minutes

    const hoursDifference = Math.floor(timeDifference / (1000 * 60 * 60));

    const minutesDifference = Math.floor((timeDifference / (1000 * 60)) % 60);

    if (hoursDifference < 1) {
      return `${Math.abs(minutesDifference)} minutes ago`;
    } else if (hoursDifference < 24) {
      return `${hoursDifference} hours ago`;
    } else if (hoursDifference >= 24 && hoursDifference < 36) {
      return "Yesterday";
    } else {
      // Format the givenTime as a date (e.g., "2023-09-12")

      const formattedDate = givenDate.toISOString().split("T")[0];

      return formattedDate;
    }
  }

  // useEffect hook to fetch data on component mount
  useEffect(() => {
    fetchData();
  }, [currentUser]);

  return (
    <div className="page-wrapper" style={{ backgroundColor: "white" }}>
      <div className="content container-fluid">
        {/* Breadcrumb */}
        <div className="page-header">
          <div className="row">
            <div className="col">
              <h3 className="page-title"> History</h3>
              <ul className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="/home">
                    <span>
                      <i className="la la-home"></i> Home
                    </span>
                  </Link>
                </li>
                <li className="breadcrumb-item active">
                  <i className="las la-history mr-1"></i>History
                </li>
              </ul>
            </div>
          </div>
        </div>
        {/* End of Breadcrumb */}

        <div className="row mt-5 mb-2">
          {PointHistory.length > 0 && (
            <div className="col-md-12 text-right">
              <select
                className="btn  dropdown-toggle"
                style={{
                  borderRadius: 50,
                  width: "20%",
                  borderColor: "#ff9b44",
                  color: "#ff9b44",
                }}
                value={optionSelected}
                onChange={(e) => {
                  console.log(e.target.value);
                  setOption(e.target.value);
                }}
              >
                {Options.map((each) => (
                  <>
                    <option key={each.id} value={each.id}>
                      {each.name.toUpperCase()}
                    </option>
                  </>
                ))}
              </select>
            </div>
          )}
        </div>

        <div className="row ">
          <div className="col-md-12 d-flex">
            <div className="card profile-box flex-fill">
              <div className="card-body">
                {isLoadingHistory ? (
                  <Skeleton
                    height={50}
                    count={3}
                    style={{ marginBottom: "1%" }}
                  />
                ) : filterPointHistory(PointHistory, optionSelected).length ===
                  0 ? (
                  <>
                    <ul className="experience-list">
                      <li>
                        <div
                          className="experience-content m-0 row"
                          style={{
                            flexDirection: "row",
                            justifyContent: "space-between",
                            color: "#4c3a2b",
                          }}
                        >
                          <div className="col-md-4">
                            <h4
                              className="mb-0 pl-2 py-2"
                              style={{
                                backgroundColor: "#fff8f2",
                              }}
                            >
                              Message
                            </h4>
                          </div>
                          <div className="col-md-4 pl-0">
                            <h4
                              className="mb-0 pl-2 py-2"
                              style={{
                                backgroundColor: "#fff8f2",
                              }}
                            >
                              Coupon
                            </h4>
                          </div>
                          <div className="col-md-4 pl-0">
                            <h4
                              className="mb-0 pl-2 py-2"
                              style={{
                                backgroundColor: "#fff8f2",
                              }}
                            >
                              Amount
                            </h4>
                          </div>
                        </div>
                      </li>
                    </ul>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "100%",
                      }}
                    >
                      <p>No data to show</p>
                    </div>
                  </>
                ) : (
                  <ul className="experience-list">
                    <li>
                      <div
                        className="experience-content row"
                        style={{
                          flexDirection: "row",
                          justifyContent: "space-between",
                          color: "#4c3a2b",
                        }}
                      >
                        <div className="col-md-4">
                          <h4
                            className="mb-0 pl-2 py-2"
                            style={{
                              backgroundColor: "#fff8f2",
                            }}
                          >
                            Message
                          </h4>
                        </div>
                        <div className="col-md-4 pl-0">
                          <h4
                            className="mb-0 pl-2 py-2"
                            style={{
                              backgroundColor: "#fff8f2",
                            }}
                          >
                            Coupon
                          </h4>
                        </div>
                        <div className="col-md-4 pl-0">
                          <h4
                            className="mb-0 pl-2 py-2"
                            style={{
                              backgroundColor: "#fff8f2",
                            }}
                          >
                            Amount
                          </h4>
                        </div>
                      </div>
                    </li>
                    {filterPointHistory(PointHistory, optionSelected).map(
                      (each, index) => (
                        <li key={index}>
                          <div className="experience-user">
                            <div className="before-circle"></div>
                          </div>
                          <div
                            className="experience-content row px-2 py-1"
                            style={{
                              flexDirection: "row",
                              justifyContent: "space-between",
                            }}
                          >
                            {/* names */}
                            <div className="col-md-4">
                              <div className="timeline-content">
                                <div
                                  className="name"
                                  style={{
                                    fontWeight: "bold",
                                    color: "black",
                                    // width: "100%",
                                  }}
                                >
                                  {each.message}
                                </div>
                                <span className="time">
                                  {getTimeDifference(each.on)}
                                </span>
                              </div>
                            </div>

                            {/* coupons */}
                            <div className="col-md-4 ">
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "flex-start",
                                  alignItems: "center",
                                  // width: "10%",
                                }}
                              >
                                {each.coupon && (
                                  <ClipboardCopy copyText={each.coupon} />
                                )}
                              </div>
                            </div>

                            {/* transaction */}
                            <div className="col-md-4">
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "flex-start",
                                  alignItems: "center",
                                  // width: "10%",
                                }}
                              >
                                <span>
                                  <i
                                    className="las la-coins"
                                    style={{
                                      marginRight: 5,
                                      color:
                                        each.event_type === "CR"
                                          ? "green"
                                          : "red",
                                    }}
                                  ></i>
                                </span>
                                <span>
                                  {each.points.toLocaleString("en-IN")}
                                </span>
                              </div>
                            </div>
                          </div>
                        </li>
                      )
                    )}
                  </ul>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default History;
