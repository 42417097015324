import { toast } from "react-toastify";


const UploadingNotification = () =>
  toast.info("File is being uploaded. Please wait...", {
    position: "top-center",
    autoClose: 3000, // Disable auto-close since we want this to be open until the process is complete
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "light",
  });

const SuccessNotifation = (message) =>
  toast.success( message, {
    position: "top-center",
    autoClose: 3000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "light",
  });

const FailedNotification = (errror) =>
  toast.error( errror, {
    position: "top-center",
    autoClose: 3000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "light",
  });

  export { SuccessNotifation, FailedNotification, UploadingNotification };
