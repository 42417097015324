import React, { useState, useEffect, useRef } from "react";
// import Select from "react-select";

import "./ItemChips.css";
import { getCustomToken } from "../helpers/utils";

function ItemChips(props) {
  const [value, setValue] = useState("");
  const [error, setError] = useState(null);
  const [suggestions, setSuggestions] = useState([]);
  // const [items, setItems] = useState([]);
  const [showSuggestions, setShowSuggestions] = useState(false); // State to control suggestion visibility

  useEffect(() => {
    // Perform any side effects or computations based on the updated 'users' data
    console.log("Users updated:", props.items);
  }, [props.items]); // Dependency array with 'users'

  useEffect(() => {
    fetchSuggestions(value); // Fetch initial suggestions on component mount
  }, [value]);

  const fetchSuggestions = async (search) => {
    try {
      let apiUrl = `${process.env.REACT_APP_BACKEND_URL}/users?page=1&size=10`;

      // Check if 'search' is not empty
      if (search !== "") {
        apiUrl += `&search=${search}`;
      }

      const response = await fetch(apiUrl, {
        headers: {
          Authorization: `Bearer ${getCustomToken()}`,
        },
      }); // Replace with your actual API endpoint
      const data = await response.json();
      setSuggestions(data.items || []); // Assuming the API response has a "suggestions" property
    } catch (error) {
      console.error("Error fetching suggestions:", error);
    }
  };

  // const handleKeyDown = (evt) => {
  //   if (["Enter", "Tab", ","].includes(evt.key)) {
  //     evt.preventDefault();
  //     const trimmedValue = value.trim();
  //     if (trimmedValue && isValid(trimmedValue)) {
  //       setItems([...items, trimmedValue]);
  //       setValue("");
  //     }
  //   }
  // };

  const handleChange = async (evt) => {
    setValue(evt.target.value);

    console.log("SEARCH: ", evt.target.value);
    setError(null);

    if (evt.target.value.length > 1) {
      setShowSuggestions(true);
    }

    await fetchSuggestions(evt.target.value);
  };

  const handleDelete = (item) => {
    props.setItems(props.items.filter((i) => i !== item));
  };

  // const handlePaste = (evt) => {
  //   evt.preventDefault();
  //   const paste = evt.clipboardData.getData("text");
  //   const emails = paste.match(/[\w\d\\.-]+@[\w\d\\.-]+\.[\w\d\\.-]+/g);

  //   if (emails) {
  //     const toBeAdded = emails.filter((email) => !isInList(email));
  //     setItems([...items, ...toBeAdded]);
  //   }
  // };

  const selectSuggestion = (suggestion) => {
    // setItems([...items, suggestion]); // Add suggestion directly to items state

    // Check for existing item with the same ID
    const existingIndex = props.items.findIndex(
      (item) => item.users.id === suggestion?.id
    );

    if (existingIndex === -1) {
      // Add new item if not found

      props.setItems([
        ...props.items,
        {
          users: {
            display_name: suggestion.display_name,
            email_id: suggestion.email_id,
            id: suggestion.id,
          },
        },
      ]);
    }

    inputRef.current.focus(); // Set focus to the input field

    setSuggestions([]); // Clear suggestions after selection
    setValue("");
    setShowSuggestions(false);
  };

  // const isValid = (email) => {
  //   let error = null;

  //   if (isInList(email)) {
  //     error = `${email} has already been added.`;
  //   } else if (!isEmail(email)) {
  //     error = `${email} is not a valid email address.`;
  //   }

  //   if (error) {
  //     setError(error);
  //     return false;
  //   }

  //   return true;
  // };

  // const isInList = (email) => items.includes(email);

  // const isEmail = (email) => /[\w\d\\.-]+@[\w\d\\.-]+\.[\w\d\\.-]+/.test(email);

  const renderSuggestions = () => {
    return suggestions.map((suggestion) => (
      <div
        className="suggestion"
        key={suggestion.id}
        onClick={() => {
          console.log("CLICKED: ", suggestion);
          selectSuggestion(suggestion);
        }}
      >
        {suggestion.display_name}
      </div>
    ));
  };

  // const handleFocus = () => {
  //   setShowSuggestions(true); // Show suggestions on input focus
  //   fetchSuggestions(value);
  // };

  // const handleBlur = () => {
  //   setShowSuggestions(false); // Show suggestions on input focus
  // };

  const inputRef = useRef(null); // Ref to reference the input field

  return (
    <>
      <div className="col-md-12">
        {props.items.map((item) => {
          console.log("ITEM: ", item);
          return (
            <div className="tag-item" key={item.users.id}>
              {item.users.display_name}
              <button
                type="button"
                className="button"
                onClick={() => handleDelete(item)}
              >
                &times;
              </button>
            </div>
          );
        })}
      </div>

      <div className="col-md-12">
        <div className="form-group form-border">
          <label>
            {props.label}:
            {props.is_required && <span style={{ color: "red" }}> * </span>}
            <span style={{ color: "red" }}>{error}</span>
          </label>

          <input
            ref={inputRef}
            className={"form-control  " + (error && " has-error")}
            value={value}
            placeholder="Type or paste email addresses and press `Enter`..."
            // onKeyDown={handleKeyDown}
            onChange={handleChange}
            // onPaste={handlePaste}
            // onFocus={handleFocus}
            // onBlur={handleBlur}
          />
        </div>

        {showSuggestions && (
          <div className="suggestions">{renderSuggestions()}</div>
        )}
      </div>
    </>
  );
}

export default ItemChips;
