import axios from "axios";

import { getCustomToken } from "../helpers/utils";

const http = axios.create({
  baseURL: process.env.REACT_APP_BACKEND_URL,

  headers: {
    "content-type": "application/json",
    Authorization: `Bearer ${getCustomToken()}`,
  },
});

class UserService {
  // getEmployeeSync(currPage, pageSize) {
  //   return http.get(`/user?page=${currPage}&size=${pageSize}`, {
  //     headers: {
  //       Authorization: `Bearer ${getCustomToken()}`,
  //     },
  //   });
  // }

  getFilterEmployee(groupId, rewardId) {
    // Construct the base URL
    let url = "/user/filter";

    // Add "group_id" to the URL if it is provided and not null/undefined
    if (groupId !== null && groupId !== undefined) {
      url += `?group_id=${groupId}`;
    }

    // Add "reward_id" to the URL if it is provided and not null/undefined
    if (rewardId !== null && rewardId !== undefined) {
      // if (groupId !== null && groupId !== undefined) {
      //   url += "&"; // Add an ampersand if "group_id" was also included
      // }
      url += `?reward_id=${rewardId}`;
    }

    return http.get(url, {
      headers: {
        Authorization: `Bearer ${getCustomToken()}`,
      },
    });
  }

  // getEmployeeSyncWithAdmin() {
  //   return http.get(`/user/includeloggedinuser?page=1&size=100`, {
  //     headers: {
  //       Authorization: `Bearer ${getCustomToken()}`,
  //     },
  //   });
  // }

  // getAadEmployeeSync = (page, pageSize) => {
  //   return http.get(`/user/aad?page=${page}&size=${pageSize}`, {
  //     headers: {
  //       Authorization: `Bearer ${getCustomToken()}`,
  //     },
  //   });
  // };

  aadSyncToDB() {
    return http.get(`/users/sync`, {
      headers: {
        Authorization: `Bearer ${getCustomToken()}`,
      },
    });
  }

  getUsers(currPage, pageSize, searchText) {
    let url = `/users`;

    url += "?page=" + currPage;
    url += "&size=" + pageSize;

    if (searchText && searchText.length > 0) {
      console.log("search: ", searchText);

      const specialCharsRegex = /^[A-Za-z\s&']+$/;

      if (!specialCharsRegex.test(searchText)) {
        // Special characters found, handle the notification or any other action
        throw new Error(
          "Invalid characters in search term. Please use alphabets, spaces, '&', and ''' only."
        );
      }

      url += "&search=" + searchText;
    }

    return http.get(url, {
      headers: {
        Authorization: `Bearer ${getCustomToken()}`,
      },
    });
  }
  assignUserGroup(formdata, data) {
    let payload = JSON.stringify({
      user_id: data.id,
      group_id: formdata.group,
      employment_type_id: formdata.employment,
      is_admin: formdata.is_admin,
    });

    return http.post(`/user/assigngroup`, payload);
  }

  updateUserDetails(user_id, payload) {
    return http.put(`/users/${user_id}`, payload, {
      headers: {
        Authorization: `Bearer ${getCustomToken()}`,
      },
    });
  }

  getRewardPoints() {
    return http.get(`/users/points`, {
      headers: {
        Authorization: `Bearer ${getCustomToken()}`,
      },
    });
  }

  getRewardsAssignedToMe() {
    return http.get(`/user/assignedrewardstome?page=1&size=100`, {
      headers: {
        Authorization: `Bearer ${getCustomToken()}`,
      },
    });
  }

  getProfile() {
    return http.get(`/users/me`, {
      headers: {
        Authorization: `Bearer ${getCustomToken()}`,
      },
    });
  }
}

export default new UserService();
