import axios from "axios";
import { getCustomToken } from "../helpers/utils";

const http = axios.create({
  baseURL: process.env.REACT_APP_BACKEND_URL,
  headers: {
    "content-type": "application/json",
  },
});

class ReportService {
  generateReport(startDate, endDate, reportCategory, page, size, totalItems) {
    const formattedStartDate = startDate.toISOString().split("T")[0];
    const formattedEndDate = endDate.toISOString().split("T")[0];

    // Assuming you have a mapping from report category names to their numeric values

    return http.get(`/report/view`, {
      params: {
        startdate: formattedStartDate,
        enddate: formattedEndDate,
        report_type: reportCategory, // Use reportCategory directly
        page,
        size,
        totalItems,
      },
      headers: {
        Authorization: `Bearer ${getCustomToken()}`,
      },
    });
  }

  downloadReport(startDate, endDate, reportCategory) {
    const formattedStartDate = startDate.toISOString().split("T")[0];
    const formattedEndDate = endDate.toISOString().split("T")[0];

    return http.get(`/report/download`, {
      responseType: "blob", // Important for file downloads
      params: {
        startdate: formattedStartDate,
        enddate: formattedEndDate,
        report_type: reportCategory,
      },
      headers: {
        Authorization: `Bearer ${getCustomToken()}`,
      },
    });
  }

  fetchRewardsHistory(userId = null, page = 1, size = 50) {
    // Construct the parameters based on whether a user ID was provided.
    const params = { page, size };
    if (userId) {
      params.user_id = userId;
    }

    return http.get(`/report/rewards`, {
      params: params,
      headers: {
        Authorization: `Bearer ${getCustomToken()}`,
      },
    });
  }
}

export default new ReportService();
