import { useState } from "react";

function ClipboardCopy({ copyText }) {
  const [isCopied, setIsCopied] = useState(false);

  // This is the function we wrote earlier
  async function copyTextToClipboard(text) {
    if ("clipboard" in navigator) {
      return await navigator.clipboard.writeText(text);
    } else {
      return document.execCommand("copy", true, text);
    }
  }

  // onClick handler function for the copy button
  const handleCopyClick = () => {
    // Asynchronously call copyTextToClipboard
    copyTextToClipboard(copyText)
      .then(() => {
        // If successful, update the isCopied state value
        setIsCopied(true);
        setTimeout(() => {
          setIsCopied(false);
        }, 2000);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <span>{copyText}</span>
      {/* Bind our handler function to the onClick button property */}
      {isCopied ? (
        <i
          className="la la-clipboard-check ml-3"
          style={{ fontSize: "24px", color: "#0ca940" }}
        ></i>
      ) : (
        <i
          className="la la-clipboard ml-1"
          onClick={handleCopyClick}
          style={{ fontSize: "24px", color: "#ff9b44" }}
        ></i>
      )}
    </div>
  );
}

export default ClipboardCopy;
