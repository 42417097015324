/*eslint-disable */
import { useState, useEffect, useRef } from "react";
import Select from "react-select";
import { Tab, Tabs } from "react-bootstrap";
import { Link } from "react-router-dom";
// import Theme from "../components/theme";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import RewardService from "../services/Reward.Service";
import UserService from "../services/User.service";
import { useContext } from "react";
import { UserContext } from "../hooks/UserContext";
import { useNavigate } from "react-router-dom/dist";
import {
  UploadingNotification,
  FailedNotification,
  SuccessNotifation,
} from "../components/Notifications";
import { checkError, fieldValidate } from "../helpers/validators";
import { Beatloader } from "../components/Spinner";
import { getCustomToken } from "../helpers/utils";
import AccessDenied from "../components/AccessDenied";
import Individual from "./Individual";
import Bulk from "./Bulk";

function AssignReward() {
  // const { currentUser } = useContext(UserContext);

  // const [RUserdata, setRUserData] = useState([]); // Make sure this line is here
  // const navigate = useNavigate();

  // const [data, setData] = useState({
  //   reward_id: "",
  //   user_id: "",
  //   recommended_email_id: "",
  //   comment: "",
  // });
  // console.log(data);

  // const noError = checkError(newErrors);

  const [ActiveTab, setActiveTab] = useState("Individual");
  const handleTabSelect = (selectedTab) => {
    toast.dismiss();
    setActiveTab(selectedTab);
  };

  // //Assign reward

  // console.log(data);

  // const handleChange = (event) => {
  //   const { name, value } = event.target;
  //   setData({ ...data, [name]: value });
  // };

  // Download Excel templete from API

  return (
    <div className="page-wrapper" style={{ backgroundColor: "white" }}>
      <div className="content container-fluid">
        <div className="row">
          <div className="col-md-12">
            <div className="page-header mb-2">
              <div className="row">
                <div className="col-sm-12">
                  <h3 className="page-title">Reward Association</h3>

                  <ul className="breadcrumb">
                    <li className="breadcrumb-item">
                      <Link to="/home">
                        <span>
                          <i className="la la-home"></i> Home
                        </span>
                      </Link>
                    </li>

                    <li className="breadcrumb-item">
                      <i className="las la-medal"></i>Assign Reward
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <Tabs
              defaultActiveKey="Individual"
              className="nav-tabs-bottom"
              onSelect={handleTabSelect}
            >
              <Tab eventKey="Individual" title="Individual">
                <Individual />
              </Tab>

              <Tab eventKey="Bulkupload" title="Bulk Upload">
                <Bulk />
              </Tab>
            </Tabs>
          </div>
        </div>
      </div>
      {/* <Footer></Footer> */}
      {/* <Theme></Theme> */}
    </div>
  );
}

export default AssignReward;
