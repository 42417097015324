import axios from "axios";

import { getCustomToken } from "../helpers/utils";

const http = axios.create({
  baseURL: process.env.REACT_APP_BACKEND_URL,
  headers: {
    "content-type": "application/json",
  },
});

class DashBoardService {
  getAssignedRewardsCount() {
    return http.get(`/analytics/rewards?page=1&size=100`, {
      headers: {
        Authorization: `Bearer ${getCustomToken()}`,
      },
    });
  }

  getBanners(currPage, pageSize, searchText) {
    let url = `/banners`;

    url += "?page=" + currPage;
    url += "&size=" + pageSize;

    if (searchText && searchText.length > 0) {
      console.log("search: ", searchText);

      const specialCharsRegex = /^[A-Za-z\s&']+$/;

      if (!specialCharsRegex.test(searchText)) {
        // Special characters found, handle the notification or any other action
        throw new Error(
          "Invalid characters in search term. Please use alphabets, spaces, '&', and ''' only."
        );
      }

      url += "&search=" + searchText;
    }

    return http.get(url, {
      headers: {
        Authorization: `Bearer ${getCustomToken()}`,
      },
    });
  }

  createBanner(data) {
    // Create an object of formData
    const formData = new FormData();

    formData.append("title", data.title);
    formData.append("description", data.descriptionb);
    // Update the formData object
    formData.append("image", data.image, data.image.name);

    return http.post(`/banners`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${getCustomToken()}`,
      },
    });
  }

  deleteBanner(id) {
    return http.delete(`/banners?id=${id}`, {
      headers: {
        Authorization: `Bearer ${getCustomToken()}`,
      },
    });
  }
}

export default new DashBoardService();
