import axios from "axios";
import { getCustomToken } from "../helpers/utils";

const http = axios.create({
  baseURL: process.env.REACT_APP_BACKEND_URL,
  headers: {
    "content-type": "application/json",
  },
});

class CoreService {
  getGroups() {
    return http.get(`/core/groups?page=1&size=50`);
  }

  getPageGroups(currPage, pageSize) {
    return http.get(`/core/groups?page=${currPage}&size=${pageSize}`, {
      headers: {
        Authorization: `Bearer ${getCustomToken()}`,
      },
    });
  }

  getEmployements() {
    return http.get(`/core/employments?page=1&size=50`, {
      headers: {
        Authorization: `Bearer ${getCustomToken()}`,
      },
    });
  }

  getModes() {
    return http.get(`/core/modes?page=1&size=50`, {
      headers: {
        Authorization: `Bearer ${getCustomToken()}`,
      },
    });
  }

  createGroup(groupData) {
    return http.post(`/core/groups`, groupData, {
      headers: {
        Authorization: `Bearer ${getCustomToken()}`,
      },
    });
  }
  updateGroup(group_id, groupData) {
    return http.put(`/core/groups/${group_id}`, groupData, {
      headers: {
        Authorization: `Bearer ${getCustomToken()}`,
      },
    });
  }

  // deleteGroup(groupId) {
  //   return http.post(
  //     `/core/delete/${groupId}`,
  //     {},
  //     {
  //       headers: {
  //         Authorization: `Bearer ${getCustomToken()}`,
  //       },
  //     }
  //   );
  // }
}

export default new CoreService();
