/*eslint-disable */
import { useState, useEffect, useRef } from "react";
import Select from "react-select";
import { Tab, Tabs } from "react-bootstrap";
import { Link } from "react-router-dom";
// import Theme from "../components/theme";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import RewardService from "../services/Reward.Service";
import UserService from "../services/User.service";
import { useContext } from "react";
import { UserContext } from "../hooks/UserContext";
import { useNavigate } from "react-router-dom/dist";
import {
  UploadingNotification,
  FailedNotification,
  SuccessNotifation,
} from "../components/Notifications";
import { checkError, fieldValidate } from "../helpers/validators";
import { Beatloader } from "../components/Spinner";
import { getCustomToken } from "../helpers/utils";
import AccessDenied from "../components/AccessDenied";
import "../components/ItemChips.css";
import ItemSelectReward from "./ItemSelectReward";
import ItemSelectRewarduser from "./ItemSelectRewarduser";
import ItemSelectRewardrecomended from "./ItemSelectRewardrecomended";

function Individual(props) {
  const [rewardData, setRewardData] = useState([]);
  const [RUsersData, setRUsersData] = useState([]);
  const [formData, setFormData] = useState({
    reward_id: "",
    user_id: "",
    recommended_id: "",
    comment: "",
    is_winner: false,
  });
  const [errors, setErrors] = useState({
    reward_name: "",
    user_id: "",
    recommended_id: "",
    comment: "",
    is_winner: "",
  });
  const [hasParticipationPoints, setHasParticipationPoints] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_BACKEND_URL}/rewards?page=1&size=10&published=true`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${getCustomToken()}`,
            },
          }
        );
        if (response && response.data) {
          setRewardData(response.data.items);
        }
      } catch (error) {
        console.error(error.message);
      }
    };
    fetchData();
  }, []);

  function getGroupAndUserNames(reward) {
    const { reward_groups, reward_users } = reward;
    let userNames = new Map();

    if (Array.isArray(reward_groups)) {
      reward_groups.forEach((group) => {
        if (group.groups && group.groups.group_users) {
          group.groups.group_users.forEach((user) => {
            if (user.users && user.users.display_name) {
              userNames.set(user.users.id, {
                id: user.users.id,
                display_name: user.users.display_name,
                email_id: user.users.email_id,
              });
            }
          });
        }
      });
    }

    if (Array.isArray(reward_users)) {
      reward_users.forEach((user) => {
        if (user && user.display_name) {
          userNames.set(user.id, {
            id: user.id,
            display_name: user.display_name,
            email_id: user.email_id,
          });
        }
      });
    }

    return Array.from(userNames.values());
  }

  const [activeReward, setActiveReward] = useState({});
  const [activeUser, setActiveUser] = useState({});
  const [activeRecomended, setActiveRecomended] = useState({});

  useEffect(() => {
    if (activeReward) {
      const reward_users = getGroupAndUserNames(activeReward);
      setRUsersData(reward_users);
    }
  }, [activeReward]);

  const [isLoading, setIsLoading] = useState(false);
  const [toggleSpinner, settoggleSpinner] = useState(false);

  const AssignRequest = async (FormData) => {
    const URL = `${process.env.REACT_APP_BACKEND_URL}/rewards/users`;

    return axios
      .post(
        URL,
        {
          reward_id: FormData.reward_id,
          user_id: FormData.user_id,
          recommended_id: FormData.recommended_id,
          comment: FormData.comment,
          is_winner: FormData.is_winner,
        },
        {
          withCredentials: true,
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getCustomToken()}`,
          },
        }
      )
      .then((response) => {
        SuccessNotifation("Rewards sent successfully", {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 3000, // Toast message duration in milliseconds
        });
        resetForm();
      })
      .catch((error) => {
        if (error.response && error.response.status === 409) {
          // Handle conflict error if the reward is already assigned
          FailedNotification(
            "This reward is already assigned to the selected user.",
            {
              position: toast.POSITION.TOP_CENTER,
              autoClose: 3000,
            }
          );
        } else {
          console.error(error.message);
          FailedNotification("An error occurred. Please try again later.", {
            position: toast.POSITION.TOP_CENTER,
            autoClose: 3000,
          });
        }
        resetForm();
        throw error;
      });
  };

  const resetForm = () => {
    setFormData({
      reward_id: "",
      user_id: "",
      recommended_id: "",
      comment: "",
      is_winner: false,
    });
    setErrors({
      reward_name: "",
      user_id: "",
      recommended_id: "",
      comment: "",
      is_winner: "",
    });
    setActiveReward({});
    setActiveUser({});
    setActiveRecomended({});
    setHasParticipationPoints(false);
  };

  const [reload, setReload] = useState(false);
  const [fetchError, setFetchError] = useState(false);

  const checkError = (errors) => {
    return Object.values(errors).every((error) => !error);
  };

  const validateForm = (e) => {
    e.preventDefault();
    const newErrors = {};
    let isValid = true;

    if (!activeReward.id) {
      newErrors.reward_id = "Reward is required.";
      isValid = false;
    }

    if (!activeUser.id) {
      newErrors.user_id = "User is required.";
      isValid = false;
    }

    if (!activeRecomended.id) {
      newErrors.recommended_id = "Recommended User is required.";
      isValid = false;
    }

    if (!formData.comment) {
      newErrors.comment = "Comment is required.";
      isValid = false;
    }

    setErrors(newErrors);

    if (isValid && checkError(newErrors)) {
      settoggleSpinner(true);
      submitHandler();
    } else {
      setFetchError(true);
    }
  };

  const submitHandler = async () => {
    settoggleSpinner(true);
    setIsLoading(true);

    const payload = {
      reward_id: activeReward.id,
      user_id: activeUser.id,
      recommended_id: activeRecomended.id,
      comment: formData.comment,
      is_winner: hasParticipationPoints,
    };

    try {
      await AssignRequest(payload);
    } catch (error) {
      // Handle any additional logic if needed after catching the error
    } finally {
      settoggleSpinner(false);
      setIsLoading(false);
    }
  };

  const handleCommentChange = (event) => {
    const { name, value } = event.target;
    const pattern = /^[a-zA-Z0-9\s,-_&%!]*$/;

    if (!pattern.test(value)) {
      setErrors({ ...errors, [name]: "Special characters not allowed" });
    } else {
      setErrors({ ...errors, [name]: "" });
    }

    setFormData({ ...formData, [name]: value });
  };

  const handleChange = (event) => {
    const { value } = event.target;
    setFormData({ ...formData, reward_id: value });

    if (event.target.value) {
      setErrors((prevErrors) => ({ ...prevErrors, reward_id: "" }));
    }
  };

  const handleChangeRecommendedBy = (event) => {
    const { value } = event.target;
    setFormData({ ...formData, recommended_email_id: value });
    if (event.target.value) {
      setErrors((prevErrors) => ({ ...prevErrors, recommended_email_id: "" }));
    }
  };

  const handleChangeReward = (event) => {
    const { value } = event.target;
    setFormData({ ...formData, reward_id: value });

    if (event.target.value) {
      setErrors((prevErrors) => ({ ...prevErrors, reward_id: "" }));
    }
  };

  const handleChangeEmployee = (event) => {
    const { value } = event.target;
    setFormData({ ...formData, user_id: value });

    if (event.target.value) {
      setErrors((prevErrors) => ({ ...prevErrors, user_id: "" }));
    }
  };

  return (
    <div className="card">
      <div className="card-header">
        <h4 className="card-title mb-0">Individual Reward</h4>
      </div>
      <div className="card-body">
        <form onSubmit={validateForm}>
          <div className="row">
            <div className="col-md-12">
              <div className="form-group row">
                <label className="col-lg-5 col-form-label">
                  Reward/Recognition
                  <span style={{ color: "red" }}> * </span>
                  <span style={{ color: "red" }}>
                    {errors.reward_id && errors.reward_id}
                  </span>
                </label>
                <div className="col-lg-7">
                  <ItemSelectReward
                    rewardData={rewardData}
                    setRewardData={(p) => {
                      setRewardData(p);
                    }}
                    activeReward={activeReward}
                    setActiveReward={(p) => {
                      setActiveReward(p);
                    }}
                    setHasParticipationPoints={(p) => {
                      setHasParticipationPoints(p);
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="col-xl-12">
              <div className="form-group row">
                <label className="col-lg-5 col-form-label">
                  Employee
                  <span style={{ color: "red" }}> * </span>
                  <span style={{ color: "red" }}>
                    {errors.user_id && errors.user_id}
                  </span>
                </label>
                <div className="col-lg-7">
                  <ItemSelectRewarduser
                    RUsersData={RUsersData}
                    activeUser={activeUser}
                    setActiveUser={(p) => {
                      setActiveUser(p);
                    }}
                  />
                </div>
              </div>
              <div className="form-group row">
                <label className="col-lg-5 col-form-label">
                  is winner
                  <span style={{ color: "red" }}> * </span>
                </label>
                <div className="col-lg-3 justify-content-center align-items-center">
                  <div className="d-flex justify-content-between ml-5">
                    <div>
                      <label className="form-check-label ml-2 mr-100">
                        <input
                          type="radio"
                          className="form-check-input"
                          name="hasParticipationPoints"
                          value={true}
                          checked={hasParticipationPoints}
                          onChange={() => {
                            setHasParticipationPoints(true);
                          }}
                        />
                        Yes
                      </label>
                    </div>

                    <div>
                      <label className="form-check-label ml-2">
                        <input
                          type="radio"
                          className="form-check-input"
                          name="hasParticipationPoints"
                          value={false}
                          checked={!hasParticipationPoints}
                          onChange={() => {
                            setHasParticipationPoints(false);
                          }}
                          disabled={activeReward.bonus_points === 0}
                        />
                        No
                      </label>
                    </div>
                  </div>
                </div>
              </div>

              <div className="form-group row">
                <label className="col-lg-5 col-form-label">
                  Recommended By
                  <span style={{ color: "red" }}> * </span>
                  <span style={{ color: "red" }}>
                    {errors.recommended_id && errors.recommended_id}
                  </span>
                </label>
                <div className="col-lg-7">
                  <ItemSelectRewardrecomended
                    activeRecomended={activeRecomended}
                    setActiveRecomended={(p) => {
                      setActiveRecomended(p);
                    }}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="form-group row">
            <label className="col-lg-5 col-form-label">
              Reward Summary
              <span style={{ color: "red" }}> * </span>
              <span style={{ color: "red" }}>
                {errors.comment && errors.comment}
              </span>
            </label>
            <div className="col-lg-7">
              <textarea
                type="text"
                className="form-control"
                placeholder="Enter Summary"
                onChange={handleCommentChange}
                name="comment"
                value={formData.comment}
              />
              <small style={{ color: "orange" }}>
                (special characters not allowed except , -_-&%! )
              </small>
            </div>
          </div>

          <div className="text-right">
            {toggleSpinner ? (
              <button className="btn add-btn" disabled>
                <Beatloader />
              </button>
            ) : (
              <button type="submit" className="btn add-btn">
                SUBMIT
              </button>
            )}
          </div>
        </form>
      </div>
    </div>
  );
}

export default Individual;
