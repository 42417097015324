import React, { useState } from "react";

const ProfileModal = ({ profileData, onClose }) => {
  // Initialize the state at the top level of your component
  const [isError, setIsError] = useState(false);

  // Early return moved after the useState call
  if (!profileData) return null;

  const ProfilePicBase =
    "https://evolutyzblobimages.blob.core.windows.net/unmaze/";
  const defaultAvatarURL =
    "https://ezcorner.evolutyz.com/uploadimages/images/User.png";

  // The modal content should replicate the structure and styles of your profile page
  return (
    <div className="modal-backdrop">
      <div className="modal-content">
        <div className="modal-header">
          <button onClick={onClose} className="close-modal-button">
            X
          </button>
        </div>
        <div className="modal-body">
          <div className="profile-center">
            <div className="profile-avatar">
              <img
                src={
                  isError || !profileData
                    ? defaultAvatarURL
                    : ProfilePicBase + profileData.employeeID + ".jpg"
                }
                alt={
                  isError || !profileData
                    ? "Default avatar image"
                    : profileData.employeeID + "'s profile picture"
                }
                onError={() => setIsError(true)}
              />
            </div>
            <h3>{profileData.display_name}</h3>
            <p>{profileData?.department ? profileData?.department : "NA"}</p>
            <div className="profile-details">
              <p>Email: {profileData.email_id}</p>
              <p>EmployeeID: {profileData.employeeID}</p>

              <div className="title">Address:</div>
              <div className="text">
                {profileData ? (
                  <p>
                    Vuda Complex, 2nd Floor, 53 26-11, TPT Colony,
                    Seethammadara, Visakhapatnam, Andhra Pradesh 530013
                  </p>
                ) : (
                  <p>XX</p>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default ProfileModal;
