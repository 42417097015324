import { ClipLoader, BeatLoader } from "react-spinners";
import React, { useState } from "react";

const Cliploader = () => {
  return (
    <ClipLoader
      color="#ff9b44"
      loading
      margin={2}
      size={50}
      speedMultiplier={1}
    />
  );
};

const Beatloader = () => {
  const [loading, setLoading] = useState(true);
  setTimeout(() => {
    setLoading(false);
  }, 5000);
  // return (
  //   <BeatLoader color="#fff" loading margin={2} size={10} speedMultiplier={1}  />
  // );
  return loading ? (
    <BeatLoader color="#fff" loading margin={2} size={10} speedMultiplier={1} />
  ) : null;
};

export { Cliploader, Beatloader };
