// import React, { useState } from "react";
// import { useMsal } from "@azure/msal-react";
// import { Link } from "react-router-dom";

const InternalServerError = () => {
  // const [toggleHover, setToggleHover] = useState(false);

  // const handletoggleHover = () => {
  //   setToggleHover((prev) => !prev);
  // };

  // const { instance } = useMsal();

  // const clearCustomToken = () => {
  //   localStorage.removeItem("customToken");
  // };

  const refreshPage = () => {
    window.location.reload();
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
        overflow: "hidden",
        backgroundColor: "#fff",
      }}
    >
      <div className="row">
        <div className="col-md-12 align-self-center text-center">
          <div className="d-flex justify-content-center align-items-center">
            <h1 style={{ color: "#ff9b44" }}>500 - INTERNAL SERVER ERROR</h1>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-md-12 align-self-center text-center">
          <div className="d-flex justify-content-center align-items-center">
            <h2 style={{ color: "#ff9b44" }}>Oops, something went wrong.</h2>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-md-12 align-self-center text-center">
          <div className="d-flex justify-content-center align-items-center">
            <p style={{ color: "#ff9b44" }}>
              An internal server error occurred. The server encountered an
              unexpected condition that prevented it from fulfilling the
              request.
            </p>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-md-12 align-self-center text-center">
          <div className="d-flex justify-content-center align-items-center"></div>
        </div>
      </div>
      <div>
        <button
          onClick={refreshPage}
          style={{
            margin: "5px",
            width: "auto",
            fontWeight: 700,
            border: "2px solid #ff9b44",
            textDecoration: "none",
            padding: "15px 45px",
            textTransform: "uppercase",
            color: "#ff9b44",
            borderRadius: "26px",
            transition: "all 0.2s ease-in-out",
            display: "inline-block",
            backgroundColor: "#fff",
          }}
        >
          Refresh
        </button>
      </div>
    </div>
  );
};

export default InternalServerError;
