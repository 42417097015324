import React from "react";
import { Cliploader } from "./Spinner";

function Loader() {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
        overflow: "hidden",
        backgroundColor: "#fff",
      }}
    >
      <div className="row">
        <div className="col-md-12">
          <div className="d-flex ">
            <Cliploader />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Loader;
