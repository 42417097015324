/*eslint-disable */
import { createRef, useContext, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css"; 
import { Link } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import { UserContext } from "../hooks/UserContext"; 
import "react-loading-skeleton/dist/skeleton.css";
import ItemChips from "../components/ItemChips";
import {
  FailedNotification,
  SuccessNotifation,
} from "../components/Notifications";
import { Beatloader } from "../components/Spinner"; 
import CoreService from "../services/Core.service"; 
import { Box, createTheme, ThemeProvider } from "@mui/material";
import MaterialTable, { MTablePagination } from "material-table";
import tableIcons from "../components/MaterialTableIcons";
import { getCustomToken } from "../helpers/utils";
import useDebounce from "../hooks/useDebounce";

import { TablePagination } from "@material-ui/core";

import AvatarGroup from "react-avatar-group";

function Groups(props) {
  const { currentUser } = useContext(UserContext);
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(true);
  const [showbanner, setShowBanner] = useState(false);
  const [toggleSpinner, settoggleSpinner] = useState(false);
  const [totalItems, setTotalItems] = useState(0); // Add state for total items

  const [modal, setModal] = useState(false);
  const [ActiveItem, setActiveItem] = useState({
    id: "",
    title: "",
    description: "",
    users: [],
  });

  // const handleClose = () => setModal((current) => !current);
  // const handleShow = (req) => {
  //   setModal(true);
  //   setActiveItem(req);
  // };

  // eslint-disable-next-line no-unused-vars
  const [formData, setFormData] = useState({
    title: "",
    descriptionb: "",
    users: [],
  });
  const [errors, setErrors] = useState({
    title: "",
    descriptionb: "",
  });

  // eslint-disable-next-line no-unused-vars
  const [currPage, setCurrPage] = useState(1);
  const [prevPage, setPrevPage] = useState(0);
  const [bannerList, setBannerList] = useState([]);
  const [wasLastList, setWasLastList] = useState(false);
  const [Loader, setLoader] = useState(true);
  const listInnerRef = useRef();
  const [isNextButtonEnabled, setIsNextButtonEnabled] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const handleShowEditModal = (group) => {
    setFormData({
      name: group.name,
      description: group.description,
      users: group.group_users,
    });
    setActiveItem(group);
    setShowEditModal(true);
  };
 
  const handleShowDeleteModal = (group) => {
    setActiveItem(group);
    setShowDeleteModal(true);
  };
 
  const toggleCreateBannerForm = () => {
    setShowBanner((current) => !current);
    setFormData({
      title: "",
      descriptionb: "",
    });
    setErrors({
      title: "",
      descriptionb: "",
    });
  };
 
  const toggleEditBannerForm = () => {
    setShowEditModal((current) => !current);
    setFormData({
      title: "",
      descriptionb: "",
    });
    setErrors({
      title: "",
      descriptionb: "",
    });
  };
 
  const pageSize = 8;
  // useEffect(() => {
  //   const fetchData = async () => {
  //     setIsLoading(true);
  //     try {
  //       const response = await CoreService.getPageGroups(currPage, pageSize);
  //       if (response.data && response.data.items) {
  //         setBannerList(response.data.items);
  //         setTotalItems(response.data.total); // Make sure this matches the actual total count key in your response
  //         setWasLastList(response.data.items.length < pageSize);
  //         console.log("Total Items:", response.data.total); // Log the total items for debugging
  //       } else {
  //         console.error("Response is missing 'data' or 'items'");
  //       }
  //     } catch (error) {
  //       console.error("Error fetching groups:", error);
  //     } finally {
  //       setIsLoading(false);
  //     }
  //   };
 
  //   if (!showbanner) {
  //     fetchData();
  //   }
  // }, [currPage, pageSize, showbanner]);
 
  // const paginateNext = () => {
  //   // If the number of items is equal to the page size, there may be a next page
  //   if (bannerList.length === pageSize && !wasLastList) {
  //     setCurrPage((prev) => prev + 1);
  //   }
  // };
 
  // const paginatePrevious = () => {
  //   if (currPage > 1) {
  //     setCurrPage((prev) => prev - 1);
  //   }
  // };
 
  // useEffect(() => {
  //   console.log("II");
  //   if (!modal === (showbanner || !showbanner)) {
  //     setCurrPage(1);
  //     setPrevPage(0);
  //     setBannerList([]);
  //     setWasLastList(false);
  //   }
  // }, [showbanner, modal]);
 
  const changeHandler = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };
 
  // useEffect(() => {
  //   console.log("FORMDATA: ", formData);
  // }, [formData]);
 
  const validateForm = (formData) => {
    const newErrors = {};
    let isValid = true;
 
    // Validation logic for title
    if (!formData.title) {
      newErrors.title = "Title is required.";
      isValid = false;
    }
 
    // Validation logic for descriptionb
    if (!formData.descriptionb) {
      newErrors.descriptionb = "Description is required.";
      isValid = false;
    }
 
    setErrors(newErrors);
    return isValid;
  };
 
  const updateValidateForm = (formData) => {
    const newErrors = {};
    let isValid = true;
 
    // Validation logic for title
    if (!formData.name) {
      newErrors.title = "Title is required.";
      isValid = false;
    }
 
    // Validation logic for descriptionb
    if (!formData?.description) {
      newErrors.descriptionb = "Description is required.";
      isValid = false;
    }
 
    setErrors(newErrors);
    return isValid;
  };
 
  const submitHandler = async (e) => {
    e.preventDefault(); // Prevent the default form submission
 
    if (validateForm(formData)) {
      // If form is valid, proceed with API call
      settoggleSpinner(true);
      try {
        const response = await CoreService.createGroup({
          name: formData.title, // changed from title to name
          description: formData.descriptionb, // changed from descriptionb to description
          users: (formData.users || []).map((item) => item.users.id),
        });
        SuccessNotifation("Group Created!");
        setShowBanner(false);
        setFormData({ title: "", descriptionb: "", users: [] }); // Reset the form data
        setCurrPage(1); // Reset to the first page to see the new group
      } catch (error) {
        if (error.response) {
          // The request was made and the server responded with a status code >= 400
          console.error("Error:", error.response.data);
          console.error("Status Code:", error.response.status);
 
          if (error.response.status === 422) {
            FailedNotification(error.response.data.detail[0]["msg"]);
          } else {
            FailedNotification(error.response.data.detail);
          }
        } else if (error.request) {
          // The request was made but no response was received
          console.error("No response received:", error.request);
          FailedNotification(error.request);
        } else {
          // Something else happened while setting up the request
          console.error("Error:", error.message);
        }
      } finally {
        settoggleSpinner(false); // Hide spinner after submission
      }
    } else {
      // If form is not valid, do not submit and show errors
      console.log("Form is not valid:", errors);
    }
  };
 
  // const updatePaginationState = (items, total) => {
  //   setBannerList(items);
  //   setTotalItems(total);
  //   // Determine if this is the last list by checking if we have fewer items than the page size
  //   setWasLastList(items.length < pageSize);
  // };
 
  // const paginate = (pageNumber) => setCurrPage(pageNumber);
 
  // const fetchBannerList = async () => {
  //   setIsLoading(true); // Show loading indicator while fetching
  //   try {
  //     // Request to fetch groups with pagination
  //     const response = await CoreService.getPageGroups(currPage, pageSize);
  //     if (response.data && response.data.items) {
  //       // Update state with fetched data
  //       setBannerList(response.data.items);
  //       setTotalItems(response.data.total); // Update the total items state
  //       // Check if this is the last page
  //       setWasLastList(response.data.items.length < pageSize);
  //     } else {
  //       console.error("Response is missing 'data' or 'items'");
  //     }
  //   } catch (error) {
  //     console.error("Error fetching groups:", error);
  //     // Optionally update state to show an error message
  //   } finally {
  //     setIsLoading(false); // Hide loading indicator after fetching
  //   }
  // };
 
  if (bannerList.length === 0 && currPage > 1) {
    setCurrPage(currPage - 1);
  }
 
  // const itemsArray = Array.from({ length: 16 }, (_, index) => index);
 
  const tableRef = createRef();
 
  const defaultMaterialTheme = createTheme();
 
  function getGroupNames(inputArray) {
    // Check if the input is an array and not empty
    if (!Array.isArray(inputArray) || inputArray.length === 0) {
      return [];
    }
 
    // Use the map function to extract the "name" property from each "group" object
    const groupNames = inputArray.map(
      (item) => item.users && item.users.display_name
    );
 
    // Filter out any undefined values (in case "group" property is missing in some objects)
    return groupNames.filter((name) => name !== undefined);
  }
 
  const [columns, setColumns] = useState([
    {
      title: "Name",
      field: "name",
      // export: true,
      headerStyle: {
        backgroundColor: "#ffedde",
      },
    },
 
    {
      title: "Description",
      render: (rowData) => rowData.description || "-",
      // export: true,
      headerStyle: {
        backgroundColor: "#ffedde",
      },
    },
 
    {
      title: "Users",
      render: (rowData) =>
        rowData.group_users.length > 0 ? (
          <AvatarGroup
            avatars={getGroupNames(rowData.group_users)}
            initialCharacters={2}
            max={3}
            size={30}
            uppercase={true}
            displayAllOnHover
            shadow={2}
            randomBackgroundColors={["#ff9b44"]}
            fontSize={0.5}
          />
        ) : (
          "-"
        ), // export: true,
      headerStyle: {
        backgroundColor: "#ffedde",
      },
    },
 
    {
      minWidth: 90,
      maxWidth: 90,
      title: "Type",
      render: (rowData) => {
        return (
          <>
            <span
              className={
                rowData.is_public
                  ? "badge bg-inverse-success"
                  : "badge bg-inverse-warning"
              }
            >
              {rowData.is_public ? "public" : "private"}
            </span>
          </>
        );
      },
      // export: true,
      headerStyle: {
        backgroundColor: "#ffedde",
      },
    },
  ]);
 
  // const handleDeleteGroup = async (groupId) => {
  //   settoggleSpinner(true);
  //   try {
  //     await CoreService.deleteGroup(groupId);
  //     setBannerList(bannerList.filter((group) => group.id !== groupId));
  //     SuccessNotifation("Group deleted successfully!");
  //   } catch (error) {
  //     const errorMessage =
  //       error.response?.data?.message || "Failed to delete the group.";
  //     FailedNotification(errorMessage); // Use the specific error message from the backend if available
  //     console.error("Error deleting the group:", errorMessage);
  //   } finally {
  //     settoggleSpinner(false);
  //     setShowDeleteModal(false);
  //   }
  // };
 
  const handleEditGroupSubmit = async (e) => {
    e.preventDefault();
 
    if (updateValidateForm(formData)) {
      settoggleSpinner(true);
      try {
        const response = await CoreService.updateGroup(ActiveItem.id, {
          name: formData.name,
          description: formData.description,
          users: (formData.users || []).map((item) => item.users.id),
        });
        SuccessNotifation("Group updated successfully!");
        // fetchBannerList();
        setShowBanner(false);
        setFormData({ title: "", descriptionb: "", users: [] }); // Reset the form data
        setCurrPage(1); // Reset to the first page to see the new group
      } catch (error) {
        if (error.response) {
          // The request was made and the server responded with a status code >= 400
          console.error("Error:", error.response.data);
          console.error("Status Code:", error.response.status);
 
          if (error.response.status === 422) {
            FailedNotification(error.response.data.detail[0]["msg"]);
          } else {
            FailedNotification(error.response.data.detail);
          }
        } else if (error.request) {
          // The request was made but no response was received
          console.error("No response received:", error.request);
          FailedNotification(error.request);
        } else {
          // Something else happened while setting up the request
          console.error("Error:", error.message);
        }
      } finally {
        settoggleSpinner(false);
        setShowEditModal(false);
      }
    } else {
      // If form is not valid, do not submit and show errors
      console.log("EditForm is not valid:", errors);
    }
  };
 
  // const validateForm = (e) => {
  //   console.log("Im here");
  //   e.preventDefault();
  //   const newErrors = { ...errors };
  //   Object.keys(formData).forEach((field) => {
  //     switch (field) {
  //       case "title":
  //         newErrors.title = fieldValidate(field, formData[field], {
  //           required: true,
  //         });
  //         break;
  //       case "descriptionb":
  //         newErrors.descriptionb = fieldValidate(field, formData[field], {
  //           required: true,
  //         });
  //         break;
 
  //       default:
  //         break;
  //     }
  //   });
  //   setErrors(newErrors);
 
  //   console.log("ERROR_DATA:", newErrors);
 
  //   const noError = checkError(newErrors);
 
  //   if (noError) {
  //     settoggleSpinner((current) => !current);
  //     submitHandler(e);
  //   }
  // };

  const [rowsPerPage, setRowsPerPage] = useState(5);

  const handleRowsPerPageChange = (event) => {
    const newPageSize = parseInt(event.target.value, 10);
    setRowsPerPage(newPageSize);
    setCurrPage(1); // Reset current page to the first page
    // fetchBannerList(); // Trigger a new API call with the updated page size
    tableRef.current.onQueryChange();
  };
 
  // useEffect(() => {
  //   tableRef.current.onQueryChange();
  // }, [rowsPerPage]);
 
  // useEffect(() => {
  //   fetchBannerList(); // Call this function whenever currPage or pageSize changes
  // }, [currPage, rowsPerPage]);
  // // Debounce search function to call API after 500ms of inactivity
  // const debouncedSearch = debounce((query) => {
  //   tableRef.current && tableRef.current.onQueryChange({ search: query });
  // }, 500);
 
  // const handleSearchChange = (event) => {
  //   const query = event.target.value;
  //   setSearchQuery(query);
  //   debouncedSearch(query);
  // };
 
  const [searchTerm, setSearchTerm] = useState("");
 
  const handleSearch = useDebounce((term) => {
    // Perform search operation with the debounced term
    console.log("Searching for:", term);
  }, 500);
 
  const handleChange = (event) => {
    const { value } = event.target;
 
    console.log("handlrchnage: ", value);
    setSearchTerm(value);
 
    // Debounce the search callback
    handleSearch(value);
  };

  const handleChnageRowsPerPage = (e) => {
    console.log("Im here");
    setRowsPerPage(e.target.value);

    tableRef.current.onChangePage(null, 0);

    tableRef.current && tableRef.current.onQueryChange();
  };

  return (
    <div className="page-wrapper" style={{ backgroundColor: "white" }}>
      <div className="content">
        <div className="page-header">
          <div className="row">
            <div className="col">
              <h3 className="page-title">Groups</h3>
              <ul className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="/home">
                    <span>
                      <i className="la la-home"></i> Home
                    </span>
                  </Link>
                </li>
                <li className="breadcrumb-item active">
                  {" "}
                  <i className="la la-users"></i> Groups
                </li>
              </ul>
            </div>
            <div
              className="col-auto float-right ml-auto"
              style={{ marginRight: "3vh" }}
            >
              {showbanner ? (
                <div onClick={toggleCreateBannerForm} className="btn add-btn">
                  {"Cancel"}
                </div>
              ) : (
                <>
                  {showEditModal ? (
                    <div onClick={toggleEditBannerForm} className="btn add-btn">
                      {"Cancel"}
                    </div>
                  ) : (
                    <div
                      onClick={toggleCreateBannerForm}
                      className="btn add-btn"
                    >
                      {"Create"}
                    </div>
                  )}
                </>
              )}
 
              {!showbanner && !showEditModal && (
                <div style={{ display: "none" }} className="btn add-btn"></div>
              )}
            </div>
          </div>
        </div>
 
        {/* {isLoading ? (
          <div
            className="row align-items-center justify-content-center"
            style={{
              flexDirection: "column",
              // backgroundColor: "red",
              width: "100%",
              height: "60vh",
            }}  
          >
            <Load />
          </div>
        ) : ( */}
        <>
          {/* Create View */}
          {showbanner && (
            <div className="row">
              <div className="col-md-12">
                <div className="card mb-0">
                  <div className="card-body">
                    <form onSubmit={submitHandler}>
                      <div className="row">
                        <div className="col-md-12">
                          <div className="form-group form-border">
                            <label>
                              Title: <span style={{ color: "red" }}> * </span>
                              <span style={{ color: "red" }}>
                                {errors.title && errors.title}
                              </span>
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Enter Title"
                              // required
                              name="title"
                              value={formData.title}
                              onChange={changeHandler}
                            />
                          </div>
                        </div>
                        <div className="col-md-12">
                          <div className="form-group form-border">
                            <label>
                              Description:
                              <span style={{ color: "red" }}> * </span>
                              <span style={{ color: "red" }}>
                                {errors.descriptionb && errors.descriptionb}
                              </span>
                            </label>
                            <textarea
                              rows="2"
                              cols="5"
                              className="form-control"
                              placeholder="Enter Description"
                              // required
                              name="descriptionb"
                              value={formData.descriptionb}
                              onChange={changeHandler}
                            ></textarea>
                          </div>
                        </div>
 
                        <ItemChips
                          label={"Users"}
                          is_required={false}
                          items={formData?.users || []}
                          setItems={(newItems) =>
                            setFormData((prevData) => ({
                              ...prevData,
                              users: newItems,
                            }))
                          }
                        />
                      </div>
 
                      <div className="text-right">
                        {toggleSpinner ? (
                          <button className="btn add-btn" disabled>
                            <Beatloader />
                          </button>
                        ) : (
                          <button type="submit" className="btn add-btn">
                            Create
                          </button>
                        )}
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          )}
 
          {/* Edit View */}
          {showEditModal && (
            <div className="row">
              <div className="col-md-12">
                <div className="card mb-0">
                  <div className="card-body">
                    <form onSubmit={handleEditGroupSubmit}>
                      <div className="row">
                        <div className="col-md-12">
                          <div className="form-group form-border">
                            <label>
                              Title: <span style={{ color: "red" }}> * </span>
                              <span style={{ color: "red" }}>
                                {errors.title && errors.title}
                              </span>
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Enter Title"
                              // required
                              name="title"
                              value={formData.name}
                              disabled={true}
                              onChange={(e) =>
                                setFormData({
                                  ...formData,
                                  name: e.target.value,
                                })
                              }
                            />
                          </div>
                        </div>
                        <div className="col-md-12">
                          <div className="form-group form-border">
                            <label>
                              Description:
                              <span style={{ color: "red" }}> * </span>
                              <span style={{ color: "red" }}>
                                {errors.descriptionb && errors.descriptionb}
                              </span>
                            </label>
                            <textarea
                              rows="2"
                              cols="5"
                              className="form-control"
                              placeholder="Enter Description"
                              // required
                              name="descriptionb"
                              value={formData.description}
                              onChange={(e) =>
                                setFormData({
                                  ...formData,
                                  description: e.target.value,
                                })
                              }
                            ></textarea>
                          </div>
                        </div>
 
                        <ItemChips
                          label={"Users"}
                          is_required={false}
                          items={formData?.users || []}
                          setItems={(newItems) =>
                            setFormData((prevData) => ({
                              ...prevData,
                              users: newItems,
                            }))
                          }
                        />
                      </div>
 
                      <div className="text-right">
                        {toggleSpinner ? (
                          <button
                            // onClick={handleClick}
                            className="btn add-btn"
                            disabled
                          >
                            <Beatloader />
                          </button>
                        ) : (
                          <button
                            type="submit"
                            // onClick={handleClick}
                            className="btn add-btn"
                          >
                            Update
                          </button>
                        )}
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          )}
 
          {/* List View */}
          {!showbanner && !showEditModal && (
            <div className="row mt-3">
              {/* {isLoading && (
                  <table className="table table-striped custom-table mb-0 datatable">
                    <thead>
                      <tr>
                        <th>
                          <Skeleton />
                        </th>
                        <th>
                          <Skeleton />
                        </th>
                        <th>
                          <Skeleton />
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {itemsArray.map((_, index) => (
                        <tr key={index}>
                          <td>
                            <Skeleton />
                          </td>
                          <td>
                            <Skeleton />
                          </td>
                          <td>
                            <Skeleton />
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                )} */}
 
              <ThemeProvider theme={defaultMaterialTheme}>
                <div style={{ width: "100%" }}>
                  <MaterialTable
                    title="Group Preview"
                    tableRef={tableRef}
                    columns={columns}
                    data={(query) =>
                      new Promise((resolve, reject) => {
                        let url =
                          process.env.REACT_APP_BACKEND_URL + "/core/groups";
                        url += "?page=" + (query.page + 1);
                        url += "&size=" + rowsPerPage;
                        // Add search query if available
                        if (query.search && query.search.length > 0) {
                          console.log("search: ", query.search);
                          url += "&search=" + query.search;
                        }
                        fetch(url, {
                          headers: {
                            Authorization: `Bearer ${getCustomToken()}`,
                          },
                        })
                          .then((response) => response.json())
                          .then((result) => {
                            console.info("RESULT:", result);
                            setIsLoading(false); // Hide loading indicator after fetching
 
                            resolve({
                              data: result.items,
                              page: result.page - 1,
                              totalCount: result.total,
                            });
                          })
                          .catch((error) => {
                            console.error("Error fetching groups:", error);
                          })
                          .finally(() => {
                            setIsLoading(false);
                          });
                      })
                    }
                    icons={tableIcons}
                    options={{
                      headerStyle: { backgroundColor: "#ffedde" },
                      showTitle: false,
                      paging: true,
                      search: true,
                      sorting: false,
                      actionsColumnIndex: -1,
                      exportButton: false,
                      // paginationType: "normal",
                      pageSize: rowsPerPage,
                      // pageSizeOptions: [5, 10, 15, 30],
                      emptyRowsWhenPaging: false,
 
                      actionsCellStyle: {
                        justifyContent: "center",
                      },
                    }}
                    localization={{
                      header: {
                        actions: "",
                      },
                    }}
                    // onChangeRowsPerPage={(event) => {
                    //   setRowsPerPage(event.target.value);
                    // }}
                    onSearchChange={handleChange}
                    searchText={searchTerm}
                    // components={{
                    //   Pagination: (subProps) => {
                    //     return (
                    //       <Box display="flex" justifyContent="flex-end">
                    //         <Box width="260px" justifyContent="flex-end">
 
                    //           <MTablePagination
                    //             {...subProps}
                    //             classes={{
                    //               root: subProps.classes.root,
                    //             }} /* Rest of the code, removed for brevity.*/
                    //           />
                    //         </Box>
                    //       </Box>
                    //     );
                    //   },
                    // }}
 
                    components={{
                      Pagination: (props) => (
                        <TablePagination
                          {...props}
                          rowsPerPageOptions={[5, 10, 15]}
                          rowsPerPage={rowsPerPage}
                          onRowsPerPageChange={handleChnageRowsPerPage}
                        />
                      ),
                    }}
                    actions={[
                      (rowData) => ({
                        icon: "edit",
                        tooltip: "edit group",
                        onClick: (event, rowData) => {
                          handleShowEditModal(rowData);
                        },
                        disabled: !rowData.is_public,
                      }),
 
                      {
                        icon: "refresh",
                        tooltip: "Refresh Data",
                        isFreeAction: true,
                        onClick: () =>
                          tableRef.current && tableRef.current.onQueryChange(),
                      },
                    ]}
                    components={{
                      Pagination: (props) => (
                        <TablePagination
                          {...props}
                          rowsPerPageOptions={[5, 10, 15]}
                          rowsPerPage={rowsPerPage}
                          onRowsPerPageChange={handleChnageRowsPerPage}
                        />
                      ),
                    }}
                  />
                </div>
              </ThemeProvider>
            </div>
          )}
        </>
      </div>
    </div>
  );
}
 
export default Groups;