/*eslint-disable */
import React, { useState, useContext, useEffect, createRef } from "react";
import { Link } from "react-router-dom";
import DatePicker from "react-datepicker";
import Select from "react-select";
import { UserContext } from "../hooks/UserContext";
import "react-datepicker/dist/react-datepicker.css"; // Import DatePicker CSS
import ReportService from "../services/Report.Service";
import Pagination from "../components/Pagination";
import { Beatloader } from "../components/Spinner";
import {
  FailedNotification,
  SuccessNotifation,
} from "../components/Notifications";
import moment from "moment-timezone";
import { Box, ThemeProvider, createTheme } from "@mui/material";
import MaterialTable, { MTableToolbar } from "material-table";
import tableIcons from "../components/MaterialTableIcons";
import { TablePagination } from "@material-ui/core";
import { getCustomToken } from "../helpers/utils";
import { SaveAlt } from "@material-ui/icons";

function Reports() {
  const tableRef = createRef();
  const defaultMaterialTheme = createTheme();

  const [rowsPerPage, setRowsPerPage] = useState(5);

  const { currentUser } = useContext(UserContext);
  const today = new Date();
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(today);
  const [selectedReportCategory, setSelectedReportCategory] = useState("1");
  const [reportErrors, setReportErrors] = useState({
    startDate: "",
    endDate: "",
    reportCategory: "",
  });
  const [reportData, setReportData] = useState(null);
  const [isReportGenerated, setIsReportGenerated] = useState(false);

  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const pageSize = 10;
  const [totalRecords, setTotalRecords] = useState(0);
  const [totalItems, setTotalItems] = useState(0);
  const [loading, setLoading] = useState(false);
  const handleCategoryChange = (selectedOption) => {
    setSelectedReportCategory(selectedOption);
    setIsReportGenerated(false); // Reset the report generated flag
  };

  const formatDateToIST = (date) => {
    return moment(date).tz("Asia/Kolkata").format("YYYY-MM-DD HH:mm:ss");
  };

  const formatDate = (isoDateString) => {
    if (!isoDateString) return "NA";
    const date = new Date(isoDateString);
    return date.toLocaleString("en-IN", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
      timeZone: "Asia/Kolkata",
    });
  };

  // const formatDateToUTC = (isoDateString) => {
  //   if (!isoDateString) return "NA"; // Handle null or undefined values
  //   const date = new Date(isoDateString);
  //   return date.toUTCString(); // Convert to UTC string
  // };

  const minimizeGiftCardCode = (code, length = 8) => {
    // Return the first 'length' characters followed by '...' if the code is longer than 'length'
    return code.length > length ? `${code.substring(0, length)}...` : code;
  };

  const [isFormSubmitted, setIsFormSubmitted] = useState(false);

  // const renderTableHeaders = () => {
  //   let headers = [];
  //   if (selectedReportCategory.value === "1") {
  //     headers = [
  //       " Employee Name",
  //       "Email id",
  //       "Reporting Manager",
  //       "Total Points Allocated",
  //       "Total Points Allocated value in INR",
  //       "Total Points Redeemed",
  //       "Total Points Redeemed value in INR",
  //       "Balance Points",
  //       "Balance Points value in INR",
  //     ];
  //   } else if (selectedReportCategory.value === "2") {
  //     headers = [
  //       "Employee Name",
  //       "Email id",

  //       "Reporting Manager",
  //       "Awards Name",
  //       "Date of Award/Event Conducted",
  //       "Points allocated",
  //       "Points value in INR",
  //     ];
  //   } else if (selectedReportCategory.value === "3") {
  //     headers = [
  //       "Employee Name",
  //       "Email id",

  //       "Reporting Manager",
  //       "Total Redemptions",
  //     ];
  //   } else if (selectedReportCategory.value === "4") {
  //     headers = [];
  //   }

  //   return headers;
  // };

  // //Render table rows dynamically
  // const renderTableRows = () => {
  //   return reportData.map((item, index) => (
  //     <tr key={index}>
  //       {selectedReportCategory.value === "1" && (
  //         <>
  //           <td>{item["Employee Name"]}</td>
  //           <td>{item["Email id"]}</td>
  //           <td>
  //             {item["Reporting Manager"] ? item["Reporting Manager"] : "NA"}
  //           </td>
  //           <td>{item["Total Points Allocated"].toLocaleString("en-IN")}</td>
  //           <td>
  //             {item["Total Points Allocated value in INR"].toLocaleString(
  //               "en-IN"
  //             )}
  //           </td>
  //           <td>{item["Total Points Redeemed"].toLocaleString("en-IN")}</td>
  //           <td>
  //             {item["Total Points Redeemed value in INR"].toLocaleString(
  //               "en-IN"
  //             )}
  //           </td>
  //           <td>{item["Balance Points"].toLocaleString("en-IN")}</td>
  //           <td>
  //             {item["Balance Points value in INR"].toLocaleString("en-IN")}
  //           </td>
  //         </>
  //       )}
  //       {selectedReportCategory.value === "2" && (
  //         <>
  //           <td>{item["Employee Name"]}</td>
  //           <td>{item["Email id"]}</td>
  //           <td>
  //             {item["Reporting Manager"] ? item["Reporting Manager"] : "NA"}
  //           </td>
  //           <td>{item["Awards Name"]}</td>
  //           <td>{formatDate(item["Date of Award/Event Conducted"])}</td>
  //           <td>{item["Points allocated"].toLocaleString("en-IN")}</td>
  //           <td>{item["Points value in INR"].toLocaleString("en-IN")}</td>
  //         </>
  //       )}
  //       {selectedReportCategory.value === "3" && (
  //         <>
  //           <td>{item["Employee Name"]}</td>
  //           <td>{item["Email id"]}</td>
  //           <td>
  //             {item["Reporting Manager"] ? item["Reporting Manager"] : "NA"}
  //           </td>
  //           <td>{item["Total Redemptions"].toLocaleString("en-IN")}</td>
  //         </>
  //       )}
  //       {selectedReportCategory.value === "4" && (
  //         <>
  //           <td>{minimizeGiftCardCode(item["Gift Card Code"])}</td>
  //           <td>{item["Value in INR"].toLocaleString("en-IN")}</td>
  //           <td>{formatDate(item["Created At"])}</td>
  //           <td>{item["Status"]}</td>
  //           <td>{item["Assigned To"] !== "NA" ? item["Assigned To"] : "NA"}</td>
  //           <td>{item["Email ID"] !== "NA" ? item["Email ID"] : "NA"}</td>
  //           <td>
  //             {item["Date Assigned"] && item["Date Assigned"] !== "NA"
  //               ? formatDate(item["Date Assigned"])
  //               : "NA"}
  //           </td>
  //         </>
  //       )}
  //     </tr>
  //   ));
  // };

  const handleGenerateReport = async () => {
    setLoading(true);
    setIsReportGenerated(false);

    const previousPageNumber = currentPage - 1;

    if (validateReport()) {
      if (startDate > endDate) {
        FailedNotification("Start Date should not be greater than End Date.");
      } else if (endDate > new Date()) {
        FailedNotification("End Date should not exceed the current date.");
      } else {
        try {
          let response;
          if (previousPageNumber >= 1) {
            response = await ReportService.generateReport(
              startDate,
              endDate,
              selectedReportCategory.value,
              previousPageNumber, // Use the previous page number
              10
            );
          } else {
            response = await ReportService.generateReport(
              startDate,
              endDate,
              selectedReportCategory.value,
              currentPage, // Use currentPage here for the initial report generation
              10
            );
          }

          console.log("API Response:", response.data);

          if (
            response.data &&
            response.data.data &&
            Array.isArray(response.data.data.items)
          ) {
            setReportData(response.data.data.items);
            setTotalPages(response.data.data.pages);
            setTotalItems(response.data.data.total);
            console.log("error", totalItems);
            if (previousPageNumber >= 1) {
              setCurrentPage(previousPageNumber); // Update the current page number for previous page
            } else {
              setCurrentPage(currentPage); // Update the current page number for initial report generation
            }

            setIsReportGenerated(true);
          } else {
            console.error(
              "Expected an array from the API, got:",
              response.data
            );
            FailedNotification("Invalid data format received from the API");
            setIsReportGenerated(false);
          }
        } catch (error) {
          FailedNotification("Failed to generate/fetch the report.");
          console.error("Error generating/fetching the report:", error);
        } finally {
          setLoading(false);
        }
      }
    } else {
      setLoading(false);
    }
  };

  // useEffect(() => {
  //   const fetchReports = async () => {
  //     setLoading(true);
  //     try {
  //       const response = await ReportService.getReports(
  //         currentPage,
  //         pageSize,
  //         selectedReportCategory?.value
  //       );
  //       console.log("API Response:", response.data); // Debugging the API response
  //       setReportData(response.data.items);
  //       setTotalPages(response.data.pages);
  //       setTotalItems(response.data.total); // Ensure this is updated based on the response
  //     } catch (error) {
  //       console.error("Error fetching reports:", error);
  //     } finally {
  //       setLoading(false);
  //     }
  //   };

  //   fetchReports();
  // }, [currentPage, pageSize, selectedReportCategory]);

  // const handleNextPage = async () => {
  //   console.log("Clicked Next");

  //   const nextPageNumber = currentPage + 1;
  //   if (currentPage < totalPages) {
  //     setLoading(true); // Start loading
  //     try {
  //       const response = await ReportService.generateReport(
  //         startDate,
  //         endDate,
  //         selectedReportCategory.value,
  //         nextPageNumber,
  //         pageSize
  //       );
  //       console.log(`Navigating to next page: ${nextPageNumber}`);
  //       if (
  //         response.data &&
  //         response.data.data &&
  //         Array.isArray(response.data.data.items)
  //       ) {
  //         setReportData(response.data.data.items);
  //         setCurrentPage(nextPageNumber); // Update the current page number
  //         setTotalPages(response.data.data.pages);
  //         setTotalRecords(response.data.data.total);
  //       } else {
  //         console.error("Expected an array from the API, got:", response.data);
  //         setError("Invalid data format received from the API");
  //       }
  //     } catch (error) {
  //       setError("Failed to fetch new records.");
  //       console.error("Error fetching new records:", error);
  //     } finally {
  //       setLoading(false); // End loading
  //     }
  //   }
  // };

  // const handlePreviousPage = async () => {
  //   console.log("Clicked Previous");

  //   const previousPageNumber = currentPage - 1;
  //   if (currentPage > 1) {
  //     setLoading(true); // Start loading
  //     try {
  //       const response = await ReportService.generateReport(
  //         startDate,
  //         endDate,
  //         selectedReportCategory.value,
  //         previousPageNumber,
  //         pageSize
  //       );
  //       console.log(`Navigating to previous page: ${previousPageNumber}`);
  //       if (
  //         response.data &&
  //         response.data.data &&
  //         Array.isArray(response.data.data.items)
  //       ) {
  //         setReportData(response.data.data.items);
  //         setCurrentPage(previousPageNumber); // Update the current page number
  //         setTotalPages(response.data.data.pages);
  //         setTotalRecords(response.data.data.total);
  //       } else {
  //         console.error("Expected an array from the API, got:", response.data);
  //         setError("Invalid data format received from the API");
  //       }
  //     } catch (error) {
  //       setError("Failed to fetch previous records.");
  //       console.error("Error fetching previous records:", error);
  //     } finally {
  //       setLoading(false); // End loading
  //     }
  //   }
  // };
  const hasDataToDisplay = () => reportData && reportData.length > 0;

  const reportCategoryOptions = [
    {
      value: "1",
      label: "Redemption and Utilization Report",
    },
    {
      value: "2",
      label: "Points allocated Event Wise",
    },
    { value: "3", label: "Redemption Count" },
    { value: "4", label: "Gift Card Codes" },
  ];

  const downloadReport = async () => {
    if (!startDate || !endDate || !selectedReportCategory) {
      FailedNotification("Please select all required fields.");
      return;
    }

    const formattedStartDate = startDate.toISOString().split("T")[0];
    const formattedEndDate = endDate.toISOString().split("T")[0];
    const reportCategoryName = selectedReportCategory.label.replace(
      /\s+/g,
      "_"
    );
    const fileName = `${reportCategoryName}-report-${formattedStartDate}_to_${formattedEndDate}.xlsx`;

    try {
      const response = await ReportService.downloadReport(
        startDate,
        endDate,
        selectedReportCategory.value
      );

      const blobUrl = window.URL.createObjectURL(response.data);
      const link = document.createElement("a");
      link.href = blobUrl;
      link.download = fileName;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(blobUrl);
    } catch (error) {
      console.error("Error downloading the report:", error);
      FailedNotification(
        "Failed to download the report. Ensure you are logged in and try again."
      );
    }
  };

  const excelFileUrl = "https://example.com/reports/report.xlsx"; // Replace with the URL of your Excel file
  const excelFileName = "report.xlsx"; // Provide the desired file name with the appropriate extension

  // Call the function to download the Excel file

  const validateReport = () => {
    let errors = { ...reportErrors };
    let isValid = true;

    // Validate Start Date
    if (!startDate) {
      errors.startDate = "Start Date is required";
      isValid = false;
    } else if (startDate > endDate) {
      errors.startDate = "Start Date should not be greater than End Date";
      isValid = false;
    } else {
      errors.startDate = "";
    }

    // Validate End Date
    if (!endDate) {
      errors.endDate = "End Date is required";
      isValid = false;
    } else if (endDate > new Date()) {
      errors.endDate = "End Date should not exceed the current date";
      isValid = false;
    } else {
      errors.endDate = "";
    }

    // Validate Report Category
    if (!selectedReportCategory) {
      errors.reportCategory = "Report Category is required";
      isValid = false;
    } else {
      errors.reportCategory = "";
    }

    setReportErrors(errors);
    return isValid;
  };

  const handleChnageRowsPerPage = (e) => {
    console.log("Im here");
    setRowsPerPage(e.target.value);

    tableRef.current.onChangePage(null, 0);

    // tableRef.current && tableRef.current.onQueryChange();
  };

  const tempColumns = [
    {
      title: "Employee Name",
      render: (rowData) => rowData["Employee Name"],
      headerStyle: {
        backgroundColor: "#ffedde",
      },
    },

    {
      title: "Email id",
      render: (rowData) => rowData["Email id"],
      // export: true,
      headerStyle: {
        backgroundColor: "#ffedde",
      },
    },
    {
      title: "Reporting Manager",
      render: (rowData) => rowData["Reporting Manager"] || "NA",
      // export: true,
      headerStyle: {
        backgroundColor: "#ffedde",
      },
    },
    {
      title: "Total Points Allocated",
      render: (rowData) =>
        rowData["Total Points Allocated"].toLocaleString("en-IN"),
      // export: true,
      headerStyle: {
        backgroundColor: "#ffedde",
      },
    },
    {
      title: "Total Points Allocated value in INR",
      render: (rowData) =>
        rowData["Total Points Allocated value in INR"].toLocaleString("en-IN"),
      // export: true,
      headerStyle: {
        backgroundColor: "#ffedde",
      },
    },
    {
      title: "Total Points Redeemed",
      render: (rowData) =>
        rowData["Total Points Redeemed"].toLocaleString("en-IN"),
      // export: true,
      headerStyle: {
        backgroundColor: "#ffedde",
      },
    },
    {
      title: "Total Points Redeemed value in INR",
      render: (rowData) =>
        rowData["Total Points Redeemed value in INR"].toLocaleString("en-IN"),
      // export: true,
      headerStyle: {
        backgroundColor: "#ffedde",
      },
    },
    {
      title: "Balance Points",
      render: (rowData) => rowData["Balance Points"].toLocaleString("en-IN"),
      // export: true,
      headerStyle: {
        backgroundColor: "#ffedde",
      },
    },
    {
      title: "Balance Points value in INR",
      render: (rowData) =>
        rowData["Balance Points value in INR"].toLocaleString("en-IN"),
      // export: true,
      headerStyle: {
        backgroundColor: "#ffedde",
      },
    },
  ];

  const [columns, setColumns] = useState(tempColumns);
  const [title, setTitle] = useState("Redemption and Utilization Report");

  const customStyles = {
    menu: (provided) => ({
      ...provided,
      zIndex: 9999, // Set a high z-index value
    }),
  };

  useEffect(() => {
    let tempColumns;

    if (selectedReportCategory.value === "1") {
      tempColumns = [
        {
          title: "Employee Name",
          render: (rowData) => rowData["Employee Name"],
          headerStyle: {
            backgroundColor: "#ffedde",
          },
        },

        {
          title: "Email id",
          render: (rowData) => rowData["Email id"],
          // export: true,
          headerStyle: {
            backgroundColor: "#ffedde",
          },
        },
        {
          title: "Reporting Manager",
          render: (rowData) => rowData["Reporting Manager"] || "NA",
          // export: true,
          headerStyle: {
            backgroundColor: "#ffedde",
          },
        },
        {
          title: "Total Points Allocated",
          render: (rowData) =>
            rowData["Total Points Allocated"].toLocaleString("en-IN"),
          // export: true,
          headerStyle: {
            backgroundColor: "#ffedde",
          },
        },
        {
          title: "Total Points Allocated value in INR",
          render: (rowData) =>
            rowData["Total Points Allocated value in INR"].toLocaleString(
              "en-IN"
            ),
          // export: true,
          headerStyle: {
            backgroundColor: "#ffedde",
          },
        },
        {
          title: "Total Points Redeemed",
          render: (rowData) =>
            rowData["Total Points Redeemed"].toLocaleString("en-IN"),
          // export: true,
          headerStyle: {
            backgroundColor: "#ffedde",
          },
        },
        {
          title: "Total Points Redeemed value in INR",
          render: (rowData) =>
            rowData["Total Points Redeemed value in INR"].toLocaleString(
              "en-IN"
            ),
          // export: true,
          headerStyle: {
            backgroundColor: "#ffedde",
          },
        },
        {
          title: "Balance Points",
          render: (rowData) =>
            rowData["Balance Points"].toLocaleString("en-IN"),
          // export: true,
          headerStyle: {
            backgroundColor: "#ffedde",
          },
        },
        {
          title: "Balance Points value in INR",
          render: (rowData) =>
            rowData["Balance Points value in INR"].toLocaleString("en-IN"),
          // export: true,
          headerStyle: {
            backgroundColor: "#ffedde",
          },
        },
      ];
      setColumns(tempColumns);
    } else if (selectedReportCategory.value === "2") {
      tempColumns = [
        {
          title: "Employee Name",
          render: (rowData) => rowData["Employee Name"],
          headerStyle: {
            backgroundColor: "#ffedde",
          },
        },

        {
          title: "Email id",
          render: (rowData) => rowData["Email id"],
          headerStyle: {
            backgroundColor: "#ffedde",
          },
        },

        {
          title: "Reporting Manager",
          render: (rowData) => rowData["Reporting Manager"] || "NA",
          headerStyle: {
            backgroundColor: "#ffedde",
          },
        },

        {
          title: "Awards Name",
          render: (rowData) => rowData["Awards Name"],
          headerStyle: {
            backgroundColor: "#ffedde",
          },
        },

        {
          title: "Date of Award/Event Conducted",
          render: (rowData) =>
            formatDate(rowData["Date of Award/Event Conducted"]),
          headerStyle: {
            backgroundColor: "#ffedde",
          },
        },

        {
          title: "Points allocated",
          render: (rowData) =>
            rowData["Points allocated"].toLocaleString("en-IN"),
          headerStyle: {
            backgroundColor: "#ffedde",
          },
        },

        {
          title: "Points value in INR",
          render: (rowData) =>
            rowData["Points value in INR"].toLocaleString("en-IN"),
          headerStyle: {
            backgroundColor: "#ffedde",
          },
        },
      ];
      setColumns(tempColumns);
    } else if (selectedReportCategory.value === "3") {
      tempColumns = [
        {
          title: "Employee Name",
          render: (rowData) => rowData["Employee Name"],
          headerStyle: {
            backgroundColor: "#ffedde",
          },
        },
        {
          title: "Email id",
          render: (rowData) => rowData["Email id"],
          headerStyle: {
            backgroundColor: "#ffedde",
          },
        },
        {
          title: "Reporting Manager",
          render: (rowData) => rowData["Reporting Manager"] || "NA",
          headerStyle: {
            backgroundColor: "#ffedde",
          },
        },
        {
          title: "Total Redemptions",
          render: (rowData) =>
            rowData["Total Redemptions"].toLocaleString("en-IN"),
          headerStyle: {
            backgroundColor: "#ffedde",
          },
        },
      ];
      setColumns(tempColumns);
    } else if (selectedReportCategory.value === "4") {
      tempColumns = [
        {
          title: "Gift Card Code",
          render: (rowData) => minimizeGiftCardCode(rowData["Gift Card Code"]),
          headerStyle: {
            backgroundColor: "#ffedde",
          },
        },

        {
          title: "Value in INR",
          render: (rowData) => rowData["Value in INR"].toLocaleString("en-IN"),
          headerStyle: {
            backgroundColor: "#ffedde",
          },
        },

        {
          title: "Created At",
          render: (rowData) => formatDate(rowData["Created At"]),
          headerStyle: {
            backgroundColor: "#ffedde",
          },
        },

        {
          title: "Status",
          render: (rowData) => rowData["Status"],
          headerStyle: {
            backgroundColor: "#ffedde",
          },
        },

        {
          title: "Assigned To",
          render: (rowData) => rowData["Assigned To"] || "NA",
          headerStyle: {
            backgroundColor: "#ffedde",
          },
        },

        {
          title: "Email ID",
          render: (rowData) =>
            rowData["Email ID"] !== "NA" ? rowData["Email ID"] : "NA",
          headerStyle: {
            backgroundColor: "#ffedde",
          },
        },

        {
          title: "Date Assigned",
          render: (rowData) =>
            rowData["Date Assigned"] && rowData["Date Assigned"] !== "NA"
              ? formatDate(rowData["Date Assigned"])
              : "NA",
          headerStyle: {
            backgroundColor: "#ffedde",
          },
        },
      ];
      setColumns(tempColumns);
    }
  }, [selectedReportCategory.value]);
  return (
    <div className="page-wrapper" style={{ backgroundColor: "white" }}>
      <div className="content container-fluid">
        {/* Breadcrumb */}
        <div className="page-header">
          <div className="row">
            <div className="col">
              <h3 className="page-title"> Reports</h3>
              <ul className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="/home">
                    <span>
                      <i className="la la-home"></i> Home
                    </span>
                  </Link>
                </li>
                <li className="breadcrumb-item active">
                  {" "}
                  <i className="la la-file-alt"></i>Reports
                </li>
              </ul>
            </div>
          </div>
        </div>
        {/* End of Breadcrumb */}

        {/* Date Pickers */}
        <div className="row mt-3">
          <div className="col-md-6">
            <div className="form-group form-border">
              <label>
                Start Date:{" "}
                <span>
                  {reportErrors.startDate && (
                    <p style={{ color: "red" }}> {reportErrors.startDate}</p>
                  )}
                </span>
              </label>

              <input
                type="date"
                className="form-control"
                placeholder="dd-mm-yyyy"
                max={today.toISOString().split("T")[0]}
                name="startDate"
                onChange={(event) =>
                  setStartDate(
                    event.target.value ? new Date(event.target.value) : null
                  )
                }
                value={startDate ? startDate.toISOString().split("T")[0] : ""}
              />
            </div>
          </div>

          <div className="col-md-6">
            <div className="form-group form-border">
              <label>
                End Date:{" "}
                <span>
                  {reportErrors.endDate && (
                    <p style={{ color: "red" }}> {reportErrors.endDate}</p>
                  )}
                </span>
              </label>

              <input
                type="date"
                className="form-control"
                max={today.toISOString().split("T")[0]}
                name="endDate"
                onChange={(event) => setEndDate(new Date(event.target.value))}
                value={endDate.toISOString().split("T")[0]}
              />
            </div>
          </div>

          <div className="col-md-6">
            <div className="form-group form-border">
              <label>
                Report Category:
                <span>
                  {reportErrors.reportCategory && (
                    <p style={{ color: "red" }}>
                      {" "}
                      {reportErrors.reportCategory}
                    </p>
                  )}
                </span>
              </label>

              <Select
                options={reportCategoryOptions}
                value={selectedReportCategory}
                onChange={handleCategoryChange}
                styles={customStyles}
              />
            </div>
          </div>

          <div className="col-md-6">
            <div className="form-group form-border">
              {loading ? (
                <button className="btn add-btn" disabled>
                  <Beatloader />
                </button>
              ) : (
                <button
                  onClick={handleGenerateReport}
                  className="btn add-btn"
                  style={{ marginTop: "22px" }}
                  disabled={loading || (isFormSubmitted && !validateReport())}
                >
                  Generate Report
                </button>
              )}
            </div>
          </div>
        </div>

        {/* Report Category */}

        {isReportGenerated && hasDataToDisplay() ? (
          // <div className="table-wrapper">
          //   <table className="table table-striped custom-table mb-0 datatable">
          //     <thead>{renderTableHeaders()}</thead>
          //     <tbody>{renderTableRows()}</tbody>
          //   </table>
          //   {totalPages > 1 && (
          //     <Pagination
          //       itemsPerPage={pageSize}
          //       totalItems={totalItems}
          //       paginateNext={handleNextPage}
          //       paginatePrevious={handlePreviousPage}
          //       currentPage={currentPage}
          //       totalPages={totalPages}
          //     />
          //   )}
          // </div>

          <div className="row mt-3">
            <ThemeProvider theme={defaultMaterialTheme}>
              <div style={{ width: "100%" }}>
                <MaterialTable
                  title={title}
                  tableRef={tableRef}
                  columns={columns}
                  data={(query) =>
                    new Promise((resolve, reject) => {
                      const formattedStartDate = startDate
                        .toISOString()
                        .split("T")[0];
                      const formattedEndDate = endDate
                        .toISOString()
                        .split("T")[0];

                      let url =
                        process.env.REACT_APP_BACKEND_URL + "/report/view";

                      url += "?startdate=" + formattedStartDate;
                      url += "&enddate=" + formattedEndDate;
                      url += "&report_type=" + selectedReportCategory.value;
                      url += "&page=" + (query.page + 1);
                      url += "&size=" + rowsPerPage;

                      fetch(url, {
                        headers: {
                          Authorization: `Bearer ${getCustomToken()}`,
                        },
                      })
                        .then((response) => response.json())
                        .then((result) => {
                          console.info("RESULT:", result);

                          setTitle(result.name);

                          resolve({
                            data: result.data.items,
                            page: result.data.page - 1,
                            totalCount: result.data.total,
                          });
                        })
                        .catch((error) => {
                          console.error("Error fetching groups:", error);
                        });
                    })
                  }
                  icons={tableIcons}
                  options={{
                    headerStyle: { backgroundColor: "#ffedde" },
                    showTitle: true,
                    paging: true,
                    search: false,
                    sorting: false,
                    actionsColumnIndex: -1,
                    // exportButton: true,
                    paginationType: "normal",
                    pageSize: rowsPerPage,
                    emptyRowsWhenPaging: false,
                    actionsCellStyle: {
                      justifyContent: "center",
                    },
                  }}
                  localization={{
                    header: {
                      actions: "",
                    },
                  }}
                  // onSearchChange={handleChange}
                  // searchText={searchTerm}
                  components={{
                    Pagination: (props) => (
                      <TablePagination
                        {...props}
                        rowsPerPageOptions={[5, 10, 15]}
                        rowsPerPage={rowsPerPage}
                        onRowsPerPageChange={handleChnageRowsPerPage}
                      />
                    ),
                  }}
                  actions={[
                    {
                      icon: "refresh",
                      tooltip: "Refresh Data",
                      isFreeAction: true,
                      onClick: () =>
                        tableRef.current && tableRef.current.onQueryChange(),
                    },
                    {
                      icon: () => <SaveAlt />,
                      tooltip: "Export Data",
                      isFreeAction: true,
                      onClick: () => downloadReport(),
                    },
                  ]}
                />
              </div>
            </ThemeProvider>
          </div>
        ) : isReportGenerated ? (
          <div className="alert alert-info" role="alert">
            No data available for the selected criteria.
          </div>
        ) : null}
      </div>
    </div>
  );
}

export default Reports;
