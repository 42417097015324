import { Fragment, useContext, useEffect, useState } from "react";
import "react-toastify/dist/ReactToastify.css";

import { Dropdown, Modal } from "react-bootstrap";
import { Link } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import { UserContext } from "../hooks/UserContext";

import DashboardService from "../services/Dashboard.service";

import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import {
  FailedNotification,
  SuccessNotifation,
} from "../components/Notifications";
import { Beatloader } from "../components/Spinner";
import { checkError, fieldValidate } from "../helpers/validators";

import {
  ChevronLeft,
  ChevronRight,
  FirstPage,
  LastPage,
} from "@material-ui/icons";
import Load from "../components/Loader";

import {
  TablePagination,
  tablePaginationClasses as classes,
} from "@mui/base/TablePagination";

function Banner(props) {
  const { currentUser } = useContext(UserContext);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (currentUser) {
      // If currentUser is defined, set isLoading to false
      setIsLoading(false);
    }
  }, [currentUser]);

  const [showbanner, setShowBanner] = useState(false);
  const [toggleSpinner, settoggleSpinner] = useState(false);
  const [totalItems, setTotalItems] = useState(0); // Add state for total items

  const [modal, setModal] = useState(false);
  const [ActiveItem, setActiveItem] = useState({
    id: "",
    title: "",
    description: "",
    image: "",
  });

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const handleChangePage = (event, newPage) => {
    console.log("handleChangePage: ", newPage);
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleClose = () => setModal((current) => !current);
  const handleShow = (req) => {
    setModal(true);
    setActiveItem(req);
  };

  // eslint-disable-next-line no-unused-vars
  const [formData, setFormData] = useState({
    title: "",
    descriptionb: "",
    image: "",
  });
  const [errors, setErrors] = useState({
    title: "",
    descriptionb: "",
    image: "",
  });

  const [currPage, setCurrPage] = useState(1);
  // eslint-disable-next-line no-unused-vars
  const [prevPage, setPrevPage] = useState(0);
  const [bannerList, setBannerList] = useState([]);
  // eslint-disable-next-line no-unused-vars
  const [Loader, setLoader] = useState(true);

  const toggleCreateBannerForm = () => {
    setShowBanner((current) => !current);
    setFormData({
      title: "",
      descriptionb: "",
      image: "",
    });
    setErrors({
      title: "",
      descriptionb: "",
      image: "",
    });
  };

  const [searchText, setSearchText] = useState("");

  const fetchData = async () => {
    setIsLoading(true);
    try {
      const response = await DashboardService.getBanners(
        page + 1,
        rowsPerPage,
        searchText
      );
      setBannerList(response.data.items);
      setTotalItems(response.data.total);
      // setWasLastList(response.data.items.length < pageSize);
    } catch (error) {
      console.error("Error fetching banners:", error);

      if (error.response) {
        // The request was made and the server responded with a status code >= 400
        console.error("Error:", error.response.data);
        console.error("Status Code:", error.response.status);
        FailedNotification(error.response.data.detail);
      } else if (error.request) {
        // The request was made but no response was received
        console.error("No response received:", error.request);
      } else {
        // Something else happened while setting up the request
        console.error("Error:", error.message);
        FailedNotification(error.message);
      }
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (!showbanner) {
      fetchData();
    }
  }, [page, showbanner, searchText, rowsPerPage, modal]);

  useEffect(() => {
    console.log("II");
    if (!modal === (showbanner || !showbanner)) {
      setCurrPage(1);
      setPrevPage(0);
      setBannerList([]);
      // setWasLastList(false);
    }
  }, [showbanner, modal]);

  const changeHandler = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const fileChangeHandler = (e) => {
    const { name } = e.target;
    setFormData({
      ...formData,
      [name]: e.target.files[0],
    });
  };

  const submitHandler = (e) => {
    e.preventDefault();

    console.log(formData);

    DashboardService.createBanner(formData)
      .then((response) => {
        console.log(response);
        SuccessNotifation("Banner Created!");
      })
      .catch((error) => {
        if (error.response) {
          console.error("Error:", error.response.data);
          console.error("Status Code:", error.response.status);
          FailedNotification(error.response.data.detail);
        } else if (error.request) {
          console.error("No response received:", error.request);
        } else {
          console.error("Error:", error.message);
        }
      })
      .finally(() => {
        settoggleSpinner((current) => !current);
        setShowBanner(false);
        setIsLoading(true);
      });
  };

  const tableIcons = {
    FirstPage: FirstPage,
    LastPage: LastPage,
    NextPage: ChevronRight,
    PreviousPage: ChevronLeft,
  };

  const DeleteHandler = (e) => {
    e.preventDefault();
    settoggleSpinner(true);

    DashboardService.deleteBanner(ActiveItem.id)
      .then((response) => {
        SuccessNotifation("Banner Deleted!");
        setBannerList(
          bannerList.filter((banner) => banner.id !== ActiveItem.id)
        );
        fetchData();
      })
      .catch((error) => {
        console.error("error: ", error);
        FailedNotification(error.detail);
      })
      .finally(() => {
        settoggleSpinner((current) => !current);
        setModal((current) => !current);
      });
  };

  if (bannerList.length === 0 && currPage > 1) {
    setCurrPage(currPage - 1);
  }

  const itemsArray = Array.from({ length: 16 }, (_, index) => index);

  const validateForm = (e) => {
    console.log("Im here");
    e.preventDefault();
    const newErrors = { ...errors };
    Object.keys(formData).forEach((field) => {
      switch (field) {
        case "title":
          newErrors.title = fieldValidate(field, formData[field], {
            required: true,
          });
          break;
        case "descriptionb":
          newErrors.descriptionb = fieldValidate(field, formData[field], {
            required: true,
          });
          break;

        case "image":
          newErrors.image = fieldValidate(field, formData[field], {
            required: true,
          });
          break;

        default:
          break;
      }
    });
    setErrors(newErrors);

    console.log("ERROR_DATA:", newErrors);

    const noError = checkError(newErrors);

    if (noError) {
      settoggleSpinner((current) => !current);
      submitHandler(e);
    }
  };

  return (
    <div className="page-wrapper" style={{ backgroundColor: "white" }}>
      <div className="content">
        <div className="page-header">
          <div className="row">
            <div className="col">
              <h3 className="page-title">Banners</h3>
              <ul className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="/home">
                    <span>
                      <i className="la la-home"></i> Home
                    </span>
                  </Link>
                </li>
                <li className="breadcrumb-item active">
                  <i className="la la-image"></i> Banners
                </li>
              </ul>
            </div>
            <div
              className="col-auto float-right ml-auto"
              style={{ marginRight: "3vh" }}
            >
              <div onClick={toggleCreateBannerForm} className="btn add-btn">
                {showbanner ? "Cancel" : "Create"}
              </div>
            </div>
          </div>
        </div>

        {!showbanner && (
          <div className="row justify-content-end">
            <div className="col-sm-6 col-md-4">
              <div className="top-nav-search position-relative form-focus">
                <input
                  className="form-control"
                  type="text"
                  placeholder="Search"
                  value={searchText}
                  onChange={(e) => setSearchText(e.target.value)}
                  style={{ borderColor: "#ff9b44" }} // Inline style for border color
                />
                <button className="btn" type="submit">
                  <i className="fa fa-search" style={{ color: "#ff9b44" }}></i>
                </button>
              </div>
            </div>
          </div>
        )}

        {isLoading ? (
          <div
            className="row align-items-center justify-content-center"
            style={{
              flexDirection: "column",
              // backgroundColor: "red",
              width: "100%",
              height: "60vh",
            }}
          >
            <Load />
          </div>
        ) : (
          <>
            {showbanner && (
              <div className="row">
                <div className="col-md-12">
                  <div className="card mb-0">
                    <div className="card-body">
                      <form onSubmit={validateForm}>
                        <div className="row">
                          <div className="col-md-12">
                            <div className="form-group form-border">
                              <label>
                                Title: <span style={{ color: "red" }}> * </span>
                                <span style={{ color: "red" }}>
                                  {errors.title && errors.title}
                                </span>
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Enter Title"
                                // required
                                name="title"
                                value={formData.title}
                                onChange={changeHandler}
                              />
                            </div>
                          </div>
                          <div className="col-md-12">
                            <div className="form-group form-border">
                              <label>
                                Description:
                                <span style={{ color: "red" }}> * </span>
                                <span style={{ color: "red" }}>
                                  {errors.descriptionb && errors.descriptionb}
                                </span>
                              </label>
                              <textarea
                                rows="2"
                                cols="5"
                                className="form-control"
                                placeholder="Enter Description"
                                // required
                                name="descriptionb"
                                value={formData.descriptionb}
                                onChange={changeHandler}
                              ></textarea>
                            </div>
                          </div>
                        </div>

                        <div className="row">
                          <div className="col-md-12">
                            <div className="form-group form-border">
                              <label className="col-form-label">
                                Upload Document:{" "}
                                <span style={{ color: "red" }}> * </span>
                                <span style={{ color: "red" }}>
                                  {errors.image && errors.image}
                                </span>
                              </label>
                              <input
                                type="file"
                                name="image"
                                className="form-control"
                                // required
                                accept=".png, .jpg, .jpeg"
                                onChange={fileChangeHandler}
                              />
                            </div>

                            <ul className="star-list">
                              <li>Supported formats: (jpeg, jpg, png)</li>

                              <li>Preferred dimensions: 760x260 pixels.</li>
                            </ul>
                          </div>
                        </div>

                        <div className="text-right">
                          {toggleSpinner ? (
                            <button
                              // onClick={handleClick}
                              className="btn add-btn"
                              disabled
                            >
                              <Beatloader />
                            </button>
                          ) : (
                            <button
                              type="submit"
                              // onClick={handleClick}
                              className="btn add-btn"
                            >
                              Create
                            </button>
                          )}
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            )}

            {!showbanner && Loader && bannerList.length === 0 && (
              <div
                className="row align-items-center justify-content-center"
                style={{
                  flexDirection: "column",
                  width: "100%",
                  height: "60vh",
                }}
              >
                <div
                  className="row align-items-center justify-content-center"
                  style={{ width: "100%" }}
                >
                  <div
                    className="col-md-6 text-center"
                    style={{
                      textTransform: "uppercase",
                    }}
                  >
                    <span style={{ color: "#ff9b44" }}>Create banner</span>
                  </div>
                </div>

                <div
                  className="row align-items-center justify-content-center"
                  style={{ width: "100%" }}
                >
                  <div
                    className="col-md-6 text-center"
                    style={{ textTransform: "uppercase" }}
                  >
                    <img
                      src={require("../assets/img/undraw_No_data_re_kwbl.png")}
                      alt="React Logo"
                      height={100}
                    />
                  </div>
                </div>
              </div>
            )}

            {!showbanner && (
              <div className="row mt-3">
                {isLoading &&
                  itemsArray.map((_, index) => (
                    <div
                      className="col-md-4 col-sm-6 col-12 col-lg-4 col-xl-3"
                      key={index}
                    >
                      <Skeleton height={"10vh"} />
                    </div>
                  ))}
                {bannerList.map((banner, index) => (
                  <div
                    className="col-md-4 col-sm-6 col-12 col-lg-4 col-xl-3 "
                    key={index}
                  >
                    <div className="profile-widget" style={{ padding: 10 }}>
                      <div>
                        <div
                          style={{
                            backgroundImage: `url("${banner.image}")`,
                            height: "90px",
                            backgroundSize: "cover",
                          }}
                        ></div>

                        <h4 className="user-name m-t-10 mb-0 text-ellipsis text-left">
                          {banner.title}
                        </h4>

                        <span className="user-name m-t-10 mb-0 text-ellipsis text-left">
                          {banner.description}
                        </span>
                      </div>
                      <Dropdown className="profile-action" as="div">
                        <Dropdown.Toggle
                          className="nav-link action-icon"
                          as="a"
                          id="dropdown-basic1"
                          style={{ paddingTop: 15 }}
                        >
                          <i
                            className="fa fa-ellipsis-v"
                            style={{ color: "#000" }}
                          ></i>
                        </Dropdown.Toggle>

                        <Dropdown.Menu
                          className="dropdown-menu-right"
                          style={{ backgroundColor: "#ffedde" }}
                        >
                          <Dropdown.Item
                            href="#"
                            onClick={() => {
                              handleShow(banner);
                            }}
                          >
                            <i className="fa fa-trash m-r-5"></i> Delete
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    </div>
                  </div>
                ))}

                {!isLoading && bannerList.length > 0 && (
                  <>
                    <div className="row mt-3">
                      {bannerList.map((banner, index) => (
                        <div
                          className="col-md-4 col-sm-6 col-12 col-lg-4 col-xl-3"
                          key={index}
                        ></div>
                      ))}
                    </div>
                    {/* <div
                      className="pagination-wrapper"
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        flexDirection: "column",
                        width: "100%",
                        marginTop: "2rem",
                        marginBottom: "1rem",
                      }}
                    >
                      <Pagination
                        itemsPerPage={pageSize}
                        totalItems={totalItems}
                        paginateNext={paginateNext}
                        paginatePrevious={paginatePrevious}
                        currentPage={currPage}
                      />
                    </div> */}

                    <div
                      className="pagination-wrapper"
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        flexDirection: "column",
                        width: "100%",
                        marginTop: "2rem",
                        marginBottom: "1rem",
                      }}
                    >
                      <Fragment>
                        <div className="TablePaginationIntroductionDemo">
                          <table aria-label="custom pagination table">
                            <tfoot>
                              <tr>
                                <TablePagination
                                  className="CustomTablePagination"
                                  rowsPerPageOptions={[
                                    5, 10, 15, 30,
                                    // { label: "All", value: -1 },
                                  ]}
                                  colSpan={3}
                                  count={totalItems}
                                  rowsPerPage={rowsPerPage}
                                  page={page}
                                  slotProps={{
                                    select: {
                                      "aria-label": "Rows per page",
                                    },
                                    actions: {
                                      showFirstButton: true,
                                      showLastButton: true,
                                      slots: {
                                        firstPageIcon: tableIcons.FirstPage,
                                        lastPageIcon: tableIcons.LastPage,
                                        nextPageIcon: tableIcons.NextPage,
                                        backPageIcon: tableIcons.PreviousPage,
                                      },
                                    },
                                  }}
                                  onPageChange={handleChangePage}
                                  onRowsPerPageChange={handleChangeRowsPerPage}
                                />

                                {/* <ThemeProvider theme={defaultMaterialTheme}>
                                  <MTablePagination

                                    className="CustomTablePagination"
                                    rowsPerPageOptions={[5, 10, 25]}
                                    colSpan={3}
                                    count={totalItems}
                                    rowsPerPage={rowsPerPage}
                                    page={page}
                                    ActionsComponent={(subProps) => {
                                      const {
                                        onChangePage,
                                        onChangeRowsPerPage,
                                      } = subProps;
                                      return (
                                        <div>
                                          <IconButton
                                            onClick={() => onChangePage(0)}
                                            disabled={page === 0}
                                            aria-label="First Page"
                                          >
                                            {tableIcons.FirstPage}
                                          </IconButton>
                                          <IconButton
                                            onClick={() =>
                                              onChangePage(page - 1)
                                            }
                                            disabled={page === 0}
                                            aria-label="Previous Page"
                                          >
                                            {tableIcons.PreviousPage}
                                          </IconButton>
                                          <IconButton
                                            onClick={() =>
                                              onChangePage(page + 1)
                                            }
                                            disabled={
                                              page >=
                                              Math.ceil(
                                                totalItems / rowsPerPage
                                              ) -
                                                1
                                            }
                                            aria-label="Next Page"
                                          >
                                            {tableIcons.NextPage}
                                          </IconButton>
                                          <IconButton
                                            onClick={() =>
                                              onChangePage(
                                                Math.max(
                                                  0,
                                                  Math.ceil(
                                                    totalItems / rowsPerPage
                                                  ) - 1
                                                )
                                              )
                                            }
                                            disabled={
                                              page >=
                                              Math.ceil(
                                                totalItems / rowsPerPage
                                              ) -
                                                1
                                            }
                                            aria-label="Last Page"
                                          >
                                            {tableIcons.LastPage}
                                          </IconButton>
                                          <select
                                            value={rowsPerPage}
                                            onChange={(e) =>
                                              onChangeRowsPerPage(
                                                e.target.value
                                              )
                                            }
                                            aria-label="Rows per page"
                                          >
                                            {[5, 10, 25].map((pageSize) => (
                                              <option
                                                key={pageSize}
                                                value={pageSize}
                                              >
                                                {pageSize}
                                              </option>
                                            ))}
                                          </select>
                                        </div>
                                      );
                                    }}
                                    onPageChange={handleChangePage}
                                    onRowsPerPageChange={
                                      handleChangeRowsPerPage
                                    }
                                  />
                                </ThemeProvider> */}
                              </tr>
                            </tfoot>
                          </table>
                        </div>
                        <Styles />
                      </Fragment>
                    </div>
                  </>
                )}
              </div>
            )}
          </>
        )}
      </div>

      <Modal
        show={modal}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        className="modal-right"
      >
        <div className="modal-header">
          <h5 className="modal-title" style={{ color: "red" }}>
            Delete Banner
          </h5>
          <button
            type="button"
            className="close"
            onClick={handleClose}
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body">
          <form className="fullStretch" onSubmit={submitHandler}>
            <div className="form-group">
              <label>&nbsp;</label>
              <div className="col-md-12">
                <div className="form-group form-border">
                  <label>Title:</label>

                  <div
                    className="form-control"
                    name="sel_reward_applicable"
                    style={{ paddingTop: "4%" }}
                  >
                    {ActiveItem.title}
                  </div>
                </div>
              </div>

              <div className="col-md-12">
                <div className="form-group form-border">
                  <label>Description:</label>

                  <div
                    className="form-control"
                    name="sel_reward_applicable"
                    style={{
                      height: "200px",
                      overflowY: "auto",
                      whiteSpace: "pre-wrap",
                      wordWrap: "break-word",
                    }}
                  >
                    {ActiveItem.description}
                  </div>
                </div>
              </div>

              <div className="col-md-12">
                <div className="form-group form-border">
                  <label>Image:</label>

                  <div
                    className="form-control"
                    name="sel_reward_applicable"
                    style={{ paddingTop: "4%", height: "15vh" }}
                  >
                    <div
                      style={{
                        backgroundImage: `url("${ActiveItem.image}")`,
                        height: "90px",
                        backgroundSize: "cover",
                      }}
                    ></div>
                  </div>

                  <div
                    className="col-md-6 text-center"
                    style={{ textTransform: "uppercase" }}
                  ></div>
                </div>
              </div>
            </div>
            <div className="modal-footer text-center">
              {toggleSpinner ? (
                <button
                  // onClick={handleClick}
                  className="btn add-btn"
                  disabled
                >
                  <Beatloader />
                </button>
              ) : (
                <button
                  type="submit"
                  // onClick={handleClick}
                  className="btn add-btn"
                  style={{ textTransform: "uppercase" }}
                  onClick={DeleteHandler}
                >
                  delete
                </button>
              )}
            </div>
          </form>
        </div>
      </Modal>
    </div>
  );
}

export default Banner;

const cyan = {
  50: "#E9F8FC",
  100: "#BDEBF4",
  200: "#99D8E5",
  300: "#66BACC",
  400: "#1F94AD",
  500: "#0D5463",
  600: "#094855",
  700: "#063C47",
  800: "#043039",
  900: "#022127",
};

const grey = {
  50: "#F3F6F9",
  100: "#E5EAF2",
  200: "#DAE2ED",
  300: "#C7D0DD",
  400: "#B0B8C4",
  500: "#9DA8B7",
  600: "#6B7A90",
  700: "#434D5B",
  800: "#303740",
  900: "#1C2025",
};

function Styles() {
  // Replace this with your app logic for determining dark mode

  return (
    <style>
      {`
      .TablePaginationIntroductionDemo {
        width: 500px;
        max-width: 100%;
      }

      th {
        background-color: "#fff"};
      }

      .TablePaginationIntroductionDemo table {
        font-family: 'IBM Plex Sans', sans-serif;
        font-size: 0.875rem;
        width: 100%;
        box-shadow: 0px 4px 16px ${grey[200]};
        background-color:"#fff"};
        border: 1px solid ${grey[200]};
        border-radius: 12px;
        overflow: hidden;
      }

      .TablePaginationIntroductionDemo td,
      .TablePaginationIntroductionDemo th {
        padding: 16px;
      }

      .TablePaginationIntroductionDemo th {
        background-color: ${"#fff"};
      }

      .CustomTablePagination .${classes.spacer} {
        display: none;
      }

      .CustomTablePagination .${classes.toolbar}  {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 8px;

        @media (min-width: 768px) {
          flex-direction: row;
          align-items: center;
        }
      }

      .CustomTablePagination .${classes.selectLabel} {
        margin: 0;
      }

      .CustomTablePagination .${classes.select} {
        font-family: 'IBM Plex Sans', sans-serif;
        padding: 2px 0 2px 4px;
        border: 1px solid ${grey[200]};
        border-radius: 6px;
        background-color: transparent;
        color: ${grey[900]};
        transition: all 100ms ease;
      }

      .CustomTablePagination .${classes.select}:hover {
        background-color: ${grey[50]};
        border-color: ${grey[300]};
      }

      .CustomTablePagination .${classes.select}:focus {
        outline: 3px solid ${cyan[200]};
        border-color: ${cyan[500]};
      }

      .CustomTablePagination .${classes.displayedRows} {
        margin: 0;

        @media (min-width: 768px) {
          margin-left: auto;
        }
      }

      .CustomTablePagination .${classes.actions} {
        display: flex;
        gap: 6px;
        border: transparent;
        text-align: center;
      }

      .CustomTablePagination .${classes.actions} > button {
        display: flex;
        align-items: center;
        padding: 0;
        border: transparent;
        border-radius: 50%;
        background-color: transparent;
        border: 1px solid ${grey[200]};
        color: ${grey[900]};
        transition: all 120ms ease;

        > svg {
          font-size: 22px;
        }
      }

      .CustomTablePagination .${classes.actions} > button:hover {
        background-color: ${grey[50]};
        border-color: ${grey[300]};
      }

      .CustomTablePagination .${classes.actions} > button:focus {
        outline: 3px solid ${cyan[200]};
        border-color: ${cyan[500]};
      }

      .CustomTablePagination .${classes.actions} > button:disabled {
        opacity: 0.3;
      }

      .CustomTablePagination .${classes.actions} > button:disabled:hover {
        border: 1px solid ${grey[200]};
        background-color: transparent;
      }
      `}
    </style>
  );
}
