/* eslint-disable no-unused-vars */
import { Box, ThemeProvider, createTheme } from "@mui/material";
import React, { createRef, useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom/dist";
import "react-toastify/dist/ReactToastify.css";
import {
  FailedNotification,
  SuccessNotifation,
} from "../components/Notifications";
import { Beatloader } from "../components/Spinner";
import { fieldValidate } from "../helpers/validators";
import { UserContext } from "../hooks/UserContext";
import CoreService from "../services/Core.service";
import RewardService from "../services/Reward.Service";

import "react-toastify/dist/ReactToastify.css";

import "react-loading-skeleton/dist/skeleton.css";

import MaterialTable, { MTablePagination } from "material-table";
import AvatarGroup from "react-avatar-group";
import MyEditor from "../components/Editor";
import ItemRewardGroupChip from "../components/ItemRewardGroupChip";
import ItemRewardUserChip from "../components/ItemRewardUserChip";
import tableIcons from "../components/MaterialTableIcons";
import { getCustomToken } from "../helpers/utils";
import useDebounce from "../hooks/useDebounce";
import { TablePagination } from "@material-ui/core";

function Reward() {
  const { currentUser } = useContext(UserContext);
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(true);
  const [showForm, setShowForm] = useState(false);

  const [toggleSpinner, settoggleSpinner] = useState(false);

  const [searchTerm, setSearchTerm] = useState("");

  const handleSearch = useDebounce((term) => {
    // Perform search operation with the debounced term
    console.log("Searching for:", term);
  }, 500);

  const handleChange = (event) => {
    const { value } = event.target;

    console.log("handlrchnage: ", value);
    setSearchTerm(value);

    // Debounce the search callback
    handleSearch(value);
  };

  // function getGroupNames(inputArray) {
  //   // Check if the input is an array and not empty
  //   if (!Array.isArray(inputArray) || inputArray.length === 0) {
  //     return [];
  //   }

  //   // Use the map function to extract the "name" property from each "group" object
  //   const groupNames = inputArray
  //     .filter((item) => item.groups && item.groups.is_public === true)
  //     .map((item) => item.groups && item.groups.name);

  //   // Filter out any undefined values (in case "group" property is missing in some objects)
  //   return groupNames.filter((name) => name !== undefined);
  // }

  // function getUserNames(inputArray) {
  //   // Check if the input is an array and not empty
  //   if (!Array.isArray(inputArray) || inputArray.length === 0) {
  //     return [];
  //   }

  //   // Use the map function to extract the "display_name" property from each "users" object
  //   const userNames = inputArray
  //     .filter(
  //       (item) =>
  //         item.groups &&
  //         !item.groups.is_public &&
  //         item.groups.group_users &&
  //         item.groups.group_users.length > 0
  //     )
  //     .flatMap((item) =>
  //       item.groups.group_users.map((user) => user.users.display_name)
  //     );

  //   return userNames;
  // }

  function getGroupAndUserNames(inputArray) {
    // Check if the input is an array and not empty
    if (!Array.isArray(inputArray) || inputArray.length === 0) {
      return [];
    }

    // Initialize arrays to store group names and user names
    let groupNames = [];
    let userNames = [];

    // Iterate through the input array
    inputArray.forEach((item) => {
      // Check if the item has groups property
      if (item.groups) {
        // Check if the group is public and extract group name
        if (item.groups.is_public === true) {
          if (item.groups.name) {
            groupNames.push(item.groups.name);
          }
        }
        // Check if the group is not public and has group users
        else if (
          !item.groups.is_public &&
          item.groups.group_users &&
          item.groups.group_users.length > 0
        ) {
          // Extract user names from group users
          item.groups.group_users.forEach((user) => {
            if (user.users && user.users.display_name) {
              userNames.push(user.users.display_name);
            }
          });
        }
      }
    });

    // Concatenate group names and user names arrays into a single array
    const combinedNames = groupNames.concat(userNames);

    // Return the combined array
    return combinedNames;
  }

  // useEffect(() => {
  //   if (currentUser) {
  //     // If currentUser is defined, set isLoading to false
  //     setIsLoading1(false);
  //   }
  // }, [currentUser]);

  const [userReward, setRewardData] = useState([]);

  const [fetchError, setFetchError] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = 8;
  const [totalItems, setTotalItems] = useState(0);
  const [totalPages, setTotalPages] = useState(0);

  const [showEditModal, setShowEditModal] = useState(false);
  const [ActiveItem, setActiveItem] = useState({
    id: "",
    title: "",
    mode_id: "",
    description: "",
    points: 0,
    bonus_points: 0,
    employment_type: "Full Time",
    valid_from: "",
    valid_till: "",
    users: [],
    groups: [],
    is_published: false,
  });
  const handleShowEditModal = (reward) => {
    setHasParticipationPoints(reward.bonus_points > 0 ? true : false);
    setFormData({
      title: reward.title,
      mode_id: reward.mode.id,
      description: reward.description,
      points: reward.points,
      bonus_points: reward.bonus_points,
      employment_type: reward.employment.name,
      valid_from: reward.valid_from,
      valid_till: reward.valid_till,
      users: getPrivateGroups(reward),
      groups: getPublicGroups(reward),
      is_published: reward.is_published,
    });
    setActiveItem(reward);
    setShowEditModal(true);
  };

  const [formData, setFormData] = useState({
    title: "",
    mode_id: "",
    description: "",
    points: 0,
    bonus_points: 0,
    employment_type: "Full Time",
    valid_from: "",
    valid_till: "",
    users: [],
    groups: [],
    is_published: false,
  });

  const [errors, setErrors] = useState({
    title: "",
    mode_id: "",
    description: "",
    points: "",
    bonus_points: "",
    employment_type: "",
    valid_from: "",
    valid_till: "",
    users: "",
    groups: "",
    is_published: "",
  });

  const [selectAll, setSelectAll] = useState(false);

  const toggleSelectAll = () => {
    const newSelectAll = !selectAll;
    setSelectAll(newSelectAll);

    if (newSelectAll) {
      const allGroupIds = groupDropdown.items.map((each) => String(each.id));
      setFormData((prevData) => ({
        ...prevData,
        Rewards_applicable_for: allGroupIds,
      }));
    } else {
      setFormData((prevData) => ({
        ...prevData,
        Rewards_applicable_for: [],
      }));
    }
  };

  const changeHandler = (e) => {
    const { name, value } = e.target;

    // if (type === "checkbox") {
    //   if (checked) {
    //     // If checkbox is checked, add the value to the rewards_applicable_for array

    //     setFormData((prevData) => ({
    //       ...prevData,

    //       Rewards_applicable_for: [...prevData.Rewards_applicable_for, name],
    //     }));
    //   } else {
    //     // If checkbox is unchecked, remove the value from the rewards_applicable_for array

    //     setFormData((prevData) => ({
    //       ...prevData,

    //       Rewards_applicable_for: prevData.Rewards_applicable_for.filter(
    //         (group) => group !== name
    //       ),
    //     }));
    //   }
    // } else {
    //   setFormData((prevData) => ({
    //     ...prevData,

    //     [name]: value,
    //   }));
    // }

    // if (name === "mode") {
    //   setFormData((prevData) => ({
    //     ...prevData,

    //     mode: value, // Set the value of sel_reward_mode directly from the selected value
    //   }));
    // }

    // if (name === "points" && parseInt(value) < 0) {
    //   setErrors({ ...errors, [name]: "Points must be a positive number" });
    // } else {
    //   setErrors({ ...errors, [name]: "" });
    // }

    setFormData((prevData) => ({
      ...prevData,

      [name]: value,
    }));
  };

  const checkError = (errors) => {
    for (let key in errors) {
      if (errors[key] !== "" && errors[key] !== null) {
        return false; // There's an error
      }
    }
    return true; // There are no errors
  };

  // const SendRequest = async (_payload) => {
  //   const URL = `${process.env.REACT_APP_BACKEND_URL}/reward/create`;

  //   const payload = {
  //     title: _payload.title,
  //     description: _payload.description,
  //     is_expiry_date: false,
  //     points: +_payload.points,
  //     valid_from: new Date(_payload.valid_from).toISOString(),
  //     employment_type: _payload.employment_type,
  //     mode_id: _payload.mode_id,
  //   };

  //   if (_payload.valid_till) {
  //     payload.valid_till = new Date(_payload.valid_till).toISOString();
  //   }
  //   if (_payload.Rewards_applicable_for.length > 0) {
  //     payload.groups = _payload.Rewards_applicable_for;
  //   }
  //   console.log("Sending POST request to:", URL);

  //   return axios
  //     .post(URL, payload, {
  //       withCredentials: true,
  //       headers: {
  //         "Content-Type": "application/json",
  //       },
  //     })
  //     .then((response) => response.data)
  //     .catch((error) => {
  //       throw error;
  //     });
  // };

  const resetForm = () => {
    setHasParticipationPoints(false);
    setFormData({
      title: "",
      mode_id: "",
      description: "",
      points: "",
      bonus_points: "",
      employment_type: "Full Time",
      valid_from: "",
      valid_till: "",
      groups: [],
      users: [],
      is_published: false,
    });
    setSelectAll(false);
    // Reset any other state variables, like errors or loading states
    setErrors({
      title: "",
      mode_id: "",
      description: "",
      points: "",
      bonus_points: "",
      employment_type: "",
      valid_from: "",
      valid_till: "",
      users: "",
      groups: "",
      is_published: "",
    });
    setIsLoading(false);
    settoggleSpinner(false);
  };

  //////////////////////////////////////////////////////////////////////////////////////////////////////////////////

  // const submitHandler = async (e) => {
  //   console.log("Im here");
  //   e.preventDefault();
  //   // settoggleSpinner(true);
  //   setIsLoading(true);
  //   console.log("FORM_DATA:", formData);

  //   // Step 2: Log the form data before sending it

  //   // setIsLoading(true);

  //   // Check if all required fields are filled

  //   // if (
  //   //   formData.title.trim() === "" ||
  //   //   formData.mode_id === "" ||
  //   //   formData.description.trim() === "" ||
  //   //   formData.points === "" ||
  //   //   formData.valid_from === "" ||
  //   //   // formData.valid_till === "" ||
  //   //   formData.Rewards_applicable_for.length === 0
  //   // ) {
  //   //   FailedNotification("Please fill all the required fields");

  //   //   return;
  //   // }

  //   // Send the request if all required fields are filled
  //   settoggleSpinner(true);
  //   // setIsLoading(true);

  //   // SendRequest(formData)
  //   //   .then((newReward) => {
  //   //     SuccessNotifation("Reward Created Successfully");
  //   //     // setIsLoading(false);
  //   //     resetForm();

  //   //     setShowForm(false);
  //   //     // setRewardData((prevRewards) => [...prevRewards, newReward]);
  //   //     // fetchRewards();
  //   //     // const newLastPage = Math.ceil(userReward.length / itemsPerPage);
  //   //     // setCurrentPage(newLastPage);

  //   //     // navigate("/createreward");
  //   //     // resetForm();
  //   //     // // window.location.reload();
  //   //     // setTimeout(() => {
  //   //     //   settoggleSpinner(false);
  //   //     // }, 3000);
  //   //   })

  //   //   .catch((error) => {
  //   //     console.error("API_Error", error);
  //   //   });

  //   // setIsLoading(false);

  //   try {
  //     const payload = {
  //       title: formData.title,
  //       description: formData.description,
  //       is_expiry_date: false,

  //       points: formData.points,
  //       bonous_points: formData.bonous_points || 0,
  //       valid_from: new Date(formData.valid_from).toISOString(),
  //       employment_type: formData.employment_type,
  //       mode_id: formData.mode_id,
  //       is_published: formData.is_published || false,
  //       groups: (formData.groups || []).map((item) => item.groups.id),
  //       users: (formData.users || []).map((item) => item.users.id),
  //     };

  //     if (formData.valid_till) {
  //       payload.valid_till = new Date(formData.valid_till).toISOString();
  //     }

  //     const response = await RewardService.createReward(payload);

  //     SuccessNotifation("Reward Created!");
  //     setShowForm(false);
  //     resetForm();
  //     setCurrPage(1); // Reset to the first page to see the new group
  //   } catch (error) {
  //     if (error.response) {
  //       // The request was made and the server responded with a status code >= 400
  //       console.error("Error:", error.response.data);
  //       console.error("Status Code:", error.response.status);
  //       FailedNotification(error.response.data.detail);
  //     } else if (error.request) {
  //       // The request was made but no response was received
  //       console.error("No response received:", error.request);
  //       FailedNotification(error.request);
  //     } else {
  //       // Something else happened while setting up the request
  //       console.error("Error:", error.message);
  //     }
  //   } finally {
  //     settoggleSpinner(false); // Hide spinner after submission
  //   }
  // };

  // const fetchRewards = () => {
  //   return RewardService.getRewards()
  //     .then((response) => {
  //       setRewardData(response.data.items);
  //     })
  //     .catch((error) => {
  //       console.error(error.message);
  //       setFetchError(true); // Set the fetchError state to true
  //     });
  // };

  const [selectedReward, setSelectedReward] = useState(null);

  const handleEditClick = (userReward) => {
    setSelectedReward(userReward.id);
    // Implement the edit action here

    const editUrl = `/editreward?id=${userReward.id}`;
    console.log("Edit button clicked");
    // Add your logic to open a modal or navigate to an edit page
    // navigate(editUrl);
    navigate(editUrl, { state: { rewardData: userReward } });
  };

  const [rewardMode, setRewardMode] = useState([]);

  // // eslint-disable-next-line no-unused-vars
  // const [selectedRewardMode, setSelectedRewardMode] = useState("");

  // useEffect(() => {
  //   console.log("selectedRewardMode: ", selectedRewardMode);
  // }, [selectedRewardMode]);

  // Get all groups

  // eslint-disable-next-line no-unused-vars
  const [employements, setEmployements] = useState([]);

  const [groupDropdown, setRewardGroup] = useState({});

  // useEffect(() => {
  //   const fetchRewards = async () => {
  //     setIsLoading(true);
  //     try {
  //       const response = await RewardService.getRewards(currentPage, pageSize);
  //       console.info("REsult: ", response.data);
  //       setRewardData(response.data.items);
  //       setTotalItems(response.data.total);
  //       setTotalPages(Math.ceil(response.data.total / pageSize));
  //     } catch (error) {
  //       console.error("Error fetching rewards:", error);
  //       setFetchError(true);
  //     } finally {
  //       setIsLoading(false);
  //     }
  //   };

  //   fetchRewards();
  // }, [currentPage, pageSize]);

  useEffect(() => {
    const rewardModes = async () => {
      CoreService.getModes()
        .then((response) => {
          console.log(JSON.stringify(response.data));
          setRewardMode(response.data.items);
        })
        .catch((error) => {
          console.log(error);
          setFetchError(true); // Set the fetchError state to true
        });
    };

    if (showForm === true || showEditModal === true) {
      rewardModes();
    }
  }, [showForm, showEditModal]);

  // useEffect(() => {
  //   const rewardGroups = async () => {
  //     CoreService.getGroups()
  //       .then((response) => {
  //         console.log("ABC: ", JSON.stringify(response.data));
  //         setRewardGroup(response.data);
  //       })
  //       .catch((error) => {
  //         console.log(error);
  //         setFetchError(true); // Set the fetchError state to true
  //       });
  //   };

  //   if (showForm === true) {
  //     rewardGroups();
  //   }
  // }, [showForm]);

  // useEffect(() => {
  //   // Fetch employements
  //   const fetchEmployements = async () => {
  //     try {
  //       const response = await CoreService.getEmployements();
  //       console.log(JSON.stringify(response.data));
  //       setEmployements(response.data.items);
  //     } catch (error) {
  //       console.log(error);
  //       setFetchError(true); // Set the fetchError state to true
  //     }
  //   };

  //   if (showForm === true) {
  //     fetchEmployements();
  //   }
  // }, [showForm]);

  // const paginate = (pageNumber) => setCurrentPage(pageNumber);

  // const handleNextPage = async () => {
  //   const nextPageNumber = currentPage + 1;
  //   if (nextPageNumber <= totalPages) {
  //     setIsLoading(true);
  //     try {
  //       const response = await RewardService.getRewards(
  //         nextPageNumber,
  //         pageSize
  //       );
  //       console.log(response);
  //       setRewardData(response.data.items);
  //       setTotalItems(response.data.total);
  //       setCurrentPage(nextPageNumber); // Only update the page number after the data has been fetched
  //     } catch (error) {
  //       console.error("Error fetching next page:", error);
  //       setFetchError(true);
  //     } finally {
  //       setIsLoading(false);
  //     }
  //   }
  // };

  // const handlePreviousPage = async () => {
  //   const previousPageNumber = currentPage - 1;
  //   if (previousPageNumber >= 1) {
  //     setIsLoading(true);
  //     try {
  //       const response = await RewardService.getRewards(
  //         previousPageNumber,
  //         pageSize
  //       );
  //       setRewardData(response.data.items);
  //       setTotalItems(response.data.total);
  //       setCurrentPage(previousPageNumber); // Only update the page number after the data has been fetched
  //     } catch (error) {
  //       console.error("Error fetching previous page:", error);
  //       setFetchError(true);
  //     } finally {
  //       setIsLoading(false);
  //     }
  //   }
  // };

  const validateForm = (formData) => {
    console.log("Im here");
    console.log("FORM DATA:", formData);

    const newErrors = { ...errors };

    Object.keys(formData).forEach((field) => {
      switch (field) {
        case "title":
          // Validation for the "title" field
          newErrors.title = fieldValidate("titlereward", formData["title"], {
            required: true,
          });
          break;

        case "description":
          // Validation for the "description" field
          newErrors.description = fieldValidate(field, formData[field], {
            required: true,
          });
          break;

        case "mode_id":
          // Validation for the "mode_id" field
          newErrors.mode_id = fieldValidate(field, formData[field], {
            required: true,
          });
          break;

        case "points":
          if (!/^[+]?\d+$/.test(formData[field])) {
            newErrors.points = "Points must be a positive number";
          } else {
            // Validation for the "points" field
            newErrors.points = fieldValidate(field, formData[field], {
              required: true,
            });
          }
          break;

        // case "bonus_points":
        //   if (!/^[+]?\d+$/.test(formData[field])) {
        //     newErrors.bonus_points = "Points must be a positive number";
        //   } else {
        //     // Validation for the "points" field
        //     newErrors.bonus_points = fieldValidate(field, formData[field], {
        //       required: false,
        //     });
        //   }
        //   break;

        case "valid_from":
          // Validation for the "valid_from" field
          newErrors.valid_from = fieldValidate(field, formData[field], {
            required: true,
          });
          break;

        // case "valid_till":
        //   // Validation for the "valid_till" field
        //   newErrors.valid_till = fieldValidate(field, formData[field], {
        //     required: true,
        //   });
        //   break;

        // case "groups":
        //   // Validation for the "Rewards_applicable_for" field
        //   if (formData[field].length === 0) {
        //     newErrors.groups = "Select at least one applicable group";
        //   }
        //   break;

        default:
          break;
      }
    });

    setErrors(newErrors);

    const noError = checkError(newErrors);

    console.log("Im here");
    console.log(newErrors);
    console.log(noError);

    if (noError) {
      return true;
    } else {
      return false;
    }
  };

  const validateFormDraft = (formData) => {
    console.log("Im here");
    console.log("FORM DATA:", formData);

    const newErrors = { ...errors };

    newErrors.title = fieldValidate("title", formData["title"], {
      required: true,
    });

    setErrors(newErrors);

    const noError = checkError(newErrors);

    console.log("Im here");
    console.log(newErrors);
    console.log(noError);

    if (noError) {
      return true;
    } else {
      return false;
    }
  };

  // const renderTableAndPagination = () => {
  //   if (userReward.length > 0) {
  //     return (
  //       <>
  //         <div className="row">
  //           <div className="col-md-12 my-2">
  //             <table className="table table-striped custom-table mb-0 datatable">
  //               <thead>
  //                 <tr>
  //                   <th>Title</th>
  //                   <th>Start Date</th>
  //                   <th>End Date</th>
  //                   <th>Mode</th>
  //                   <th>Points</th>
  //                   <th>Groups</th>
  //                   <th className="text-right no-sort">Action</th>
  //                 </tr>
  //               </thead>
  //               <tbody>
  //                 {userReward.map((req) => (
  //                   <tr key={req.id}>
  //                     <td>{req.title}</td>
  //                     <td>{req.valid_from}</td>
  //                     <td>{req.valid_till}</td>
  //                     <td>{req.mode.name}</td>
  //                     <td>{req.points.toLocaleString("en-IN")}</td>
  //                     <td style={{ width: "20%" }}>
  //                       <AvatarGroup
  //                         avatars={getGroupNames(req.reward_groups)}
  //                         initialCharacters={2}
  //                         max={3}
  //                         size={30}
  //                         uppercase={true}
  //                         displayAllOnHover
  //                         shadow={2}
  //                         randomBackgroundColors={["#ff9b44"]}
  //                         fontSize={0.5}
  //                       />
  //                     </td>
  //                     <td className="text-right">
  //                       <Dropdown className="dropdown-action" as="div">
  //                         <Dropdown.Toggle
  //                           className="action-icon"
  //                           as="a"
  //                           id="dropdown-basic1"
  //                         >
  //                           <i className="material-icons">more_vert</i>
  //                         </Dropdown.Toggle>
  //                         <Dropdown.Menu className="dropdown-menu-right">
  //                           <Dropdown.Item
  //                             href="#"
  //                             onClick={() => handleEditClick(req)}
  //                           >
  //                             <i className="fa fa-pencil m-r-5"></i> Edit
  //                           </Dropdown.Item>
  //                         </Dropdown.Menu>
  //                       </Dropdown>
  //                     </td>
  //                   </tr>
  //                 ))}
  //               </tbody>
  //             </table>
  //           </div>
  //         </div>
  //         <div className="row">
  //           <div className="col-md-12 my-2">
  //             <Pagination
  //               currentPage={currentPage}
  //               itemsPerPage={pageSize}
  //               totalItems={totalItems}
  //               paginateNext={handleNextPage}
  //               paginatePrevious={handlePreviousPage}
  //             />
  //           </div>
  //         </div>
  //       </>
  //     );
  //   } else {
  //     return <Load />;
  //   }
  // };

  useEffect(() => {
    const fetchRewardModes = async () => {
      try {
        const response = await CoreService.getModes();
        const modes = response.data.items;
        setRewardMode(modes || null);
      } catch (error) {
        console.error(error.message);
      }
    };
    fetchRewardModes();
  }, [setRewardMode]);

  const toggleCreateRewardForm = () => {
    setHasParticipationPoints(false);
    setShowForm((current) => !current);
    setFormData({
      title: "",
      mode_id: "",
      description: "",
      points: "",
      bonus_points: "",
      employment_type: "Full Time",
      valid_from: "",
      valid_till: "",
      groups: [],
      users: [],
      is_published: false,
    });
    setErrors({
      title: "",
      mode_id: "",
      description: "",
      points: "",
      bonus_points: "",
      employment_type: "",
      valid_from: "",
      valid_till: "",
      users: "",
      groups: "",
      is_published: "",
    });
  };

  const toggleEditRewardForm = () => {
    setShowEditModal((current) => !current);
    setHasParticipationPoints(false);
    setFormData({
      title: "",
      mode_id: "",
      description: "",
      points: "",
      bonus_points: "",
      employment_type: "Full Time",
      valid_from: "",
      valid_till: "",
      groups: [],
      users: [],
      is_published: false,
    });
    setErrors({
      title: "",
      mode_id: "",
      description: "",
      points: "",
      bonus_points: "",
      employment_type: "",
      valid_from: "",
      valid_till: "",
      users: "",
      groups: "",
      is_published: "",
    });
  };

  const tableRef = createRef();

  const defaultMaterialTheme = createTheme();

  const [hasParticipationPoints, setHasParticipationPoints] = useState(false);

  // function hasPublicGroups(inputArray) {
  //   // Check if the input is an array and not empty
  //   if (!Array.isArray(inputArray) || inputArray.length === 0) {
  //     return false;
  //   }

  //   // Check if any object in the array has the property "is_public" set to true
  //   return inputArray.some((item) => item.groups && item.groups.is_public);
  // }

  const [columns, setColumns] = useState([
    {
      title: "Title",
      field: "title",
      // export: true,
      headerStyle: {
        backgroundColor: "#ffedde",
      },
    },

    {
      title: "Points (winner)",
      render: (rowData) => rowData.points.toLocaleString("en-IN") || "NA",
      // export: true,
      headerStyle: {
        backgroundColor: "#ffedde",
      },
    },

    {
      title: "Points (participation)",
      render: (rowData) =>
        rowData.bonus_points == 0
          ? "NA"
          : rowData.bonus_points.toLocaleString("en-IN"),
      // export: true,
      headerStyle: {
        backgroundColor: "#ffedde",
      },
    },

    {
      title: "Groups/Users",
      render: (rowData) =>
        rowData.reward_groups.length > 0 ? (
          <AvatarGroup
            avatars={getGroupAndUserNames(rowData.reward_groups)}
            initialCharacters={2}
            max={3}
            size={30}
            uppercase={true}
            displayAllOnHover
            shadow={2}
            randomBackgroundColors={["#ff9b44"]}
            fontSize={0.5}
          />
        ) : (
          "NA"
        ),
      headerStyle: {
        backgroundColor: "#ffedde",
      },
    },

    {
      title: "Status",
      render: (rowData) => (
        <span
          className={
            rowData.is_published
              ? "badge bg-inverse-success"
              : "badge bg-inverse-warning"
          }
        >
          {rowData.is_published ? "published" : "drafted"}
        </span>
      ),
      // export: true,
      headerStyle: {
        backgroundColor: "#ffedde",
      },
    },

    // {
    //   title: "Users",
    //   render: (rowData) =>
    //     rowData.group_users.length > 0 ? (
    //       <AvatarGroup
    //         avatars={getGroupNames(rowData.group_users)}
    //         initialCharacters={2}
    //         max={3}
    //         size={30}
    //         uppercase={true}
    //         displayAllOnHover
    //         shadow={2}
    //         randomBackgroundColors={["#ff9b44"]}
    //         fontSize={0.5}
    //       />
    //     ) : (
    //       "-"
    //     ), // export: true,
    //   headerStyle: {
    //     backgroundColor: "#ffedde",
    //   },
    // },

    // {
    //   title: "isActive",
    //   render: (rowData) => <Indicator is_active={rowData.is_active} />,
    //   export: true,
    //   headerStyle: {
    //     backgroundColor: "#ff9b44",
    //   },
    // },
  ]);

  const [currPage, setCurrPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  // eslint-disable-next-line no-unused-vars

  const handleRowsPerPageChange = (event) => {
    const newPageSize = parseInt(event.target.value, 10);
    setRowsPerPage(newPageSize);
    setCurrPage(1); // Reset current page to the first page
    // fetchBannerList(); // Trigger a new API call with the updated page size
    tableRef.current.onQueryChange();
  };

  const handleChnageRowsPerPage = (e) => {
    console.log("Im here");
    setRowsPerPage(e.target.value);

    tableRef.current.onChangePage(null, 0);

    // tableRef.current && tableRef.current.onQueryChange();
  };

  function getPublicGroups(data) {
    const publicGroups = [];
    for (const group of data.reward_groups) {
      if (group.groups.is_public) {
        publicGroups.push({
          groups: {
            id: group.groups.id,
            name: group.groups.name,
          },
        });
      }
    }
    return publicGroups;
  }

  function getPrivateGroups(data) {
    const privateGroups = [];
    for (const group of data.reward_groups) {
      if (!group.groups.is_public) {
        for (const user of group.groups.group_users) {
          privateGroups.push({
            users: {
              display_name: user.users.display_name,
              email_id: user.users.email_id,
              id: user.users.id,
            },
          });
        }
      }
    }
    return privateGroups;
  }

  const submitHandler = async (e) => {
    e.preventDefault(); // Prevent the default form submission

    if (validateForm(formData)) {
      // If form is valid, proceed with API call
      settoggleSpinner(true);
      try {
        const payload = {
          title: formData.title,
          description: formData.description,
          is_expiry_date: false,
          points: formData.points,
          bonus_points: formData.bonus_points || 0,
          valid_from: new Date(formData.valid_from).toISOString(),
          employment_type: formData.employment_type,
          mode_id: formData.mode_id,
          is_published: formData.is_published,
          groups: (formData.groups || []).map((item) => item.groups.id),
          users: (formData.users || []).map((item) => item.users.id),
        };

        if (formData.valid_till) {
          payload.valid_till = new Date(formData.valid_till).toISOString();
        }

        const response = await RewardService.createReward(payload);

        if (payload.is_published) {
          SuccessNotifation("Reward published successfully");
        } else {
          SuccessNotifation("Reward drafted successfully");
        }
        setShowForm(false);
        resetForm();
        setCurrPage(1); // Reset to the first page to see the new group
      } catch (error) {
        if (error.response) {
          // The request was made and the server responded with a status code >= 400
          console.error("Error:", error.response.data);
          console.error("Status Code:", error.response.status);

          if (error.response.status === 422) {
            FailedNotification(error.response.data.detail[0]["msg"]);
          } else {
            FailedNotification(error.response.data.detail);
          }
        } else if (error.request) {
          // The request was made but no response was received
          console.error("No response received:", error.request);
          FailedNotification(error.request);
        } else {
          // Something else happened while setting up the request
          console.error("Error:", error.message);
        }
      } finally {
        settoggleSpinner(false); // Hide spinner after submission
      }
    } else {
      // If form is not valid, do not submit and show errors
      console.log("Form is not valid:", errors);
    }
  };

  useEffect(() => {}, [formData]);

  const editHandler = async (e) => {
    e.preventDefault(); // Prevent the default form submission

    if (validateForm(formData)) {
      // If form is valid, proceed with API call
      settoggleSpinner(true);
      try {
        const payload = {
          title: formData.title,
          description: formData.description,
          is_expiry_date: false,
          points: formData.points,
          bonus_points: formData.bonus_points || 0,
          valid_from: new Date(formData.valid_from).toISOString(),
          employment_type: formData.employment_type,
          mode_id: formData.mode_id,
          is_published: formData.is_published || false,
          groups: (formData.groups || []).map((item) => item.groups.id),
          users: (formData.users || []).map((item) => item.users.id),
        };

        if (formData.valid_till) {
          payload.valid_till = new Date(formData.valid_till).toISOString();
        }

        const response = await RewardService.updateReward(
          ActiveItem.id,
          payload
        );

        if (payload.is_published) {
          SuccessNotifation("Reward updated and published succeffully");
        } else {
          SuccessNotifation("Reward updated and drafted successfully!");
        }
        setShowForm(false);
        resetForm();
        setCurrPage(1); // Reset to the first page to see the new group
      } catch (error) {
        if (error.response) {
          // The request was made and the server responded with a status code >= 400
          console.error("Error:", error.response.data);
          console.error("Status Code:", error.response.status);

          if (error.response.status === 422) {
            FailedNotification(error.response.data.detail[0]["msg"]);
          } else {
            FailedNotification(error.response.data.detail);
          }
        } else if (error.request) {
          // The request was made but no response was received
          console.error("No response received:", error.request);
          FailedNotification(error.request);
        } else {
          // Something else happened while setting up the request
          console.error("Error:", error.message);
        }
      } finally {
        settoggleSpinner(false); // Hide spinner after submission
        toggleEditRewardForm();
      }
    } else {
      // If form is not valid, do not submit and show errors
      console.log("Form is not valid:", errors);
    }
  };

  return (
    <div className="page-wrapper" style={{ backgroundColor: "white" }}>
      <div className="content">
        <div className="page-header">
          <div className="row">
            <div className="col">
              <h3 className="page-title">Rewards </h3>

              <ul className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="/home">
                    <span>
                      <i className="la la-home"></i> Home
                    </span>
                  </Link>
                </li>

                <li className="breadcrumb-item active">
                  <i className="las la-trophy"></i> Reward
                </li>
              </ul>
            </div>

            <div
              className="col-auto float-right ml-auto"
              style={{ marginRight: "3vh" }}
            >
              {showForm ? (
                <div onClick={toggleCreateRewardForm} className="btn add-btn">
                  {"Cancel"}
                </div>
              ) : (
                <>
                  {showEditModal ? (
                    <div onClick={toggleEditRewardForm} className="btn add-btn">
                      {"Cancel"}
                    </div>
                  ) : (
                    <div
                      onClick={toggleCreateRewardForm}
                      className="btn add-btn"
                    >
                      {"Create"}
                    </div>
                  )}
                </>
              )}

              {!showForm && !showEditModal && (
                <div style={{ display: "none" }} className="btn add-btn"></div>
              )}
            </div>
          </div>
        </div>

        {/* {!showForm && Loader && userReward.length === 0 && (
          <div
            className="row align-items-center justify-content-center"
            style={{
              flexDirection: "column",
              width: "100%",
              height: "60vh",
            }}
          >
            <div
              className="row align-items-center justify-content-center"
              style={{ width: "100%" }}
            >
              <div
                className="col-md-6 text-center"
                style={{
                  textTransform: "uppercase",
                }}
              >
                <span style={{ color: "#ff9b44" }}>Create Reward</span>
              </div>
            </div>

            <div
              className="row align-items-center justify-content-center"
              style={{ width: "100%" }}
            >
              <div
                className="col-md-6 text-center"
                style={{ textTransform: "uppercase" }}
              >
                <img
                  src={require("../assets/img/undraw_No_data_re_kwbl.png")}
                  alt="React Logo"
                  height={100}
                />
              </div>
            </div>
          </div>
        )} */}

        {/* Create View */}
        {showForm && (
          <div className="row">
            <div className="col-md-12">
              <div className="card mb-0">
                <div className="card-body">
                  <form onSubmit={submitHandler}>
                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-group form-border">
                          <label>
                            Title:<span style={{ color: "red" }}> * </span>
                            <span style={{ color: "red" }}>
                              {errors.title && errors.title}
                            </span>
                          </label>

                          <input
                            type="text"
                            className="form-control"
                            placeholder="Enter Title"
                            // required
                            name="title"
                            value={formData.title}
                            onChange={changeHandler}
                          />
                        </div>
                      </div>

                      <div className="col-md-6">
                        <div className="form-group form-border">
                          <label>
                            Reward Mode:
                            <span style={{ color: "red" }}> * </span>
                            <span style={{ color: "red" }}>
                              {errors.mode_id && errors.mode_id}
                            </span>{" "}
                          </label>
                          <select
                            className="form-control"
                            name="mode_id"
                            value={formData.mode_id}
                            onChange={changeHandler}
                          >
                            <option value="">Select Mode</option>
                            {Array.isArray(rewardMode) &&
                              rewardMode.map((mode) => (
                                <option key={mode.id} value={mode.id}>
                                  {mode.name}
                                </option>
                              ))}
                          </select>
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-group form-border">
                          <label>
                            Start Date:{" "}
                            <span style={{ color: "red" }}> * </span>
                            <span style={{ color: "red" }}>
                              {errors.valid_from && errors.valid_from}
                            </span>
                          </label>

                          <input
                            type="date"
                            className="form-control"
                            // max={new Date().toISOString().split("T")[0]}
                            // min={new Date().toISOString().split("T")[0]}
                            // required
                            name="valid_from"
                            onChange={changeHandler}
                            value={formData.valid_from}
                          />
                        </div>
                      </div>

                      <div className="col-md-6">
                        <div className="form-group form-border">
                          <label>End Date: </label>

                          <input
                            type="date"
                            className="form-control"
                            min={new Date().toISOString().split("T")[0]}
                            // required
                            name="valid_till"
                            onChange={changeHandler}
                            value={formData.valid_till}
                          />
                        </div>
                      </div>
                    </div>

                    <div className="row mt-30 mb-30">
                      <div className="col-md-4 d-flex justify-content-center align-items-center">
                        <b>Has participation points?</b>
                      </div>

                      <div className="col-md-4 d-flex justify-content-center align-items-center">
                        <label className="form-check-label ml-2 mr-100">
                          <input
                            type="radio"
                            className="form-check-input"
                            name="hasParticipationPoints"
                            value={true}
                            checked={hasParticipationPoints}
                            onChange={() => setHasParticipationPoints(true)}
                          />
                          Yes
                        </label>
                      </div>

                      <div className="col-md-4 d-flex justify-content-center align-items-center">
                        <label className="form-check-label ml-2">
                          <input
                            type="radio"
                            className="form-check-input"
                            name="hasParticipationPoints"
                            value={false}
                            checked={!hasParticipationPoints}
                            onChange={() => setHasParticipationPoints(false)}
                          />
                          No
                        </label>
                      </div>
                    </div>

                    <div className="row">
                      <div
                        className={
                          hasParticipationPoints ? "col-md-6" : "col-md-12"
                        }
                      >
                        <div className="form-group form-border">
                          <label>
                            Points: <span style={{ color: "red" }}> * </span>
                            <span style={{ color: "red" }}>
                              {errors.points && errors.points}
                            </span>
                          </label>

                          <input
                            type="number"
                            className="form-control"
                            name="points"
                            placeholder="Enter Points"
                            value={formData.points}
                            onChange={changeHandler}
                            min={"0"}
                          />
                        </div>
                      </div>

                      {hasParticipationPoints && (
                        <div className="col-md-6">
                          <div className="form-group form-border">
                            <label>Participation Points: </label>

                            <input
                              type="number"
                              className="form-control"
                              name="bonus_points"
                              placeholder="Enter Points"
                              value={formData.bonus_points}
                              onChange={changeHandler}
                              min={"0"}
                            />
                          </div>
                        </div>
                      )}
                    </div>

                    {/* <div className="form-group form-border">
                      <label>
                        Reward Description:{" "}
                        <span style={{ color: "red" }}> * </span>
                        <span style={{ color: "red" }}>
                          {errors.description && errors.description}
                        </span>
                      </label>

                      <textarea
                        rows="6"
                        cols="5"
                        className="form-control"
                        placeholder="Enter Description"
                        value={formData.description}
                        // required
                        name="description"
                        onChange={changeHandler}
                      ></textarea>
                    </div> */}

                    {/* select users chips */}
                    <ItemRewardUserChip
                      label={"Users"}
                      is_required={false}
                      items={formData?.users || []}
                      setItems={(newItems) =>
                        setFormData((prevData) => ({
                          ...prevData,
                          users: newItems,
                        }))
                      }
                    />

                    {/* select groups chips */}
                    <ItemRewardGroupChip
                      label={"Groups"}
                      is_required={false}
                      items={formData?.groups || []}
                      setItems={(newItems) =>
                        setFormData((prevData) => ({
                          ...prevData,
                          groups: newItems,
                        }))
                      }
                    />

                    {/* 
                    <div className="form-group form-border">
                      <label>
                        Rewards applicable for:
                        <span style={{ color: "red" }}> * </span>
                        <span style={{ color: "red" }}>
                          {errors.Rewards_applicable_for &&
                            errors.Rewards_applicable_for}
                        </span>
                      </label>

                      <div className="form-control" style={{ height: "150px" }}>
                        <div
                          className="scrollable-div"
                          style={{
                            width: "100%",
                            height: "135px",
                            // overflow: "auto",
                          }}
                        >
                          <div className="checkbox">
                            <label>
                              <input
                                type="checkbox"
                                checked={selectAll}
                                onChange={toggleSelectAll}
                                style={{
                                  border: "1px solid #ccc",
                                  backgroundColor: "#fff",
                                }}
                              />{" "}
                              Select All
                            </label>
                          </div>
                          {Array.isArray(groupDropdown.items) &&
                            groupDropdown.items.map((each, index) => (
                              <div key={index} className="checkbox">
                                <label>
                                  <input
                                    type="checkbox"
                                    name={each.id}
                                    checked={formData.Rewards_applicable_for.includes(
                                      String(each.id)
                                    )}
                                    onChange={changeHandler}
                                    value={formData.Rewards_applicable_for}
                                  />{" "}
                                  {each.name && each.name.toUpperCase()}
                                </label>
                              </div>
                            ))}
                        </div>
                      </div>
                    </div>
                     */}

                    <div className="row">
                      <div className="col-md-12">
                        <div
                          className="form-border"
                          style={{ minHeight: "280px" }}
                        >
                          <label
                            style={{
                              bottom: -1,
                              position: "relative",
                              zIndex: 2,
                            }}
                          >
                            Editor: <span style={{ color: "red" }}> * </span>
                            <span style={{ color: "red" }}>
                              {errors.description && errors.description}
                            </span>
                          </label>
                          <MyEditor
                            value={formData.description}
                            setValue={(newItems) =>
                              setFormData((prevData) => ({
                                ...prevData,
                                description: newItems, // Set the value of sel_reward_mode directly from the selected value
                              }))
                            }
                          />
                        </div>
                      </div>
                    </div>

                    <div className="row mt-10">
                      <div className="col-md-12">
                        <div className="text-right">
                          {toggleSpinner ? (
                            <button
                              // onClick={handleClick}
                              className="btn add-btn"
                              disabled
                            >
                              <Beatloader />
                            </button>
                          ) : (
                            <button
                              type="submit"
                              name="publish"
                              className="btn add-btn"
                              onClick={(e) => {
                                // e.preventDefault(); // Prevent the default form submission

                                const isPublished = e.target.name === "publish"; // Identify button clicked based on name
                                const updatedData = {
                                  ...formData, // Spread existing data to preserve other fields
                                  is_published: isPublished, // Update is_published based on button clicked
                                };

                                setFormData(updatedData); // Update
                              }}
                            >
                              PUBLISH
                            </button>
                          )}
                        </div>

                        <div className="text-right">
                          {toggleSpinner ? (
                            <button
                              // onClick={handleClick}
                              className="btn add-btn"
                              disabled
                            >
                              <Beatloader />
                            </button>
                          ) : (
                            <button
                              type="submit"
                              name="draft"
                              className="btn cancel-btn"
                              onClick={(e) => {
                                const isPublished = e.target.name === "publish"; // Identify button clicked based on name
                                const updatedData = {
                                  ...formData, // Spread existing data to preserve other fields
                                  is_published: isPublished, // Update is_published based on button clicked
                                };
                                setFormData(updatedData); // Update
                              }}
                            >
                              DRAFT
                            </button>
                          )}
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        )}

        {/* Edit View */}
        {showEditModal && (
          <div className="row">
            <div className="col-md-12">
              <div className="card mb-0">
                <div className="card-body">
                  <form onSubmit={editHandler}>
                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-group form-border">
                          <label>
                            Title:<span style={{ color: "red" }}> * </span>
                            <span style={{ color: "red" }}>
                              {errors.title && errors.title}
                            </span>
                          </label>

                          <input
                            type="text"
                            className="form-control"
                            placeholder="Enter Title"
                            disabled={formData.is_published ? true : false}
                            name="title"
                            value={formData.title}
                            onChange={changeHandler}
                          />
                        </div>
                      </div>

                      <div className="col-md-6">
                        <div className="form-group form-border">
                          <label>
                            Reward Mode:
                            <span style={{ color: "red" }}> * </span>
                            <span style={{ color: "red" }}>
                              {errors.mode_id && errors.mode_id}
                            </span>{" "}
                          </label>
                          <select
                            className="form-control"
                            name="mode_id"
                            disabled={formData.is_published ? true : false}
                            value={formData.mode_id}
                            onChange={changeHandler}
                          >
                            <option value="">Select Mode</option>
                            {rewardMode.map((mode) => (
                              <option key={mode.id} value={mode.id}>
                                {mode.name}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-group form-border">
                          <label>
                            Start Date:{" "}
                            <span style={{ color: "red" }}> * </span>
                            <span style={{ color: "red" }}>
                              {errors.valid_from && errors.valid_from}
                            </span>
                          </label>

                          <input
                            type="date"
                            className="form-control"
                            // max={new Date().toISOString().split("T")[0]}
                            // min={new Date().toISOString().split("T")[0]}
                            // required
                            disabled={formData.is_published ? true : false}
                            name="valid_from"
                            onChange={changeHandler}
                            value={formData.valid_from}
                          />
                        </div>
                      </div>

                      <div className="col-md-6">
                        <div className="form-group form-border">
                          <label>End Date: </label>

                          <input
                            type="date"
                            className="form-control"
                            min={new Date().toISOString().split("T")[0]}
                            disabled={formData.is_published ? true : false}
                            name="valid_till"
                            onChange={changeHandler}
                            value={formData.valid_till}
                          />
                        </div>
                      </div>
                    </div>

                    <div className="row mt-30 mb-30">
                      <div className="col-md-4 d-flex justify-content-center align-items-center">
                        <b>Has participation points?</b>
                      </div>

                      <div className="col-md-4 d-flex justify-content-center align-items-center">
                        <label className="form-check-label ml-2 mr-100">
                          <input
                            type="radio"
                            className="form-check-input"
                            name="hasParticipationPoints"
                            value={true}
                            disabled={formData.is_published ? true : false}
                            checked={hasParticipationPoints}
                            onChange={() => setHasParticipationPoints(true)}
                          />
                          Yes
                        </label>
                      </div>

                      <div className="col-md-4 d-flex justify-content-center align-items-center">
                        <label className="form-check-label ml-2">
                          <input
                            type="radio"
                            className="form-check-input"
                            name="hasParticipationPoints"
                            value={false}
                            disabled={formData.is_published ? true : false}
                            checked={!hasParticipationPoints}
                            onChange={() => setHasParticipationPoints(false)}
                          />
                          No
                        </label>
                      </div>
                    </div>

                    <div className="row">
                      <div
                        className={
                          hasParticipationPoints ? "col-md-6" : "col-md-12"
                        }
                      >
                        <div className="form-group form-border">
                          <label>
                            Points: <span style={{ color: "red" }}> * </span>
                            <span style={{ color: "red" }}>
                              {errors.points && errors.points}
                            </span>
                          </label>

                          <input
                            type="number"
                            className="form-control"
                            name="points"
                            placeholder="Enter Points"
                            value={formData.points}
                            min={"0"}
                            disabled={formData.is_published ? true : false}
                            onChange={changeHandler}
                          />
                        </div>
                      </div>

                      {hasParticipationPoints && (
                        <div className="col-md-6">
                          <div className="form-group form-border">
                            <label>Participation Points: </label>

                            <input
                              type="number"
                              className="form-control"
                              name="bonus_points"
                              placeholder="Enter Points"
                              value={formData.bonus_points}
                              disabled={formData.is_published ? true : false}
                              onChange={changeHandler}
                              min={"0"}
                            />
                          </div>
                        </div>
                      )}
                    </div>

                    {/* <div className="form-group form-border">
                      <label>
                        Reward Description:{" "}
                        <span style={{ color: "red" }}> * </span>
                        <span style={{ color: "red" }}>
                          {errors.description && errors.description}
                        </span>
                      </label>

                      <textarea
                        rows="6"
                        cols="5"
                        className="form-control"
                        placeholder="Enter Description"
                        value={formData.description}
                        // required
                        name="description"
                        onChange={changeHandler}
                      ></textarea>
                    </div> */}

                    {/* select users chips */}
                    <ItemRewardUserChip
                      label={"Users"}
                      is_required={false}
                      items={formData?.users || []}
                      setItems={(newItems) =>
                        setFormData((prevData) => ({
                          ...prevData,
                          users: newItems,
                        }))
                      }
                    />

                    {/* select groups chips */}
                    <ItemRewardGroupChip
                      label={"Groups"}
                      is_required={false}
                      items={formData?.groups || []}
                      setItems={(newItems) =>
                        setFormData((prevData) => ({
                          ...prevData,
                          groups: newItems,
                        }))
                      }
                    />

                    {/* 
                    <div className="form-group form-border">
                      <label>
                        Rewards applicable for:
                        <span style={{ color: "red" }}> * </span>
                        <span style={{ color: "red" }}>
                          {errors.Rewards_applicable_for &&
                            errors.Rewards_applicable_for}
                        </span>
                      </label>

                      <div className="form-control" style={{ height: "150px" }}>
                        <div
                          className="scrollable-div"
                          style={{
                            width: "100%",
                            height: "135px",
                            // overflow: "auto",
                          }}
                        >
                          <div className="checkbox">
                            <label>
                              <input
                                type="checkbox"
                                checked={selectAll}
                                onChange={toggleSelectAll}
                                style={{
                                  border: "1px solid #ccc",
                                  backgroundColor: "#fff",
                                }}
                              />{" "}
                              Select All
                            </label>
                          </div>
                          {Array.isArray(groupDropdown.items) &&
                            groupDropdown.items.map((each, index) => (
                              <div key={index} className="checkbox">
                                <label>
                                  <input
                                    type="checkbox"
                                    name={each.id}
                                    checked={formData.Rewards_applicable_for.includes(
                                      String(each.id)
                                    )}
                                    onChange={changeHandler}
                                    value={formData.Rewards_applicable_for}
                                  />{" "}
                                  {each.name && each.name.toUpperCase()}
                                </label>
                              </div>
                            ))}
                        </div>
                      </div>
                    </div>
                     */}

                    <div className="row">
                      <div className="col-md-12">
                        <div
                          className="form-border"
                          style={{ minHeight: "280px" }}
                        >
                          <label
                            style={{
                              bottom: -1,
                              position: "relative",
                              zIndex: 2,
                            }}
                          >
                            Editor:
                            <span style={{ color: "red" }}> * </span>
                            <span style={{ color: "red" }}>
                              {errors.description && errors.description}
                            </span>
                          </label>
                          <MyEditor
                            disabled={formData.is_published ? true : false}
                            value={formData.description}
                            setValue={(newItems) =>
                              setFormData((prevData) => ({
                                ...prevData,
                                description: newItems, // Set the value of sel_reward_mode directly from the selected value
                              }))
                            }
                          />
                        </div>
                      </div>
                    </div>

                    <div className="row mt-10">
                      <div className="col-md-12">
                        <div className="text-right">
                          {toggleSpinner ? (
                            <button
                              // onClick={handleClick}
                              className="btn add-btn"
                              disabled
                            >
                              <Beatloader />
                            </button>
                          ) : (
                            <button
                              type="submit"
                              name="publish"
                              className="btn add-btn"
                              onClick={(e) => {
                                const isPublished = e.target.name === "publish"; // Identify button clicked based on name
                                const updatedData = {
                                  ...formData, // Spread existing data to preserve other fields
                                  is_published: isPublished, // Update is_published based on button clicked
                                };

                                setFormData(updatedData); // Update
                              }}
                            >
                              {formData.is_published ? "UPDATE" : "PUBLISH"}
                            </button>
                          )}
                        </div>

                        {!formData.is_published && (
                          <div className="text-right">
                            {toggleSpinner ? (
                              <button
                                // onClick={handleClick}
                                className="btn add-btn"
                                disabled
                              >
                                <Beatloader />
                              </button>
                            ) : (
                              <button
                                type="submit"
                                name="draft"
                                className="btn cancel-btn"
                                onClick={(e) => {
                                  const isPublished =
                                    e.target.name === "publish"; // Identify button clicked based on name
                                  const updatedData = {
                                    ...formData, // Spread existing data to preserve other fields
                                    is_published: isPublished, // Update is_published based on button clicked
                                  };

                                  setFormData(updatedData); // Update
                                }}
                              >
                                DRAFT
                              </button>
                            )}
                          </div>
                        )}
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        )}

        {/* List View */}
        {!showForm && !showEditModal && (
          <div className="row mt-3">
            <ThemeProvider theme={defaultMaterialTheme}>
              <div style={{ width: "100%" }}>
                <MaterialTable
                  title="Group Preview"
                  tableRef={tableRef}
                  columns={columns}
                  data={(query) =>
                    new Promise((resolve, reject) => {
                      let url = process.env.REACT_APP_BACKEND_URL + "/rewards";
                      url += "?page=" + (query.page + 1);
                      url += "&size=" + rowsPerPage;

                      // Add search query if available
                      if (query.search && query.search.length > 0) {
                        console.log("search: ", query.search);
                        url += "&search=" + query.search;
                      }

                      url += "&published=all";
                      fetch(url, {
                        headers: {
                          Authorization: `Bearer ${getCustomToken()}`,
                        },
                      })
                        .then((response) => response.json())
                        .then((result) => {
                          console.info("RESULT:", result);
                          setIsLoading(false); // Hide loading indicator after fetching

                          resolve({
                            data: result.items,
                            page: result.page - 1,
                            totalCount: result.total,
                          });
                        })
                        .catch((error) => {
                          console.error("Error fetching groups:", error);
                        })
                        .finally(() => {
                          setIsLoading(false);
                        });
                    })
                  }
                  icons={tableIcons}
                  options={{
                    headerStyle: { backgroundColor: "#ffedde" },
                    showTitle: false,
                    paging: true,
                    search: true,
                    sorting: false,
                    actionsColumnIndex: -1,
                    exportButton: false,
                    paginationType: "normal",
                    pageSize: rowsPerPage,
                    emptyRowsWhenPaging: false,

                    actionsCellStyle: {
                      justifyContent: "center",
                    },
                  }}
                  localization={{
                    header: {
                      actions: "",
                    },
                  }}
                  onSearchChange={handleChange}
                  searchText={searchTerm}
                  components={{
                    Pagination: (props) => (
                      <TablePagination
                        {...props}
                        rowsPerPageOptions={[5, 10, 15]}
                        rowsPerPage={rowsPerPage}
                        onRowsPerPageChange={handleChnageRowsPerPage}
                      />
                    ),
                  }}
                  actions={[
                    {
                      icon: "edit",
                      tooltip: "edit group",
                      onClick: (event, rowData) => {
                        handleShowEditModal(rowData);

                        console.log("Clicked");
                      },
                    },

                    {
                      icon: "refresh",
                      tooltip: "Refresh Data",
                      isFreeAction: true,
                      onClick: () =>
                        tableRef.current && tableRef.current.onQueryChange(),
                    },
                  ]}
                  // components={{
                  //   Pagination: (subProps) => {
                  //     return (
                  //       <Box
                  //         display="flex"
                  //         justifyContent="flex-end"
                  //         alignItems="center"
                  //       >
                  //         <div style={{ paddingRight: 10 }}>
                  //           Rows per page:{"  "}
                  //         </div>
                  //         <select
                  //           value={rowsPerPage}
                  //           disabled
                  //           onChange={handleRowsPerPageChange}
                  //         >
                  //           {[5, 10, 15, 30].map((pageSizeOption) => (
                  //             <option
                  //               key={pageSizeOption}
                  //               value={pageSizeOption}
                  //             >
                  //               {pageSizeOption}
                  //             </option>
                  //           ))}
                  //         </select>
                  //         <Box>
                  //           <MTablePagination {...subProps} />
                  //         </Box>
                  //       </Box>
                  //     );
                  //   },
                  // }}
                />
              </div>
            </ThemeProvider>
          </div>
        )}
      </div>
    </div>
  );
}

export default Reward;
