/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";

function ItemSelectRewarduser(props) {
  const [showSuggestions, setShowSuggestions] = useState(false); // State to control suggestion visibility
  const [error, setError] = useState(null);
  const [value, setValue] = useState("");

  useEffect(() => {
    const timeOut = setTimeout(() => {
      const filteredUsers = props.RUsersData.filter((user) =>
        user.display_name.toLowerCase().includes(value.toLowerCase())
      );
      console.log(filteredUsers);
    }, 1500);

    return () => {
      clearTimeout(timeOut);
    };
  }, [value, props.RUsersData]);

  useEffect(() => {
    if (!props.activeUser.email_id) {
      setValue("");
    } else {
      setValue(props.activeUser.email_id);
    }
  }, [props.activeUser]);

  const handleChangeTitle = async (evt) => {
    setValue(evt.target.value);
    console.log("SEARCH: ", evt.target.value);
    setError(null);
    setShowSuggestions(true);
  };

  return (
    <div>
      <input
        className={"form-control"}
        value={value}
        placeholder="Search user by name.."
        onChange={handleChangeTitle}
        onFocus={() => {
          props.setActiveUser({});
          setShowSuggestions(true);
        }}
      />
      {showSuggestions && (
        <div className="suggestions">
          {props.RUsersData.map((user) => (
            <div
              className="suggestion"
              key={user.id}
              onClick={() => {
                props.setActiveUser(user);
                setShowSuggestions(false);
              }}
            >
              {user.display_name}
            </div>
          ))}
        </div>
      )}
    </div>
  );
}

export default ItemSelectRewarduser;
