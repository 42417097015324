/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { getCustomToken } from "../helpers/utils";

function ItemSelectReward(props) {
  const [showSuggestions, setShowSuggestions] = useState(false); // State to control suggestion visibility
  const [error, setError] = useState(null);
  const [value, setValue] = useState("");

  const fetchSuggestions = async (search) => {
    try {
      let apiUrl = `${process.env.REACT_APP_BACKEND_URL}/rewards?page=1&size=10&published=true`;

      // Check if 'search' is not empty
      if (search !== "") {
        apiUrl += `&search=${search}`;
      }

      const response = await fetch(apiUrl, {
        headers: {
          Authorization: `Bearer ${getCustomToken()}`,
        },
      }); // Replace with your actual API endpoint
      const data = await response.json();
      props.setRewardData(data.items || []); // Assuming the API response has a "suggestions" property
    } catch (error) {
      console.error("Error fetching suggestions:", error);
    }
  };

  useEffect(() => {
    const timeOut = setTimeout(() => {
      console.log(`Calling API with search: ${value}`);
      fetchSuggestions(value); // Fetch initial suggestions on component mount
    }, 1500);

    return () => {
      clearTimeout(timeOut);
    };
  }, [value]);

  useEffect(() => {
    if (!props.activeReward.title) {
      setValue("");
    } else {
      setValue(props.activeReward.title);
    }
  }, [props.activeReward]);

  const handleChangeTitle = async (evt) => {
    setValue(evt.target.value);

    console.log("SEARCH: ", evt.target.value);
    setError(null);

    setShowSuggestions(true);

    await fetchSuggestions(evt.target.value);
  };

  return (
    <div>
      <input
        className={"form-control"}
        value={value}
        placeholder="Search reward by title.."
        onChange={handleChangeTitle}
        onFocus={() => {
          setShowSuggestions(true);
        }}
      />
      {showSuggestions && (
        <div className="suggestions">
          {props.rewardData.map((reward) => (
            <div
              className="suggestion"
              key={reward.id}
              onClick={() => {
                props.setActiveReward(reward);
                if (reward.bonus_points === 0) {
                  props.setHasParticipationPoints(true);
                }
                setShowSuggestions(false);
              }}
            >
              {reward.title}
            </div>
          ))}
        </div>
      )}
    </div>
  );
}

export default ItemSelectReward;
