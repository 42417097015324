import axios from "axios";
import { getCustomToken } from "../helpers/utils";

const http = axios.create({
  baseURL: process.env.REACT_APP_BACKEND_URL,

  headers: {
    "content-type": "application/json",
  },
});

class AuthService {
  getCutomToen(access_token) {
    return http.post(`/auth/access_token?access_token=${access_token}`);
  }

  getDecodeData() {
    return http.post(
      `/auth/decode_token`,
      {},
      {
        headers: {
          Authorization: `Bearer ${getCustomToken()}`,
          accept: "application/json",
        },
      }
    );
  }
}

export default new AuthService();
