/*eslint-disable */
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import ReactCanvasConfetti from "react-canvas-confetti";
import { useCallback } from "react";
import { useRef } from "react";
import { Modal } from "antd";

import "./redeem.css";
import { Beatloader } from "../components/Spinner";

import AnimatedNumbers from "react-animated-numbers";
import RedeemService from "../services/Redeem.service";
import { FailedNotification } from "../components/Notifications";

const canvasStyles = {
  position: "fixed",
  pointerEvents: "none",
  width: "100%",
  height: "100%",
  top: 0,
  left: 0,
  zIndex: 9999,
};

function formatNumberWithCommas(number) {
  return number.toLocaleString("en-IN");
}

export default function Redeem() {
  const refAnimationInstance = useRef(null);

  const GC = 500;

  const [isLoading, setIsLoading] = useState(false);

  const [MyPoints, setMyPoints] = useState({
    totalReward: 0,
    totalRemaining: 0,
  });

  const [convRate, setConvRate] = useState(1);

  const convertPointsToRupees = (points) => {
    const rupeesValue = points / convRate; // 1000 points equal 1 rupee
    return rupeesValue;
  };

  const convertRupeesToPoints = (points) => {
    const pointValue = points * convRate; // 1000 points equal 1 rupee
    return pointValue;
  };

  const [redeemLoading, setRedeemLoading] = useState(false);

  const fetchData = async () => {
    setIsLoading(true);
    RedeemService.getRate()
      .then((response) => {
        console.log("json3:", response.data);
        setConvRate(response.data.in_points);
      })
      .catch((error) => {
        console.error(error.message);
      });

    RedeemService.getPoints()
      .then((response) => {
        console.log("json3:", response.data);
        setMyPoints(response.data);
      })
      .catch((error) => {
        console.error(error.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };
  useEffect(() => {
    if (redeemLoading === false) {
      fetchData();
    }
  }, [redeemLoading]);

  const updatedMyPoints = {
    ...MyPoints,
    totalRemainingWorth: convertPointsToRupees(MyPoints.totalRemaining),
  };

  const getInstance = useCallback((instance) => {
    refAnimationInstance.current = instance;
  }, []);

  const makeShot = useCallback((particleRatio, opts) => {
    refAnimationInstance.current &&
      refAnimationInstance.current({
        ...opts,
        origin: { y: 0.7 },
        particleCount: Math.floor(200 * particleRatio),
      });
  }, []);

  const fire = useCallback(() => {
    makeShot(0.25, {
      spread: 26,
      startVelocity: 55,
    });

    makeShot(0.2, {
      spread: 200,
    });

    makeShot(0.35, {
      spread: 200,
      decay: 0.91,
      scalar: 0.8,
    });

    makeShot(0.1, {
      spread: 120,
      startVelocity: 25,
      decay: 0.92,
      scalar: 1.2,
    });

    makeShot(0.1, {
      spread: 120,
      startVelocity: 45,
    });
  }, [makeShot]);

  const [number, setNumber] = useState(1);
  /* eslint-disable */
  const [reedemAmount, setRedeemAmount] = useState(500);

  const handleInputChange = (e) => {
    const newValue = e.target.value;
    setNumber(newValue);
    setRedeemAmount(GC * newValue);
  };

  // useEffect(() => {}, [reedemAmount]);

  const { confirm } = Modal;

  function showConfirm() {
    confirm({
      title: "Confirmation",
      content: `Do you wish to proceed with redeeming gift card's with a deduction of ${(
        number *
        500 *
        convRate
      ).toLocaleString("en-IN")}points ?`,
      async onOk() {
        try {
          return RedeemService.availGC(number)
            .then((response) => {
              // Call your function here when the promise is resolved
              fire();
              console.log(response);
            })
            .catch((error) => {
              if (error.response) {
                // The request was made and the server responded with a status code >= 400
                console.error("Error:", error.response.data);
                console.error("Status Code:", error.response.status);
                FailedNotification(error.response.data.detail);
              } else if (error.request) {
                // The request was made but no response was received
                console.error("No response received:", error.request);
              } else {
                // Something else happened while setting up the request
                console.error("Error:", error.message);
              }
            })
            .finally(() => {
              setRedeemLoading(false);
            });

          // return new Promise((resolve, reject) => {
          //   setTimeout(Math.random() > 0.5 ? resolve : reject, 1000);
          // })
          //   .then(() => {
          //     //

          //     setRedeemLoading(false);

          //     // Call your function here when the promise is resolved
          //     fire();
          //   })
          //   .catch((error) => {
          //     // Handle the  rejection of the promise here if needed
          //     console.error("Promise rejected:", error);
          //   });
        } catch (e) {
          return console.log("Oops errors!");
        }
      },
      onCancel() {
        setRedeemLoading(false);
      },

      width: "50%", // Set the width to 100% of the parent container
      height: "100vh", // Set the height to 100% of the viewport height
      zIndex: 10000,
    });
  }

  const handleRedeem = () => {
    // Set redeemLoading to true when you start the redeem operation
    setRedeemLoading(true);

    showConfirm();

    // // Simulate an async operation (e.g., an API request)
    // setTimeout(() => {
    //   // After the operation is complete, set redeemLoading back to false
    //   setRedeemLoading(false);
    // }, 2000); // Replace this with the actual duration of your operation
  };

  // TODO: Call API to get points details

  // TODO: Call API to reedem points

  return (
    <div style={{ height: "100vh" }}>
      <div className="page-wrapper" style={{ backgroundColor: "white" }}>
        <div className="content">
          <div className="page-header">
            <div className="row align-items-center">
              <div className="col">
                <h3 className="page-title">Redeem Points</h3>
                <ul className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/home">
                      <span>
                        <i className="la la-home"></i> Home
                      </span>
                    </Link>
                  </li>

                  <li className="breadcrumb-item active">
                    <i className="las la-rupee-sign"></i> Redeem
                  </li>
                </ul>
              </div>
            </div>
          </div>

          {/* Cards */}

          <div className="row mb-5">
            <div className="col-md-4">
              <div
                className="card mb-0"
                style={{
                  borderRadius: 10,
                  borderColor: "#f58b29",
                  backgroundColor: "#fff",
                  opacity: 0.7,
                  boxShadow: "2px 2px 4px rgba(0, 0, 0, 0.6)", // Define the box shadow here
                }}
              >
                <div className="card-body">
                  <div className="row" style={{ fontSize: 20, color: "gray" }}>
                    <div className="col">
                      <span> Total Reward Points (as on date)</span>
                    </div>
                  </div>
                  <div className="row" style={{ fontSize: 30 }}>
                    <div className="col d-flex align-items-center justify-content-left">
                      <span>
                        <i
                          className="las la-coins"
                          style={{
                            color: "#f58b29",
                          }}
                        ></i>
                      </span>

                      <AnimatedNumbers
                        includeComma
                        animateToNumber={updatedMyPoints.totalReward}
                        locale="en-IN"
                        configs={[
                          { mass: 1, tension: 220, friction: 100 },
                          { mass: 1, tension: 180, friction: 130 },
                          { mass: 1, tension: 280, friction: 90 },
                          { mass: 1, tension: 180, friction: 135 },
                          { mass: 1, tension: 260, friction: 100 },
                          { mass: 1, tension: 210, friction: 180 },
                        ]}
                      ></AnimatedNumbers>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-4">
              <div
                className="card mb-0"
                style={{
                  borderRadius: 10,
                  borderColor: "#f58b29",
                  backgroundColor: "#fff",
                  opacity: 0.7,
                  boxShadow: "2px 2px 4px rgba(0, 0, 0, 0.6)", // Define the box shadow here
                }}
              >
                <div className="card-body">
                  <div className="row" style={{ fontSize: 20, color: "gray" }}>
                    <div className="col">
                      <span> Total Remaining Points</span>
                    </div>
                  </div>
                  <div className="row" style={{ fontSize: 30 }}>
                    <div className="col d-flex align-items-center justify-content-left">
                      <span>
                        <i
                          className="las la-coins"
                          style={{ color: "#f58b29" }}
                        ></i>
                      </span>
                      <AnimatedNumbers
                        includeComma
                        animateToNumber={updatedMyPoints.totalRemaining}
                        locale="en-IN"
                        configs={[
                          { mass: 1, tension: 220, friction: 100 },
                          { mass: 1, tension: 180, friction: 130 },
                          { mass: 1, tension: 280, friction: 90 },
                          { mass: 1, tension: 180, friction: 135 },
                          { mass: 1, tension: 260, friction: 100 },
                          { mass: 1, tension: 210, friction: 180 },
                        ]}
                      ></AnimatedNumbers>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-4">
              <div
                className="card mb-0"
                style={{
                  borderRadius: 10,
                  borderColor: "#f58b29",
                  backgroundColor: "#fff",
                  opacity: 0.7,
                  boxShadow: "2px 2px 4px rgba(0, 0, 0, 0.6)", // Define the box shadow here
                }}
              >
                <div className="card-body">
                  <div className="row" style={{ fontSize: 20, color: "gray" }}>
                    <div className="col">
                      <span> Points Worth</span>
                    </div>
                  </div>
                  <div className="row" style={{ fontSize: 30 }}>
                    <div className="col d-flex align-items-center justify-content-left">
                      <span>
                        <i
                          className="las la-rupee-sign"
                          style={{ color: "#069557" }}
                        ></i>
                      </span>
                      <AnimatedNumbers
                        includeComma
                        animateToNumber={updatedMyPoints.totalRemainingWorth}
                        locale="en-IN"
                        configs={[
                          { mass: 1, tension: 220, friction: 100 },
                          { mass: 1, tension: 180, friction: 130 },
                          { mass: 1, tension: 280, friction: 90 },
                          { mass: 1, tension: 180, friction: 135 },
                          { mass: 1, tension: 260, friction: 100 },
                          { mass: 1, tension: 210, friction: 180 },
                        ]}
                      ></AnimatedNumbers>
                    </div>
                  </div>

                  <ul
                    className="star-list position-absolute mb-1 ml-4"
                    style={{ bottom: 0, left: 0 }}
                  >
                    <li>1000 points = 1 ₹</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>

          {/* Field and reddeem */}

          <div className="row">
            <div className="col">
              <h3>Redeem your points</h3>
            </div>
          </div>

          {/* <div className="row">
            <div className="col">
              <div className="GC-card-body"></div>
            </div>
          </div> */}

          <div className="row">
            <div className="col-md-12">
              <div className="card mb-0">
                <div className="card-body">
                  <div
                    className="row"
                    style={{
                      alignItems: "center",
                      justifyContent: "flex-start",
                      flexDirection: "row",
                    }}
                  >
                    <div className="col-md-10">
                      <div
                        className="row"
                        style={{
                          justifyContent: "flex-start",
                          alignItems: "center",
                          marginLeft: 10,
                        }}
                      >
                        <div
                          className="GC-card-body"
                          style={{ backgroundColor: "#fff" }}
                        >
                          <div className="GC-line-2">
                            <span className="GC-num">₹500</span>
                          </div>
                        </div>

                        <span
                          style={{
                            marginRight: 15,
                            marginLeft: 15,
                            color: "#000",
                          }}
                        >
                          X
                        </span>

                        <div style={{ width: "8%" }}>
                          <input
                            style={{ borderColor: "#000" }}
                            type="number"
                            className="form-control"
                            min="1"
                            max={Math.floor(
                              updatedMyPoints.totalRemainingWorth / GC
                            )}
                            value={number}
                            onChange={handleInputChange}
                          />
                        </div>

                        <span style={{ marginRight: 10, marginLeft: 10 }}>
                          =
                        </span>

                        <div
                          className="card mb-0"
                          style={{
                            borderRadius: 10,
                            backgroundColor: "#fff",
                            opacity: 0.7,
                            borderColor: "#069557",
                          }}
                        >
                          <div className="col btn">
                            <span>
                              <i
                                className="las la-rupee-sign"
                                style={{ color: "#069557", fontSize: "1em" }}
                              ></i>
                            </span>
                            <span style={{ color: "#069557", fontSize: "1em" }}>
                              {formatNumberWithCommas(reedemAmount)}
                            </span>
                          </div>
                        </div>

                        <div
                          className="card mb-0"
                          style={{
                            marginLeft: 10,
                            borderRadius: 10,
                            backgroundColor: "#fff",
                            opacity: 0.7,
                            borderColor: "#ff9b44",
                          }}
                        >
                          <div className="col btn ">
                            <span>
                              <i
                                className="las la-coins"
                                style={{ color: "#ff9b44", fontSize: "1em" }}
                              ></i>
                            </span>
                            <span style={{ color: "#ff9b44", fontSize: "1em" }}>
                              {formatNumberWithCommas(
                                convertRupeesToPoints(reedemAmount)
                              )}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-md-2">
                      {redeemLoading ? (
                        <button
                          style={{ width: "100%", borderRadius: 50 }}
                          type="button"
                          className="btn btn-primary"
                          disabled
                        >
                          <Beatloader />
                        </button>
                      ) : (
                        <button
                          style={{ width: "100%", borderRadius: 50 }}
                          type="button"
                          onClick={handleRedeem}
                          className="btn btn-primary"
                          disabled={
                            updatedMyPoints.totalRemaining <
                            convertRupeesToPoints(GC)
                          }
                        >
                          Redeem Points
                        </button>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <Modal
        show={true}
        // onHide={handleClose}
        backdrop="static"
        keyboard={false}
        size="lg"
        centered
        style={{
          zIndex: 9999,
        }}
      >
        <div className="modal-content p-2">
          <div className="modal-heading">
            <h2 style={{ color: "#069557" }}>{"Confirmation"}</h2>
          </div>

          <div className="modalBody">
            <div>{`Do you wish to proceed with redeeming gift card's with a deduction of  `}</div>

            <span>
              <i className="las la-coins" style={{ color: "#f58b29" }}></i>
            </span>

            <div>{` ${(number * 500 * convRate).toLocaleString(
              "en-IN"
            )} points ?`}</div>
          </div>

          <div className="modal-footer text-center"></div>
        </div>
      </Modal> */}
      <ReactCanvasConfetti refConfetti={getInstance} style={canvasStyles} />
    </div>
  );
}
