import React from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

function MyEditor(props) {
  const backgroundColor = props.disabled ? "#ddd" : "white"; // Set background color

  return (
    <ReactQuill
      placeholder={"write description"}
      theme="snow"
      readOnly={props.disabled}
      value={props.value}
      onChange={props.setValue}
      style={{ height: "200px", backgroundColor: backgroundColor }}
    />
  );
}
export default MyEditor;
