import React, { createContext, useEffect, useState } from "react";

export const UserContext = createContext();

const UserContextProvider = (props) => {
  const [currentUser, setCurrentUser] = useState({});

  // const [currentUser, setCurrentUser] = useState({
  //   email: "venkatesh.pilla@evolutyz.com",
  //   role: "USER",
  //   id: "",
  //   group: "",
  // });

  useEffect(() => {
    const fetchCurrentUser = async () => {
      try {
        // Call the API to get the current user
        const token = localStorage.getItem("customToken");
        if (token) {
          const response = await fetch(
            `${process.env.REACT_APP_BACKEND_URL}/auth/decode_token`,
            {
              method: "POST",
              credentials: "include",
              headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
                accept: "application/json",
              },
            }
          );

          if (response.ok) {
            const user = await response.json();

            console.log("user: ", user);

            setCurrentUser(user.data);
          }
        }
      } catch (error) {
        console.error(error);
      }
    };

    console.log("---");
    fetchCurrentUser();
    console.log("--AA-");
  }, []);

  return (
    <UserContext.Provider value={{ currentUser, setCurrentUser }}>
      {props.children}
    </UserContext.Provider>
  );
};

export default UserContextProvider;
