import axios from "axios";

import { getCustomToken } from "../helpers/utils";

const http = axios.create({
  baseURL: process.env.REACT_APP_BACKEND_URL,
  headers: {
    "content-type": "application/json",
  },
});

class RewardService {
  uploadBulkReward(payload) {
    return http.post(`/rewards/upload-template`, payload, {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${getCustomToken()}`,
      },
    });
  }

  assignreward(payload) {
    return http.post(`/rewards/users`, payload, {
      headers: {
        Authorization: `Bearer ${getCustomToken()}`,
      },
    });
  }
  downloadTemplate() {
    return http.get(`/rewards/download-template`, {
      responseType: "blob",
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${getCustomToken()}`,
      },
    });
  }

  getRewards(page = 1, size = 10) {
    return http.get(`/rewards?page=${page}&size=${size}&published=true`, {
      headers: {
        Authorization: `Bearer ${getCustomToken()}`,
      },
    });
  }

  // createReward(data) {
  //   // Create an object of formData
  ////   const formData = new FormData();

  //   formData.append("title", data.title);
  //   formData.append("description", data.description);
  //   formData.append("is_expiry_date", "false");
  //   formData.append("points", data.points.toString());
  //   formData.append("valid_from", new Date(data.valid_from).toISOString());
  //   formData.append("valid_till", new Date(data.valid_till).toISOString());
  //   formData.append("employment_type", data.employment_type);
  //   formData.append("mode_id", data.mode_id);

  //   if (data.Rewards_applicable_for.length > 0) {
  //     formData.append("groups", JSON.stringify(data.Rewards_applicable_for));
  //   }
  //   return http.post(`/reward/create`, formData, {
  //     headers: {
  //       "Content-Type": "multipart/form-data",
  //       Authorization: `Bearer ${getCustomToken()}`,
  //     },
  //   });
  // }

  createReward(formData) {
    return http.post(`/rewards`, formData, {
      headers: {
        // "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${getCustomToken()}`,
      },
    });
  }

  updateReward(reward_id, rewardData) {
    return http.put(`/rewards/${reward_id}`, rewardData, {
      headers: {
        Authorization: `Bearer ${getCustomToken()}`,
      },
    });
  }
}

export default new RewardService();
