import { useMsal } from "@azure/msal-react";
import { Link } from "react-router-dom";

const SignOutButton = () => {
  const { instance } = useMsal();

  const clearCustomToken = () => {
    localStorage.removeItem("customToken");
  };

  const handleLogout = (instance) => {
    clearCustomToken();
    instance.logoutRedirect({
      postLogoutRedirectUri: "/",
    });
  };

  return (
    <Link className="w-100 text-left" onClick={() => handleLogout(instance)}>
      <i className="la la-sign-out"></i> <span>Sign Out</span>
    </Link>
  );
};

export default SignOutButton;
