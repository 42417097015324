import React, { useState } from "react";
import { Link } from "react-router-dom";

const NotFound = () => {
  const [toggleHover, setToggleHover] = useState(false);

  const handletoggleHover = () => {
    setToggleHover((prev) => !prev);
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
        overflow: "hidden",
      }}
    >
      <div className="row">
        <div className="col-md-12 align-self-center text-center">
          <div className="d-flex justify-content-center align-items-center">
            <h1 style={{ color: "#ff9b44" }}>404 - NOT FOUND</h1>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-md-12 align-self-center text-center">
          <div className="d-flex justify-content-center align-items-center">
            <h2 style={{ color: "#ff9b44" }}>Oops, page not found.</h2>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-md-12 align-self-center text-center">
          <div className="d-flex justify-content-center align-items-center">
            <p style={{ color: "#ff9b44" }}>
              A web server may return a 404 Not Found HTTP status code in
              response to a request from a client for a web page or resource To
              <br></br>
              indicate that the server can be reached and understood the
              request, but cannot find the requested resource. The status code
              <br></br>
              404 response indicates that the server could not find what was
              requested, possibly due to the resource being removed, renamed, or
              never existed.
            </p>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-md-12 align-self-center text-center">
          <div className="d-flex justify-content-center align-items-center">
            <div
              style={{
                margin: "30px",
                fontWeight: 700,
                border: "2px solid #ff9b44",
                textDecoration: "none",
                padding: "15px",
                textTransform: "uppercase",
                color: "#354f7eff",
                borderRadius: "26px",
                transition: "all 0.2s ease-in-out",
                display: "inline-block",
              }}
            >
              <Link
                to={"/home"}
                style={
                  toggleHover ? { color: "#ff9b44" } : { color: "#ff9b44" }
                }
                onMouseEnter={handletoggleHover}
                onMouseLeave={handletoggleHover}
              >
                Go to home
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NotFound;
