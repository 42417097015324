const checkError = (obj) => {
  for (const key in obj) {
    if (obj[key] !== "") {
      return false;
    }
  }
  return true;
};

const positiveNumberPattern = /^[+]?\d+$/;

const fieldValidate = (fieldName, value, rules) => {
  let errorMessage = "";
  switch (fieldName) {
    case "title":
      if (rules.required && !value) {
        errorMessage = " Title is required";
      } else if (value.length > 50) {
        errorMessage = " Title must be less than 50 characters";
      }
      break;

    case "titlereward":
      if (rules.required && !value) {
        errorMessage = " Title is required";
      }
      break;

    case "description":
      if (rules.required && !value) {
        errorMessage = " Description is required";
      } else if (value.length > 500) {
        errorMessage = " Description must be less than 500 characters.";
      }
      break;

    case "descriptionb":
      if (rules.required && !value) {
        errorMessage = " Description is required";
      } else if (value.length > 255) {
        errorMessage = " Description must be less than 255characters.";
      }
      break;

    case "image":
      if (rules.required && !value) {
        errorMessage = "Image is required";
      }
      break;

    case "group":
      if (rules.required && !value) {
        errorMessage = "Select one from the list";
      }
      break;
    case "mode":
      if (rules.required && !value) {
        errorMessage = "Select one from the list";
      }
      break;
    case "mode_id":
      if (rules.required && !value) {
        errorMessage = "Select one from the list";
      }
      break;

    case "employment":
      if (rules.required && !value) {
        errorMessage = "Select one from the list";
      }
      break;

    case "display_name":
      if (rules.required && !value) {
        errorMessage = " Name is required";
      } else if (value.length > 50) {
        errorMessage = " must be less than 50 characters";
      }
      break;

    case "group_id":
      if (rules.required && !value) {
        errorMessage = "Select one from the list";
      }
      break;

    case "employment_id":
      if (rules.required && !value) {
        errorMessage = "Select one from the list";
      }
      break;

    case "points":
      if (rules.required && !value) {
        errorMessage = "Award the points";
      } else if (!positiveNumberPattern.test(value)) {
        errorMessage = "Points must be a positive number";
      }
      break;

    case "bonus_points":
      if (rules.required && !value) {
        errorMessage = "Award the bonous points";
      } else if (!positiveNumberPattern.test(value)) {
        errorMessage = "Points must be a positive number";
      }
      break;
    case "valid_from":
      if (rules.required && !value) {
        errorMessage = "Select the date";
      }
      break;

    case "valid_till":
      if (rules.required && !value) {
        errorMessage = "Select the date";
      }
      break;

    case "Rewards_applicable_for":
      if (rules.required && !value) {
        errorMessage = "Select one from the checkbox";
      }
      break;

    case "groups":
      if (rules.required && !value) {
        errorMessage = "Select the checkboxes";
      }
      break;

    case "reward_id":
      if (rules.required && !value) {
        errorMessage = "Select the reward from the dropdown";
      }
      break;

    case "user_id":
      if (rules.required && !value) {
        errorMessage = "Select the user from the drop down";
      }
      break;

    case "recommended_email_id":
      if (rules.required && !value) {
        errorMessage = "Select the recommended user";
      }
      break;

    case "comment":
      if (rules.required && !value) {
        errorMessage = "add comments";
      }
      break;

    default:
      break;
  }
  return errorMessage;
};

export { checkError, fieldValidate };
