/*eslint-disable */
import { useState, useEffect, useRef } from "react";
import Select from "react-select";
import { Tab, Tabs } from "react-bootstrap";
import { Link } from "react-router-dom";
// import Theme from "../components/theme";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import RewardService from "../services/Reward.Service";
import UserService from "../services/User.service";
import { useContext } from "react";
import { UserContext } from "../hooks/UserContext";
import { useNavigate } from "react-router-dom/dist";
import {
  UploadingNotification,
  FailedNotification,
  SuccessNotifation,
} from "../components/Notifications";
import { checkError, fieldValidate } from "../helpers/validators";
import { Beatloader } from "../components/Spinner";
import { getCustomToken } from "../helpers/utils";
import AccessDenied from "../components/AccessDenied";
function Bulk(props) {
  const [toggleSpinner, settoggleSpinner] = useState(false);

  const [error, setError] = useState({
    is_uploaded: "",
  });

  const handleSubmit = async (e) => {
    settoggleSpinner(true);
    e.preventDefault();

    if (!file) {
      console.error("No file selected");

      setError({ ...error, is_uploaded: "No file selected" });
      settoggleSpinner(false);
      return;
    }

    // Show the toast notification immediately after starting the upload process
    toast.info("Processing file. Please wait...", {
      position: toast.POSITION.TOP_CENTER,
      autoClose: 3000, // Toast message duration in milliseconds
    });

    // Clear the input file field
    if (fileInput.current) {
      fileInput.current.value = "";
    }
    setFile(null);

    // Disable the spinner after showing the toast
    settoggleSpinner(false);

    // Run the actual upload process in the background
    const formData = new FormData();
    formData.append("file", file);

    // const endpoint = `${process.env.REACT_APP_BACKEND_URL}/rewards/upload-template`;
    // const response = await fetch(endpoint, {
    //   method: "POST",
    //   body: formData,
    //   credentials: "include",
    //   headers: {
    //     "Content-Type": "multipart/form-data",
    //     Authorization: `Bearer ${getCustomToken()}`,
    //   },
    // });

    await RewardService.uploadBulkReward(formData)
      .then((response) => {
        SuccessNotifation(response.data.message);
      })
      .catch((error) => {
        if (error.response) {
          console.error("Error:", error.response.data);
          console.error("Status Code:", error.response.status);
          FailedNotification(error.response.data.detail);
        } else if (error.request) {
          console.error("No response received:", error.request);
        } else {
          console.error("Error:", error.message);
        }
      });

    // console.log("File uploaded successfully:", await response.data);

    // if (response.ok) {
    //   console.log("file upload successfully");
    //   toast.success("File uploaded successfully", {
    //     position: toast.POSITION.TOP_CENTER,
    //     autoClose: 1000,
    //   });

    //   setTimeout(() => {
    //     toast.success("Rewards assigned successfully", {
    //       position: toast.POSITION.TOP_CENTER,
    //       autoClose: 3000,
    //     });
    //   }, 5000);
    // } else {
    //   console.log("Failed to upload file");
    //   toast.error("Failed to upload file. Please try again.", {
    //     position: toast.POSITION.TOP_CENTER,
    //     autoClose: 3000, // Toast message duration in milliseconds
    //   });
    // }
    // } catch (error) {
    //   console.error("Upload failed:", error);
    //   toast.error(error.message, {
    //     position: toast.POSITION.TOP_CENTER,
    //     autoClose: 3000, // Toast message duration in milliseconds
    //   });
    // }
  };

  const [isSubmitDisabled, setIsSubmitDisabled] = useState(true);
  const [file, setFile] = useState();

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    if (selectedFile) {
      setFile(selectedFile);
      // setIsSubmitDisabled(false);
    }
  };

  const handleDownloadTemplate = async () => {
    try {
      setIsLoading(true);
      const response = await RewardService.downloadTemplate();

      SuccessNotifation("Downloded Excel successfully");

      if (response.status !== 200) {
        throw new Error(`Error downloading file: ${response.statusText}`);
      }

      const blob = await response.data;

      const filename = response.headers
        .get("content-disposition")
        .split('"')[1];

      const blobUrl = window.URL.createObjectURL(blob); // Create temporary URL

      const link = document.createElement("a");
      link.href = blobUrl;
      link.download = filename || "download.xlsx"; // Use a default if filename not found
      link.click();

      // Revoke the temporary URL after download (optional for cleanup)
      window.URL.revokeObjectURL(blobUrl);

      // // Try to extract filename from response headers (if available)
    } catch (error) {
      console.error("Error downloading template:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const fileInput = useRef(null);
  const [uploadedFile, setUploadedFile] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [filename, setFilename] = useState(null);

  return (
    <div
      className="tab-pane"
      id="BulkUpload"
      role="tabpanel"
      aria-labelledby="bulkupload-tab"
    >
      <div className="faq-card">
        <form onSubmit={handleSubmit}>
          <div className="form-group row">
            <label className="col-lg-3 col-form-label">
              Upload Document <span style={{ color: "red" }}> * </span>
              <span style={{ color: "red" }}>{error && error.is_uploaded}</span>
            </label>
            <div className="col-lg-7">
              <input
                ref={fileInput}
                type="file"
                className="form-control"
                accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                // required
                name="is_uploaded"
                onChange={handleFileChange}
              />

              {/* <div className="col-lg-2">
              <div className="img-thumbnail float-right"></div>
            </div> */}
              <div className="col-lg-10">
                {isLoading ? (
                  "Downloading..."
                ) : (
                  <Link
                    href="#"
                    download="template.xlsx"
                    onClick={handleDownloadTemplate}
                  >
                    Download Template
                  </Link>
                )}
              </div>
              {/* <span className="form-text text-muted">
                Recommended File size is 10KB
              </span> */}
            </div>
          </div>

          <div className="submit-section">
            {toggleSpinner ? (
              <button
                type="submit"
                className="btn btn-primary submit-btn"
                disabled
              >
                Submit
                <Beatloader />
              </button>
            ) : (
              <button
                type="submit"
                // disabled={!file}
                // disabled={isSubmitDisabled}
                className="btn btn-primary submit-btn"
                // onClick={handleSubmit}
              >
                Submit
              </button>
            )}
            {error && <p>{error.message}</p>}
            {uploadedFile && <p>File uploaded successfully!</p>}
            <ToastContainer />
          </div>
        </form>
        <div id="disclaimer">
          <div className="card mt-3">
            <div className="card-header">
              <h4 className="" aria-expanded="true">
                Disclaimer :
              </h4>
            </div>

            <div id="collapseOne" className="card-collapse collapse show">
              <div className="card-body">
                <ol>
                  <li>
                    The reward name is to be selected from a drop-down menu and{" "}
                    <strong>
                      <u>must be the same</u>
                    </strong>{" "}
                    as mentioned in the Create Rewards tab.
                  </li>
                  <li>
                    There are four sections: Reward Name, User Email ID,
                    Recommender Email ID, and Comments. Except for the Comments,
                    all of them are{" "}
                    <strong>
                      <u>mandatory fields</u>
                    </strong>
                    .
                  </li>

                  <li>
                    Every time a new reward or user is created, it is advised to
                    download{" "}
                    <strong>
                      <u>the most recent excel sheet template</u>
                    </strong>{" "}
                    to obtain the most recent data, ensuring that the
                    information presented is always up-to-date, accurate, and
                    reflective of the latest changes in the rewards and user
                    details.
                  </li>
                </ol>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Bulk;
