import React from "react";

import Navbar from "../components/Navbar";

function MainLayout(props) {
  return (
    <>
      <Navbar />
      {props.element}
    </>
  );
}

export default MainLayout;
