/*eslint-disable */
import React, { useState, useEffect } from "react";
import { Table, Button, Dropdown, FormControl } from "react-bootstrap";
import ReportService from "../services/Report.Service";
import UserService from "../services/User.service";
import { Link } from "react-router-dom";
import reward from "../assets/img/icons/reward.png";
import Select from "react-select";

const RewardsHistoryPage = () => {
  const [userData, setUserData] = useState([]);
  const [selectedUserId, setSelectedUserId] = useState("");
  const [rewardsHistory, setRewardsHistory] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [hasSubmitted, setHasSubmitted] = useState(false);

  useEffect(() => {
    const fetchUsers = async () => {
      setIsLoading(true);
      try {
        const response = await UserService.getUsers(); // Page 1, Size 50
        const users = response.data.items.map((user) => ({
          value: user.User.id,
          label: `${user.User.display_name} `,
        }));
        setUserData(users);
      } catch (error) {
        console.error("Failed to fetch users:", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchUsers();
  }, []);

  const handleUserChange = (newValue, actionMeta) => {
    if (
      actionMeta.action === "select-option" ||
      actionMeta.action === "option"
    ) {
      setSelectedUserId(newValue.value);
    } else if (actionMeta.action === "clear") {
      setSelectedUserId("");
    }
  };

  const fetchRewardsHistory = async (userId) => {
    setIsLoading(true);
    try {
      const response = await ReportService.fetchRewardsHistory(userId);
      setRewardsHistory(response.data.data.items || []);
    } catch (error) {
      console.error("Failed to fetch rewards history:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setHasSubmitted(true);
    setIsLoading(true);
    fetchRewardsHistory(selectedUserId || null)
      .then((response) => {
        setRewardsHistory(response.data.data.items || []);
      })
      .catch((error) => {
        console.error("Failed to fetch rewards history:", error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleInputChange = (inputValue, actionMeta) => {
    console.log(inputValue, actionMeta);
    if (actionMeta.action === "input-change") {
      setSelectedUserId(inputValue);
    }
  };

  const userOptions = userData.map((user) => ({
    value: user.value,
    label: user.label,
  }));

  return (
    <div className="page-wrapper" style={{ backgroundColor: "white" }}>
      <div className="content container-fluid">
        <div className="page-header">
          <div className="row">
            <div className="col">
              <h3 className="page-title">User Reward History</h3>
              <ul className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="/home">
                    <span>
                      <i className="la la-home"></i> Home
                    </span>
                  </Link>
                </li>
                <li className="breadcrumb-item active">
                  <i className="las la-history mr-1">User History</i>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <form onSubmit={handleSubmit}>
          <div className="row">
            <div className="col-md-6">
              <div className="form-group">
                <label>Select or Type User ID</label>
                <Select
                  options={userOptions}
                  isClearable
                  onChange={handleUserChange}
                  onInputChange={handleInputChange}
                  placeholder="Type or select a user"
                  classNamePrefix="select"
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <Button
                  type="submit"
                  className="btn add-btn"
                  disabled={isLoading}
                  style={{ marginTop: "1.9rem" }}
                >
                  {isLoading ? "Loading..." : "Search"}
                </Button>
              </div>
            </div>
          </div>
        </form>
        <div className="row">
          <div className="col-md-12 my-2">
            {hasSubmitted && rewardsHistory.length > 0 ? (
              <table className="table table-striped custom-table mb-0 datatable">
                <thead>
                  <tr>
                    <th>User Name</th>
                    <th>Reward Title</th>
                    <th>Points</th>
                    <th>Date</th>
                    <th>Assigned Mode</th>
                    <th>Allocation Date</th>
                  </tr>
                </thead>
                <tbody>
                  {rewardsHistory.map((reward, index) => (
                    <tr key={index}>
                      <td>{reward.user_name}</td>
                      <td>{reward.reward_title}</td>
                      <td>{reward.points}</td>
                      <td>{reward.allocation_date}</td>
                      <td>{reward.assigned_mode}</td>
                      <td>{reward.allocation_date}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            ) : hasSubmitted && !isLoading ? (
              <div className="fadeInAnimation">
                No rewards history available.
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
};

export default RewardsHistoryPage;
