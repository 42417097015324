import axios from "axios";

import { getCustomToken } from "../helpers/utils";

const http = axios.create({
  baseURL: process.env.REACT_APP_BACKEND_URL,

  headers: {
    "content-type": "application/json",
    Authorization: `Bearer ${getCustomToken()}`,
  },
});

class RedeemService {
  getProfileHistory() {
    return http.get(`/users/history`, {
      headers: {
        Authorization: `Bearer ${getCustomToken()}`,
      },
    });
  }

  getRate() {
    return http.get(`/core/convertion/config`, {
      headers: {
        Authorization: `Bearer ${getCustomToken()}`,
      },
    });
  }

  getPoints() {
    return http.get(`/users/points`, {
      headers: {
        Authorization: `Bearer ${getCustomToken()}`,
      },
    });
  }

  availGC(gc_count) {
    let payload = JSON.stringify({
      count: gc_count,
    });

    return http.post(`/redeem`, payload, {
      headers: {
        Authorization: `Bearer ${getCustomToken()}`,
      },
    });
  }

  getRewardsByGID(groupId, pageCount) {
    // Construct the base URL
    let url = "/reward";

    // Add "group_id" to the URL if it is provided and not null/undefined
    if (groupId !== null && groupId !== undefined) {
      url += `?group_id=${groupId}&`;
    }

    url += `page=${pageCount}&size=3`;

    // console.warn("URL maker:", url);

    return http.get(url, {
      headers: {
        Authorization: `Bearer ${getCustomToken()}`,
      },
    });
  }
}

export default new RedeemService();
