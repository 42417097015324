import React from "react";

import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import { useNavigate } from "react-router-dom";

import { Cliploader } from "../components/Spinner";

function Login() {
  const { instance } = useMsal();

  const isAuthenticated = useIsAuthenticated();
  const navigator = useNavigate();

  if (isAuthenticated) {
    navigator("/auth/microsoft", { replace: true });
  }

  const handleLogin = () => {
    instance
      .loginRedirect({
        scopes: ["User.Read"],
      })
      .catch((e) => {
        console.log(e);
      });
  };

  console.log("isAuthenticated: ", isAuthenticated);

  return (
    <>
      {!isAuthenticated ? (
        <div
          className="account-page"
          style={{
            display: "flex",
            flexDirection: "column",
            overflow: "hidden",
          }}
        >
          <nav className="navbar navbar-default">
            <div className="container-fluid">
              <div className="navbar-header">
                <div className="navbar-brand">
                  <img src={require("../assets/img/logo.png")} alt="" />
                </div>
              </div>
              <ul className="nav navbar-nav navbar-flex">
                <li>
                  <img
                    border="0"
                    src={require("../assets/img/icons/ec_inc.png")}
                    alt="logo"
                  />
                </li>
                <li>
                  <img
                    border="0"
                    src={require("../assets/img/icons/GreatPlaceToWorkIndia.png")}
                    alt="logo"
                  />
                </li>
                <li>
                  <img
                    border="0"
                    src={require("../assets/img/icons/inc5000.png")}
                    alt="logo"
                    //onClick={()=>window.open('https://www.inc.com/', '_blank')}
                  />
                </li>
              </ul>
            </div>
          </nav>
          <div className="main-wrapper">
            <div className="account-content">
              <div>
                {/* <div className="account-text text-center">
                  <h2
                    className="d-inline-block my-0 mr-3"
                    style={{ fontWeight: "900" }}
                  >
                    Reward
                  </h2>
                  <h3
                    className="d-inline-block my-0"
                    style={{ fontWeight: "400" }}
                  >
                    Program
                  </h3>
                </div> */}
                <img
                  src={
                    "https://evolutyzblobimages.blob.core.windows.net/elp/assets/44.png"
                  }
                  className="img-fluid"
                  alt=""
                  // height="50"
                  width="60%"
                />
              </div>
              <div className="account-box">
                <div className="account-wrapper">
                  <div className="text-center">
                    <div className="account-text text-center">
                      <h2
                        className="d-inline-block my-0 mr-3"
                        style={{ fontWeight: "900" }}
                      >
                        Evolutyz Loyality Program
                      </h2>
                    </div>

                    {/* <img
                      src={require("../assets/img/icons/Office-365-Logo.png")}
                      className="img-fluid"
                      height="50"
                      alt=""
                    /> */}
                  </div>
                  <p className="account-subtitle">&nbsp;</p>

                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center", // Add this line
                    }}
                    onClick={() => {
                      handleLogin();
                    }}
                    className="form-group text-center btn btn-primary account-btn ml-auto"
                  >
                    {/* <img
                      src={require("../assets/img/icons/Office-365-Logo.png")}
                      height="20"
                      alt=""
                    />
                    <p style={{ marginLeft: "5px" }}>Login With Microsoft</p> */}

                    <div
                      // className="bsk-container"
                      style={{ fontFamily: '"Roboto", sans-serif' }}
                    >
                      <object
                        style={{ marginRight: "10px" }}
                        type="image/svg+xml"
                        data="https://s3-eu-west-1.amazonaws.com/cdn-testing.web.bas.ac.uk/scratch/bas-style-kit/ms-pictogram/ms-pictogram.svg"
                        className="x-icon"
                      ></object>
                      {"                    "}Sign in with Microsoft
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div
          className="d-flex justify-content-center align-items-center"
          style={{ height: "100%" }}
        >
          <div className="row">
            <div className="col-md-12">
              <div className="d-flex ">
                <Cliploader />
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default Login;
