/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useContext, useEffect, useState } from "react";

import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import { useNavigate } from "react-router-dom";

import { Cliploader } from "../components/Spinner";
import UserNotFound from "../components/UserNotFound";
import { UserContext } from "../hooks/UserContext";
import InternalServerError from "../pages/InternalServerError";

import AuthService from "../services/SSOAuth.service";
import Loader from "../components/Loader";

const MicrosoftCallback = () => {
  const { currentUser, setCurrentUser } = useContext(UserContext);

  const { instance, accounts, inProgress } = useMsal();

  const isAuthenticated = useIsAuthenticated();
  const navigator = useNavigate();

  const [token, setToken] = useState(null);
  const [customToken, setCustomToken] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const storeCustomToken = (token) => {
    setCustomToken(token);
    localStorage.setItem("customToken", token);
  };

  const clearCustomToken = () => {
    setCustomToken(null);
    localStorage.removeItem("customToken");
  };

  // useEffect(() => {
  //   setCustomToken(localStorage.getItem("customToken") || null);
  // }, []);

  useEffect(() => {
    console.log("AQURING TOKEN");

    async function acquireToken() {
      console.log("I AM HERE 1");

      console.log("I AM HERE 2");

      console.log(isAuthenticated);
      console.log(accounts);

      console.log("I AM HERE 3");

      // First step - Microsoft token
      const response = await instance.acquireTokenSilent({
        scopes: ["User.Read"],
        account: accounts,
      });

      console.log(response.accessToken);

      console.log("I AM HERE 4");

      setToken(response.accessToken);

      AuthService.getCutomToen(response.accessToken)
        .then((response) => {
          // const json2 = response.json();

          console.log("CUSTOM_TOKEN: ", response.data.token);
          storeCustomToken(response.data.token);

          AuthService.getDecodeData()
            .then((response) => {
              // const json3 = response.json();
              console.log("DATA: ", response);
              setCurrentUser(response.data.data);

              if (isAuthenticated) {
                navigator("/home", { replace: true });
              }
            })
            .catch((error) => {
              console.error(error.response.data.detail);
              setError(error.response.status);
            });
        })
        .catch((error) => {
          console.error("error: ", error.message);

          if (error.message === "Network Error") {
            setError(500);
          } else {
            setError(error.response.status);
          }
        });

      // // second step - Cistom Token
      // const response2 = await fetch(
      //   `${process.env.REACT_APP_BACKEND_URL}/auth/access_token?access_token=${response.accessToken}`,
      //   {
      //     method: "POST",
      //     headers: {
      //       "Content-Type": "application/json",
      //       accept: "application/json",
      //     },
      //   }
      // );

      // console.log("I AM HERE 5");

      // if (response2.ok) {
      //   const json2 = await response2.json();

      //   console.log("CUSTOM_TOKEN: ", json2.token);
      //   storeCustomToken(json2.token);

      //   // third step - encode custom token
      //   const response3 = await fetch(
      //     `${process.env.REACT_APP_BACKEND_URL}/auth/decode_token`,
      //     {
      //       method: "POST",
      //       credentials: "include",
      //       headers: {
      //         Authorization: `Bearer ${json2.token}`,
      //         "Content-Type": "application/json",
      //         accept: "application/json",
      //       },
      //     }
      //   );

      //   const json3 = await response3.json();

      //   console.log("DATA: ", json3);

      //   setCurrentUser(json3.data);

      //   console.log("DONE");

      // } else {
      //   setError(response2.statusText);
      // }
    }

    console.log("I AM HERE 0");

    acquireToken();
  }, [currentUser]);

  useEffect(() => {
    console.error("ERRRO PAGE: ", error);
  }, [error]);

  return (
    <>
      {error ? (
        error === 404 ? (
          <UserNotFound />
        ) : (
          <InternalServerError />
        )
      ) : (
        <Loader />
      )}
    </>
  );
};

export default MicrosoftCallback;
