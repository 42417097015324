/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { getCustomToken } from "../helpers/utils";

function ItemSelectRewardrecomended(props) {
  const [showSuggestions, setShowSuggestions] = useState(false); // State to control suggestion visibility
  const [error, setError] = useState(null);
  const [usersdata, setUsersData] = useState([]);
  const [value, setValue] = useState("");

  const fetchSuggestions = async (search) => {
    try {
      let apiUrl = `${process.env.REACT_APP_BACKEND_URL}/users?page=1&size=10`;

      // Check if 'search' is not empty
      if (search !== "") {
        apiUrl += `&search=${search}`;
      }

      const response = await fetch(apiUrl, {
        headers: {
          Authorization: `Bearer ${getCustomToken()}`,
        },
      }); // Replace with your actual API endpoint
      const data = await response.json();
      setUsersData(data.items || []); // Assuming the API response has a "suggestions" property
    } catch (error) {
      console.error("Error fetching suggestions:", error);
    }
  };

  useEffect(() => {
    const timeOut = setTimeout(() => {
      console.log(`Calling API with search: ${value}`);
      fetchSuggestions(value); // Fetch initial suggestions on component mount
    }, 1500);

    return () => {
      clearTimeout(timeOut);
    };
  }, [value]);

  useEffect(() => {
    if (!props.activeRecomended.email_id) {
      setValue("");
    } else {
      setValue(props.activeRecomended.email_id);
    }
  }, [props.activeRecomended]);

  const handleChangeTitle = async (evt) => {
    setValue(evt.target.value);

    console.log("SEARCH: ", evt.target.value);
    setError(null);

    setShowSuggestions(true);

    await fetchSuggestions(evt.target.value);
  };

  return (
    <div>
      <input
        className={"form-control"}
        value={value}
        placeholder="Search user by name.."
        onChange={handleChangeTitle}
        onFocus={() => {
          props.setActiveRecomended({});
          setShowSuggestions(true);
        }}
      />
      {showSuggestions && (
        <div className="suggestions">
          {usersdata.map((user) => (
            <div
              className="suggestion"
              key={user.id}
              onClick={() => {
                props.setActiveRecomended(user);
                setShowSuggestions(false);
              }}
            >
              {user.display_name}
            </div>
          ))}
        </div>
      )}
    </div>
  );
}

export default ItemSelectRewardrecomended;
