import React from "react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "./assets/css/bootstrap.min.css";
import "./assets/css/font-awesome.min.css";
import "./assets/css/line-awesome.min.css";
import "./assets/css/style.css";
import UserContextProvider from "./hooks/UserContext";
import MainLayout from "./layouts/MainLayout";
import Banner from "./pages/Banner";
import Employees from "./pages/Employees";
import ErrorElement from "./pages/Error";
import Groups from "./pages/Groups";
import History from "./pages/History";
import AssignReward from "./pages/AssignReward";
import Login from "./pages/Login";
import Logout from "./pages/LogOut";
import MicrosoftCallback from "./pages/MicrosoftCallback";
import NotFound from "./pages/NotFound";
import Redeem from "./pages/Redeem";
import Reports from "./pages/Reports";
import Reward from "./pages/Reward";
import UserDashboard from "./pages/UserDashboard";
import Userrewardhistory from "./pages/Userrewardhistory";

function App() {
  const router = createBrowserRouter([
    {
      path: "/",
      element: <Login />,
      errorElement: <ErrorElement />,
    },

    {
      path: "/home",
      element: <MainLayout element={<UserDashboard />} />,
      errorElement: <ErrorElement />,
    },

    {
      path: "/auth/microsoft/*",
      element: <MicrosoftCallback />,
      errorElement: <ErrorElement />,
    },

    {
      path: "/rewards",
      element: <MainLayout element={<Reward />} />,
      errorElement: <ErrorElement />,
    },
    {
      path: "/rewards/users",
      element: <MainLayout element={<AssignReward />} />,
      errorElement: <ErrorElement />,
    },
    {
      path: "/banners",
      element: <MainLayout element={<Banner />} />,
      errorElement: <ErrorElement />,
    },
    {
      path: "/reports",
      element: <MainLayout element={<Reports />} />,
      errorElement: <ErrorElement />,
    },
    {
      path: "/history",
      element: <MainLayout element={<History />} />,
      errorElement: <ErrorElement />,
    },

    {
      path: "/groups",
      element: <MainLayout element={<Groups />} />,
      errorElement: <ErrorElement />,
    },

    {
      path: "/users",
      element: <MainLayout element={<Employees />} />,
      errorElement: <ErrorElement />,
    },

    {
      path: "/redeem",
      element: <MainLayout element={<Redeem />} />,
      errorElement: <ErrorElement />,
    },

    {
      path: "/logout",
      element: <Logout />,
      errorElement: <ErrorElement />,
    },
    {
      path: "/rewards/history",
      element: <MainLayout element={<Userrewardhistory />} />,
      errorElement: <ErrorElement />,
    },

    {
      path: "*",
      element: <NotFound />,
      errorElement: <ErrorElement />,
    },
  ]);

  return (
    <UserContextProvider>
      <RouterProvider router={router} />
      <ToastContainer
        position="top-center"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </UserContextProvider>
  );
}
export default App;
