/*eslint-disable */
import { useMsal } from "@azure/msal-react";
import axios from "axios";
import React, { createRef, useEffect, useRef, useState } from "react";
import Carousel from "react-grid-carousel";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { getCustomToken } from "../helpers/utils";

import { useContext } from "react";
import Load from "../components/Loader";
import { UserContext } from "../hooks/UserContext";
import DashboardService from "../services/Dashboard.service";
import RedeemService from "../services/Redeem.service";

import { Box, createTheme, ThemeProvider } from "@mui/material";
import MaterialTable, { MTablePagination } from "material-table";
import tableIcons from "../components/MaterialTableIcons";
import { TablePagination } from "@material-ui/core"; 

function UserDashboard() {
  // const ProfileData = (props) => {
  //   console.log(props.graphData);
  // };

  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [currPage, setCurrPage] = useState(1);

  const handleRowsPerPageChange = (event) => {
    const newPageSize = parseInt(event.target.value, 10);
    setRowsPerPage(newPageSize);
    setCurrPage(1); // Reset current page to the first page
    // fetchBannerList(); // Trigger a new API call with the updated page size
    tableRef.current.onQueryChange();
  };


  const handleChnageRowsPerPage = (e) => {
    console.log("Im here");
    setRowsPerPage(e.target.value);
 
    tableRef.current.onChangePage(null, 0);
 
    // tableRef.current && tableRef.current.onQueryChange();
  };


  const { currentUser } = useContext(UserContext);

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (currentUser.role !== undefined) {
      // If currentUser is defined, set isLoading to false

      console.warn("currentUser: ", currentUser);
      setIsLoading(false);
    }
  }, [currentUser]);

  const { accounts } = useMsal();
  // eslint-disable-next-line no-unused-vars
  const [graphData, setGraphData] = useState(null);

  const name = accounts[0] && accounts[0].name;
  // eslint-disable-next-line no-unused-vars
  const [bannersData, setBannersData] = useState([]);

  const [rewardsData, setRewardsData] = useState([]);
  const [selectedReward, setSelectedReward] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(false);

  const tableRef = createRef();
  const defaultMaterialTheme = createTheme();

  // const BadgeComponent = ({ isWinner }) => {
  //   return;
  // };

  const [columns, setColumns] = useState([
    {
      title: "Rewards",
      render: (rowData) => rowData.title || "-",
      // export: true,
      headerStyle: {
        backgroundColor: "#ffedde",
      },
    },

    {
      title: "Membership Associated As",
      render: (rowData) => rowData.part_of || "-",

      // export: true,
      headerStyle: {
        backgroundColor: "#ffedde",
      },
    },

    {
      title: "Ways To Earn Points",
      render: (rowData) => (
        <div>
          {rowData.point_type === "true" && (
            <>
              <span className={"badge bg-inverse-success"}>{"winning"}</span>
              {","}
            </>
          )}

          <span className={"badge bg-inverse-warning"}>{"participating"}</span>
        </div>
      ),

      // export: true,
      headerStyle: {
        backgroundColor: "#ffedde",
      },
    },

    {
      title: "Participant Status",
      render: (rowData) => {
        let badgeType;
        switch (rowData.status) {
          case "NA":
            badgeType = (
              <span className={"badge bg-inverse-danger"}>{"missed"}</span>
            );
            break;
          case "winner":
            badgeType = (
              <span className={"badge bg-inverse-success"}>{"winner"}</span>
            );
            break;
          case "participant":
            badgeType = (
              <span className={"badge bg-inverse-warning"}>
                {"participant"}
              </span>
            );
            break;
          default:
            badgeType = null;
        }

        return badgeType;
      },

      // export: true,
      headerStyle: {
        backgroundColor: "#ffedde",
      },
    },
  ]);

  const getRandomLightColor = () => {
    const letters = "0123456789ABCDEF";
    let color = "#";
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 10 + 6)];
    }
    return color;
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        var config = {
          method: "get",
          url: `${process.env.REACT_APP_BACKEND_URL}/banners?page=1&size=5`,
          headers: {
            Authorization: `Bearer ${getCustomToken()}`,
          },
        };

        axios(config)
          .then(function (response) {
            console.log("Im here");
            const sortedBanners = response.data.items.sort((a, b) =>
              b.created_at.localeCompare(a.created_at)
            );
            const topBanners = sortedBanners.slice(0, 5);
            console.log(JSON.stringify(response.data));
            setBannersData(topBanners);
          })
          .catch(function (error) {});
      } catch (error) {
        console.error(error.message);
      }
    };

    fetchData();
  }, []);

  const [convRate, setConvRate] = useState(1);

  const convertPointsToRupees = (points) => {
    const rupeesValue = points / convRate; // 1000 points equal 1 rupee
    return rupeesValue;
  };

  const [totalPages, setTotalPages] = useState(1); // Pagination count
  const [currentPage, setCurrentPage] = useState(1); // Current page

  const [MyPoints, setMyPoints] = useState({
    totalReward: 0,
    totalRemaining: 0,
  });

  const updatedMyPoints = {
    ...MyPoints,
    totalRemainingWorth: convertPointsToRupees(MyPoints.totalRemaining),
  };

  const [isResponseEmpty, setIsResponseEmpty] = useState(false);

  const [isLoadingRewards, setisLoadingRewards] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      if (currentPage == 1) {
        RedeemService.getRate()
          .then((response) => {
            console.log("json3:", response.data);
            setConvRate(response.data.in_points);
          })
          .catch((error) => {
            console.error(error.message);
          });
      }

      if (currentPage == 1) {
        RedeemService.getPoints()
          .then((response) => {
            console.log("json3:", response.data);
            setMyPoints(response.data);
          })
          .catch((error) => {
            console.error(error.message);
          });
      }

      if (currentPage == 1) {
        DashboardService.getAssignedRewardsCount()
          .then((response) => {
            console.log(JSON.stringify(response.data));
            setrewardCount(response.data.items);
          })
          .catch((error) => {
            console.error(error.message);
            // setApiError(error);
          });
      }
      // if (["ADMIN", "USER"].includes(currentUser.role)) {
      RedeemService.getRewardsByGID(currentUser.group, currentPage)
        .then((response) => {
          console.log("I am HERE getRewardsByGID:", response.data);

          if (response.data.page === response.data.pages) {
            setRewardsData([...rewardsData, ...response.data.items]);
            setIsResponseEmpty(true); // Set the flag to true if the response length is zero
            setDisableButton(true);
          } else {
            setRewardsData([...rewardsData, ...response.data.items]);
            setTotalPages(response.data.pages);
          }
        })
        .catch((error) => {
          console.error(error.message);
        })
        .finally(() => {
          setisLoadingRewards(false);

          // Check if the total length of rewardsData is less than 4 and print "abc" if true
          // if (rewardsData.length <= 3) {
          //   setDisableButton(true);
          // }
        });
      // }
    };
    if (["ADMIN", "USER"].includes(currentUser.role)) {
      fetchData();
    }
  }, [currentUser, currentPage]);

  const [rewardCount, setrewardCount] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      DashboardService.getAssignedRewardsCount()
        .then((response) => {
          console.log("all rewards loaded: ", JSON.stringify(response.data));
          setrewardCount(response.data.items);
        })
        .catch((error) => {
          console.error(error.message);
        });
    };

    if (currentUser.role === "ADMIN") {
      fetchData();
    }
  }, []);

  const [disableButton, setDisableButton] = useState(false);

  const scrollRef = useRef(null);

  const handleShowMore = () => {
    // setCurrentPage((currentPage) => currentPage + 1);
    let newPage = currentPage + 1;
    // Ensure the new page is within valid bounds
    if (newPage >= 1 && newPage <= totalPages) {
      setCurrentPage(newPage);
    } else {
      setDisableButton(true);
    }
  };

  const [searchTerm, setSearchTerm] = useState("");

  useEffect(() => {
    if (currentPage > 1) {
      console.log("Scroll Height: ", document.body.scrollHeight);

      if (scrollRef.current) {
        const currentScrollPosition =
          scrollRef.current.getBoundingClientRect().top + window.pageYOffset;
        window.scrollTo({
          top: currentScrollPosition + 130 * 3,
          behavior: "smooth",
        });
      }
    }
  });

  const handleChange = (event) => {
    const { value } = event.target;

    console.log("handlrchnage: ", value);
    setSearchTerm(value);

    // Debounce the search callback
    handleSearch(value);
  };

  const openModalWithReward = (reward) => {
    setSelectedReward(reward);
    setIsModalVisible(true);
  };

  return (
    <div className="page-wrapper" style={{ backgroundColor: "white" }}>
      <div className="content">
        <div className="page-header">
          <div className="row">
            <div className="col-sm-12">
              {/* <ProfileData graphData={graphData} /> */}
              <h3 className="page-title">Welcome, {name}!</h3>
              <ul className="breadcrumb">
                <li className="breadcrumb-item active">
                  <i className="la la-home"></i> Home
                </li>
              </ul>
            </div>
          </div>
        </div>

        {isLoading ? (
          <div
            className="row align-items-center justify-content-center"
            style={{
              flexDirection: "column",
              // backgroundColor: "red",
              width: "100%",
              height: "60vh",
            }}
          >
            <Load />
          </div>
        ) : (
          <>
            {rewardCount.length > 0 && currentUser.role === "ADMIN" && (
              <Carousel
                indicators={false}
                cols={3}
                rows={1}
                gap={10}
                loop={true}
                slide={true}
                autoplay={4000}
                arrowLeft={
                  rewardCount.length <= 3 && <div style={{ display: "none" }} />
                }
                arrowRight={
                  rewardCount.length <= 3 && <div style={{ display: "none" }} />
                }
              >
                {rewardCount.map((req, index) => (
                  <Carousel.Item key={req.id}>
                    <div
                      className="card dash-widget col-md-12"
                      style={{
                        borderRadius: 10,
                        borderColor: "#f58b29",
                        backgroundColor: "#fff",
                        // opacity: 0.7,
                        boxShadow: "2px 2px 4px rgba(0, 0, 0, 0.6)", // Define the box shadow here
                        width: "98%",
                      }}
                    >
                      <div className="card-body">
                        <span className="dash-widget-icon">
                          <i className="fa fa-trophy"></i>
                        </span>
                        <div className="dash-widget-info">
                          <h3>{req.count}</h3>
                          <span>
                            {req.title
                              .split("-")
                              .slice(0, req.title.split("-").length - 1)}
                          </span>
                          <span className="text-muted">
                            {req.title
                              .split("-")
                              .slice(
                                req.title.split("-").length - 1,
                                req.title.split("-").length
                              )}
                          </span>
                        </div>
                      </div>
                    </div>
                  </Carousel.Item>
                ))}
              </Carousel>
            )}

            <div className="row my-0">
              <div
                className="col-md-4"
                style={{
                  display: "flex", // Add this to make it a flex container
                  flexDirection: "column", // Stack items vertically
                  alignItems: "center",
                }}
              >
                <div
                  className="card mb-0"
                  style={{
                    borderRadius: 10,
                    borderColor: "#f58b29",
                    backgroundColor: "#fff",
                    // opacity: 0.7,
                    boxShadow: "2px 2px 4px rgba(0, 0, 0, 0.6)", // Define the box shadow here
                    width: "100%",
                  }}
                >
                  <div className="card-body">
                    <div
                      className="row"
                      style={{ fontSize: 20, color: "gray" }}
                    >
                      <div className="col">
                        <span> Total Remaining Points</span>
                      </div>
                    </div>
                    <div className="row" style={{ fontSize: 30 }}>
                      <div className="col d-flex align-items-center justify-content-left">
                        <div>
                          <span>
                            <i
                              className="las la-coins"
                              style={{
                                color: "#f58b29",
                              }}
                            ></i>
                          </span>

                          <span>
                            {" "}
                            {updatedMyPoints.totalRemaining.toLocaleString(
                              "en-IN"
                            )}{" "}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  className="card mb-0"
                  style={{
                    borderRadius: 10,
                    borderColor: "#f58b29",
                    backgroundColor: "#fff",
                    // opacity: 0.7,
                    boxShadow: "2px 2px 4px rgba(0, 0, 0, 0.6)", // Define the box shadow here
                    marginTop: "10%",
                    width: "100%",
                  }}
                >
                  <div className="card-body">
                    <div
                      className="row"
                      style={{ fontSize: 20, color: "gray" }}
                    >
                      <div className="col">
                        <span> Points Worth </span>
                      </div>
                    </div>
                    <div className="row" style={{ fontSize: 30 }}>
                      <div className="col d-flex align-items-center justify-content-left">
                        <div>
                          <span>
                            <i
                              className="las la-rupee-sign"
                              style={{ color: "#069557" }}
                            ></i>
                          </span>

                          <span>
                            {updatedMyPoints.totalRemainingWorth.toLocaleString(
                              "en-IN"
                            )}{" "}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {bannersData.length > 0 ? (
                <div className="col-md-8">
                  <Carousel
                    cols={1}
                    rows={1}
                    // gap={15}
                    loop={true}
                    slide={true}
                    autoplay={3000}
                    showDots={bannersData.length <= 1 ? false : true}
                    arrowLeft={
                      bannersData.length <= 1 && (
                        <div style={{ display: "none" }} />
                      )
                    }
                    arrowRight={
                      bannersData.length <= 1 && (
                        <div style={{ display: "none" }} />
                      )
                    }

                    // nextIcon='<span aria-hidden="true" className="carousel-control-next-icon"></span>'
                  >
                    {bannersData.map((req) => (
                      <Carousel.Item key={req.id}>
                        <div
                          className="card dash-widget"
                          style={{
                            borderRadius: 10,
                            borderColor: "#f58b29",
                            marginBottom: 0,
                            boxShadow: "2px 2px 4px rgba(0, 0, 0, 0.6)", // Define the box shadow here
                          }}
                        >
                          <div className="card-body" style={{ padding: 5 }}>
                            {/* <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                height: "100%", // Set a fixed height for the container
                                backgroundImage: `url("${req.image}")`,
                                backgroundSize: "100% 100%",

                                width: "100%",
                              }}
                            ></div> */}
                            <img
                              src={req.image}
                              style={{
                                maxWidth: "100%",
                                margin: "auto",
                                height: 260,
                                borderRadius: 8,
                                display: "block",
                              }}
                            />
                          </div>
                        </div>
                      </Carousel.Item>
                    ))}
                  </Carousel>
                </div>
              ) : (
                <div className="col-md-8">
                  <div
                    className="card dash-widget"
                    style={{
                      borderRadius: 10,
                      borderColor: "#f58b29",
                      // opacity: 0.7,
                      boxShadow: "2px 2px 4px rgba(0, 0, 0, 0.6)", // Define the box shadow here
                      width: "99%",
                      height: "35vh",
                    }}
                  >
                    <div className="card-body">
                      <Skeleton
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          height: "100%", // Set a fixed height for the container
                          backgroundSize: "100% 100%",

                          width: "100%",
                        }}
                      ></Skeleton>
                    </div>
                  </div>
                </div>
              )}
            </div>

            {/* <div className="row mt-4">
              <div className="col">
                <h3>My Reward Programs</h3>
              </div>
            </div> */}

            <div className="row mt-3">
              <div className="col-md-12">
                <ThemeProvider theme={defaultMaterialTheme}>
                  <div style={{ width: "100%" }}>
                    <MaterialTable
                      title="My Reward Programs"
                      tableRef={tableRef}
                      columns={columns}
                      data={(query) =>
                        new Promise((resolve, reject) => {
                          let url =
                            process.env.REACT_APP_BACKEND_URL + "/rewards/me";
                          url += "?page=" + (query.page + 1);
                          url += "&size=" + rowsPerPage;
                          // Add search query if available
                          if (query.search && query.search.length > 0) {
                            console.log("search: ", query.search);
                            url += "&search=" + query.search;
                          }
                          fetch(url, {
                            headers: {
                              Authorization: `Bearer ${getCustomToken()}`,
                            },
                          })
                            .then((response) => response.json())
                            .then((result) => {
                              console.info("RESULT:", result);
                              setIsLoading(false); // Hide loading indicator after fetching

                              resolve({
                                data: result.items,
                                page: result.page - 1,
                                totalCount: result.total,
                              });
                            })
                            .catch((error) => {
                              console.error("Error fetching groups:", error);
                            })
                            .finally(() => {
                              setIsLoading(false);
                            });
                        })
                      }
                      icons={tableIcons}
                      options={{
                        headerStyle: { backgroundColor: "#ffedde" },
                        showTitle: true,
                        paging: true,
                        search: false,
                        sorting: false,
                        actionsColumnIndex: -1,
                        exportButton: false,
                        paginationType: "normal",
                        pageSize: rowsPerPage,
                        // pageSizeOptions: [5, 10, 15, 30],
                        emptyRowsWhenPaging: false,

                        actionsCellStyle: {
                          justifyContent: "center",
                        },
                      }}
                      localization={{
                        header: {
                          actions: "",
                        },
                      }}
                      // onChangeRowsPerPage={(event) => {
                      //   setRowsPerPage(event.target.value);
                      // }}
                      onSearchChange={handleChange}
                      components={{
                        Pagination: (props) => (
                          <TablePagination
                            {...props}
                            rowsPerPageOptions={[5, 10, 15]}
                            rowsPerPage={rowsPerPage}
                            onRowsPerPageChange={handleChnageRowsPerPage}
                          />
                        ),
                      }}
                      // searchText={searchTerm}
                      actions={[
                        // (rowData) => ({
                        //   icon: "edit",
                        //   tooltip: "edit group",
                        //   onClick: (event, rowData) => {
                        //     handleShowEditModal(rowData);
                        //   },
                        //   disabled: !rowData.is_public,
                        // }),

                        {
                          icon: "refresh",
                          tooltip: "Refresh Data",
                          isFreeAction: true,
                          onClick: () =>
                            tableRef.current &&
                            tableRef.current.onQueryChange(),
                        },
                      ]}
                      // components={{
                      //   Pagination: (subProps) => {
                      //     return (
                      //       <Box
                      //         display="flex"
                      //         justifyContent="flex-end"
                      //         alignItems="center"
                      //       >
                      //         <div style={{ paddingRight: 10 }}>
                      //           Rows per page:{"  "}
                      //         </div>
                      //         <select
                      //           disabled
                      //           value={rowsPerPage}
                      //           onChange={handleRowsPerPageChange}
                      //         >
                      //           {[5, 10, 15, 30].map((pageSizeOption) => (
                      //             <option
                      //               key={pageSizeOption}
                      //               value={pageSizeOption}
                      //             >
                      //               {pageSizeOption}
                      //             </option>
                      //           ))}
                      //         </select>
                      //         <Box>
                      //           <MTablePagination {...subProps} />
                      //         </Box>
                      //       </Box>
                      //     );
                      //   },
                      // }}
                    />
                  </div>
                </ThemeProvider>
              </div>
            </div>

            {/* {rewardsData.length > 0 && (
              <table className="table table-striped custom-table mb-0 datatable">
                <thead>
                  <tr>
                    <th>Title</th>
                    <th>Description</th>
                    <th>Points</th>
                    <th>Date Assigned</th>
                  </tr>
                </thead>
                <tbody>
                  {rewardsData.map((reward, index) => (
                    <tr key={index}>
                      <td>{reward.title}</td>
                      <td>{reward.description}</td>
                      <td>{reward.points}</td>
                      <td>
                        {new Date(reward.assigned_date).toLocaleDateString()}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            )} */}
          </>
        )}
      </div>
    </div>
  );
}

export default UserDashboard;
