/* eslint-disable */
import { createRef, useContext, useEffect, useRef, useState } from "react";
import { Dropdown, Modal, Nav, Tab } from "react-bootstrap";
import { Link } from "react-router-dom";
import { UserContext } from "../hooks/UserContext";
import CoreService from "../services/Core.service";
import UserService from "../services/User.service";
import { Box, createTheme, ThemeProvider } from "@mui/material";
import MaterialTable, { MTablePagination } from "material-table";
import tableIcons from "../components/MaterialTableIcons";
import { getCustomToken } from "../helpers/utils";
import useDebounce from "../hooks/useDebounce";
import AuthService from "../services/SSOAuth.service";

import "react-loading-skeleton/dist/skeleton.css";

import {
  FailedNotification,
  SuccessNotifation,
} from "../components/Notifications";
import Pagination from "../components/Pagination";
import { Beatloader } from "../components/Spinner";
import { checkError, fieldValidate } from "../helpers/validators";
import { TablePagination } from "@material-ui/core";

import Load from "../components/Loader";

function Employees() {
  const { currentUser } = useContext(UserContext);

  const [columns, setColumns] = useState([]);

  useEffect(() => {
    if (currentUser) {
      console.log("Im hereeeee");
      setColumns([
        {
          minWidth: 60,
          maxWidth: 60,
          title: "Profile",
          render: (rowData) => (
            <span
              className="avatar cursor-pointer"
              // onClick={() => {
              //   handleShow(req);
              // }}
            >
              <img
                src={ProfilePicBase + rowData.employeeID + ".jpg"}
                height={"36px"}
                width={"36px"}
                alt={`${rowData?.employeeeID}'s profile picture`}
                onError={handleImageError} // Use the error handler
              />
            </span>
          ),
          headerStyle: {
            backgroundColor: "#ffedde",
          },
        },
        {
          title: "Name",
          render: (rowData) => rowData.display_name || "-",
          headerStyle: {
            backgroundColor: "#ffedde",
          },
        },

        {
          title: "Email",
          render: (rowData) => rowData.email_id || "-",
          headerStyle: {
            backgroundColor: "#ffedde",
          },
        },

        {
          title: "EmployeeID",
          render: (rowData) => rowData.employeeID || "-",
          headerStyle: {
            backgroundColor: "#ffedde",
          },
        },

        {
          title: "Department",
          render: (rowData) => rowData.department || "-",
          headerStyle: {
            backgroundColor: "#ffedde",
          },
        },

        {
          minWidth: 90,
          maxWidth: 90,
          title: "Role",
          render: (rowData) => {
            return (
              <>
                <span
                  className={
                    rowData.is_admin
                      ? "badge bg-inverse-success"
                      : "badge bg-inverse-warning"
                  }
                >
                  {rowData.is_admin ? "admin" : "user"}
                </span>

                <span className={"ml-1 badge bg-inverse-danger"}>
                  {currentUser?.id === rowData.id && "me"}
                </span>
              </>
            );
          },
          // export: true,
          headerStyle: {
            backgroundColor: "#ffedde",
          },
        },
      ]);
    }
  }, [currentUser]);

  const tableRef = createRef();

  const defaultMaterialTheme = createTheme();

  const [toggleSpinner, settoggleSpinner] = useState(false);

  const [searchTerm, setSearchTerm] = useState("");

  const [isLoading, setIsLoading] = useState(true);

  const [currPage, setCurrPage] = useState(1);

  const [rowsPerPage, setRowsPerPage] = useState(5);

  const [page, setPage] = useState(0);

  const [userList, setUserList] = useState([]);
  const [refreshKey, setRefreshKey] = useState(0);

  const [totalItems, setTotalItems] = useState(0); // Add state for total items

  // useEffect(() => {
  //   fetchData();
  // }, [page, searchTerm, rowsPerPage]);

  // const pageSize = 8;

  // const [selectedUser, setSelectedUser] = useState([]);
  // const handleUserClick = (user) => {
  //   setSelectedUser(user);
  // };

  // const [toggleSpinner, settoggleSpinner] = useState(false);
  // const [wasLastList, setWasLastList] = useState(false); // setting a flag to know the last list

  // const [isLoading, setIsLoading] = useState(false);
  // const [isLoading2, setIsLoading2] = useState(false);

  // const [fetchError, setFetchError] = useState(false);

  // // for profile
  // const [modal, setModal] = useState(false);
  // const [ActiveItem, setActiveItem] = useState({});
  // const handleClose = () => setModal((current) => !current);
  // const handleShow = (userData) => {
  //   setActiveItem(userData);
  //   setModal(true);
  // };

  // const [modal1, setModal1] = useState(false);
  // const [ActiveItem1, setActiveItem1] = useState({});
  // const [formData, setFormData] = useState({
  //   group: "",
  //   employment: "",
  //   is_admin: false,
  // });
  // const [errors, setErrors] = useState({
  //   group: "",
  //   employment: "",
  //   is_admin: "",
  // });

  // useEffect(() => {
  //   if (currentUser) {
  //     // If currentUser is defined, set isLoading to false
  //     setIsLoading(false);
  //   }
  // }, [currentUser]);

  // const handleClose1 = () => {
  //   setModal1((current) => !current);
  //   setFormData({
  //     group: "",
  //     employment: "",
  //     is_admin: false,
  //   });
  // };
  // const handleShow1 = (user) => {
  //   setModal1(true);
  //   console.log("ActiveItem: ", user);
  //   setActiveItem1(user);
  // };

  // // for update user
  const [modal2, setModal2] = useState(false);
  const [formData2, setFormData2] = useState({
    id: "",
    display_name: "",
    email_id: "",
    employment_id: "",
    group_id: "",
    is_admin: false,
  });
  const [errors2, setErrors2] = useState({
    display_name: "",
    email_id: "",
    employment_id: "",
    group_id: "",
    is_admin: "",
  });
  const handleClose2 = () => {
    setModal2(false);
    settoggleSpinner(false);
    setErrors2({
      id: "",
      display_name: "",
      email_id: "",
      employment_id: "",
      group_id: "",
      is_admin: false,
    });
    setErrors2({
      display_name: "",
      email_id: "",
      employment_id: "",
      group_id: "",
      is_admin: "",
    });

    if (tableRef.current) {
      tableRef.current.onQueryChange();
    }
  };
  const handleShow2 = (rowData) => {
    console.log("handle show side menu for edit details");
    setModal2(true);
    console.log("ActiveItem: ", rowData);
    const userData = {
      ...formData2,
      id: rowData.id,
      display_name: rowData.display_name,
      email_id: rowData.email_id,
      employment_id: rowData.employment_id,
      is_admin: rowData.is_admin,
    };
    setFormData2(userData);
  };

  const fetchAADUsers = async () => {
    console.log("Im Here ABCD");
    setIsLoading(true);
    UserService.aadSyncToDB()
      .then((response) => {
        console.log(response);
        SuccessNotifation(response.data.message);
      })
      .catch((error) => {
        console.error(error);
        FailedNotification(error);
        setFetchError(true); // Set the fetchError state to true
      })
      .finally(() => {
        setIsLoading(false);
        settoggleSpinner((current) => !current);
      });
  };

  const handleClick = () => {
    settoggleSpinner((current) => !current);
    fetchAADUsers();
  };

  // const handleSyncEmployees = () => {};
  // // eslint-disable-next-line no-unused-vars

  // //Get all users
  // const [userData, setUserData] = useState([]);
  // // eslint-disable-next-line no-unused-vars
  // const [userData1, setUserData1] = useState([]);

  // const [searchTerm, setSearchTerm] = useState("");

  // const [Loader, setLoader] = useState(true);

  // const handleSearchInputChange = (event) => {
  //   setSearchTerm(event.target.value);
  // };

  // const filteredUserData1 = userData1.filter((user) =>
  //   user.display_name.toLowerCase().includes(searchTerm.toLowerCase())
  // );

  // const filteredUserData = userData.filter((user) =>
  //   user.User.display_name.toLowerCase().includes(searchTerm.toLowerCase())
  // );

  // const [currentPage, setCurrentPage] = useState(1);

  // // const [itemsPerPage, setItemsPerPage] = useState(8);
  // // const isLastPage = currentPage * itemsPerPage >= filteredUserData1.length;
  // // Compute the current items to show
  // // Pagination for getAadEmployeeSync  API call
  // // const indexOfLastItem = currentPage * itemsPerPage;
  // // const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  // // const currentItems = filteredUserData1.slice(
  // //   indexOfFirstItem,
  // //   indexOfLastItem
  // // );
  // // const [totalItems, setTotalItems] = useState(0);

  // // const totalPages = Math.ceil(totalItems / itemsPerPage);
  // // const pageNumbers = Array.from({ length: totalPages }, (_, i) => i + 1);

  // // const [page, setPage] = useState(1); // Initial page

  // const [isLoadingNext, setIsLoadingNext] = useState(false);
  // const [isLoadingPrev, setIsLoadingPrev] = useState(false);

  // const submitHandler = (e) => {
  //   e.preventDefault();

  //   console.log("Added Group");
  //   console.log(formData);

  //   UserService.assignUserGroup(formData, ActiveItem1)
  //     .then((response) => {
  //       console.log(response);
  //       SuccessNotifation("Group assigneed Successfully");
  //     })
  //     .catch((error) => {
  //       if (error.response) {
  //         // The request was made and the server responded with a status code >= 400
  //         console.error("Error:", error.response.data);
  //         console.error("Status Code:", error.response.status);
  //         FailedNotification(error.response.data.detail);
  //       } else if (error.request) {
  //         // The request was made but no response was received
  //         console.error("No response received:", error.request);
  //       } else {
  //         // Something else happened while setting up the request
  //         console.error("Error:", error.message);
  //         setFetchError(true); // Set the fetchError state to true
  //       }
  //     })
  //     .finally(() => {
  //       settoggleSpinner((current) => !current);
  //       setModal1((current) => !current);
  //       setActiveItem1({});
  //       setFormData({
  //         group: "",
  //         employment: "",
  //         is_admin: false,
  //       });
  //     });
  // };

  const submitHandler2 = async (e) => {
    e.preventDefault();
    settoggleSpinner(true);
    console.log("edited User");

    let payload = JSON.stringify({
      employment_id: formData2.employment_id,
      is_admin: formData2.is_admin,
    });

    console.log("payload: ", payload);

    try {
      const response = await UserService.updateUserDetails(
        formData2.id,
        payload
      );
      console.log(response);
      SuccessNotifation("User Updated Successfully");

      setRefreshKey((oldKey) => oldKey + 1);
    } catch (error) {
      if (error.response) {
        console.error("Error:", error.response.data);
        console.error("Status Code:", error.response.status);

        if (error.response.status === 422) {
          FailedNotification(error.response.data.detail[0]["msg"]);
        } else {
          FailedNotification(error.response.data.detail);
        }
      } else if (error.request) {
        console.error("No response received:", error.request);
      } else {
        console.error("Error:", error.message);
      }
    } finally {
      settoggleSpinner(false);
      setModal2(false);
      console.log("Finished processing submitHandler2");
    }
  };

  // const changeHandler = (e) => {
  //   let { name, value } = e.target;
  //   console.warn(name, value);
  //   if (name === "is_admin") {
  //     value = e.target.value === "true";
  //   }
  //   setFormData({ ...formData, [name]: value });
  // };

  const changeHandler2 = (e) => {
    let { name, value } = e.target;
    console.warn(name, value);
    if (name === "is_admin") {
      value = e.target.value === "true";
    }
    setFormData2({ ...formData2, [name]: value });
  };

  // const validateForm = (e) => {
  //   e.preventDefault();
  //   const newErrors = { ...errors };

  //   Object.keys(formData).forEach((field) => {
  //     switch (field) {
  //       case "group":
  //         newErrors.group = fieldValidate(field, formData[field], {
  //           required: true,
  //         });
  //         break;
  //       case "employment":
  //         newErrors.employment = fieldValidate(field, formData[field], {
  //           required: true,
  //         });
  //         break;

  //       default:
  //         break;
  //     }
  //   });
  //   setErrors(newErrors);

  //   console.log("ERROR_DATA:", newErrors);

  //   const noError = checkError(newErrors);

  //   console.log(noError);

  //   if (noError) {
  //     settoggleSpinner((current) => !current);
  //     submitHandler(e);
  //   }
  // };

  const validateForm2 = (e) => {
    console.log("Im here");
    e.preventDefault();
    const newErrors2 = { ...errors2 };

    Object.keys(formData2).forEach((field) => {
      switch (field) {
        case "employment_id":
          newErrors2.employment_id = fieldValidate(field, formData2[field], {
            required: true,
          });
          break;

        default:
          break;
      }
    });
    setErrors2(newErrors2);

    console.log("ERROR_DATA:", newErrors2);

    const noError = checkError(newErrors2);

    if (noError) {
      settoggleSpinner((current) => !current);
      submitHandler2(e);

      if (tableRef.current) {
        tableRef.current.onQueryChange();
      }
    }
  };

  const [EmployementType, setEmployementType] = useState([]);
  // const [GroupType, setGroupType] = useState([]);

  // const [activeTab, setActiveTab] = useState("employeeprofiles");
  // // const handleTabSelect = (selectedTab) => {
  // //   setActiveTab(selectedTab);
  // // };

  // const handleTabChange = (key) => {
  //   console.log("key:", key);
  //   setActiveTab(key);
  // };

  // const [FirstcurrPage, setFirstCurrPage] = useState(1);
  // const [FirstList, setFirstList] = useState([]);
  // const [FirstwasLastList, setFirstWasLastList] = useState(false);
  // const [FirsttotalItems, setFirstTotalItems] = useState(0); // Add state for total items

  // const [SecondcurrPage, setSecondCurrPage] = useState(1);
  // const [SecondList, setSecondList] = useState([]);
  // const [SecondwasLastList, setSecondWasLastList] = useState(false);
  // const [SecondtotalItems, setSecondTotalItems] = useState(0); // Add state for total items

  // const FirstpaginateNext = () => {
  //   // If the number of items is equal to the page size, there may be a next page
  //   if (FirstList.length === pageSize && !FirstwasLastList) {
  //     setFirstCurrPage((prev) => prev + 1);
  //   }
  // };

  // const SecondpaginateNext = () => {
  //   // If the number of items is equal to the page size, there may be a next page
  //   if (SecondList.length === pageSize && !SecondwasLastList) {
  //     setSecondCurrPage((prev) => prev + 1);
  //   }
  // };

  // const FirstpaginatePrevious = () => {
  //   if (FirstcurrPage > 1) {
  //     setFirstCurrPage((prev) => prev - 1);
  //   }
  // };

  // const SecondpaginatePrevious = () => {
  //   if (SecondcurrPage > 1) {
  //     setSecondCurrPage((prev) => prev - 1);
  //   }
  // };

  // const updateFirstPaginationState = (items, total) => {
  //   setFirstList(items);
  //   setFirstTotalItems(total);
  //   // Determine if this is the last list by checking if we have fewer items than the page size
  //   setFirstWasLastList(items.length < pageSize);
  // };

  // const updateSecondPaginationState = (items, total) => {
  //   setSecondList(items);
  //   setSecondTotalItems(total);
  //   // Determine if this is the last list by checking if we have fewer items than the page size
  //   setSecondWasLastList(items.length < pageSize);
  // };

  // const fetchDataList = () => {
  //   setIsLoading(true);
  //   UserService.getAadEmployeeSync(FirstcurrPage, pageSize)
  //     .then((response) => {
  //       console.log("fetchData1:", response.data);

  //       updateFirstPaginationState(
  //         response.data.items,
  //         response.data.totalItems
  //       );

  //       setFirstList(response.data.items);
  //       setFirstTotalItems(response.data.total);
  //       if (response.data.items.length === 0 && FirstcurrPage > 1) {
  //         setFirstCurrPage((prev) => prev - 1);
  //       } else if (response.data.totalItems <= pageSize * (FirstcurrPage - 1)) {
  //         setFirstCurrPage((prev) => prev - 1);
  //       }
  //       setFirstWasLastList(response.data.items.length < pageSize);
  //     })
  //     .catch((error) => {
  //       console.error("Error fetching banners:", error);
  //     })
  //     .finally(() => {
  //       setIsLoading(false);
  //     });
  // };

  // const fetchDataList2 = () => {
  //   // setIsLoading2(true);
  //   UserService.getEmployeeSync(SecondcurrPage, pageSize)
  //     .then((response) => {
  //       console.log("fetchData2:", response.data);

  //       updateSecondPaginationState(
  //         response.data.items,
  //         response.data.totalItems
  //       );

  //       setSecondList(response.data.items);
  //       setSecondTotalItems(response.data.total);
  //       if (response.data.items.length === 0 && SecondcurrPage > 1) {
  //         setSecondCurrPage((prev) => prev - 1);
  //       } else if (
  //         response.data.totalItems <=
  //         pageSize * (SecondcurrPage - 1)
  //       ) {
  //         setSecondCurrPage((prev) => prev - 1);
  //       }
  //       setSecondWasLastList(response.data.items.length < pageSize);
  //     })
  //     .catch((error) => {
  //       console.error("Error fetching banners:", error);
  //     })
  //     .finally(() => {
  //       // setIsLoading2(false);
  //     });
  // };

  // useEffect(() => {
  //   if (currentUser && FirstcurrPage === 1) {
  //     fetchDataList();
  //   }
  // }, [FirstcurrPage, currentUser]);

  const fetchData = async () => {
    console.log("fetchData");
    //   // UserService.getEmployeeSync()
    //   //   .then((response) => {
    //   //     if (!response.data.items.length) {
    //   //       setWasLastList(true);
    //   //       return;
    //   //     }

    //   //     console.log("fetchDataTest:", response.data.items);

    //   //     setUserData(response.data.items);
    //   //     setIsLoading2(false);
    //   //   })
    //   //   .catch((error) => {
    //   //     console.error(error.message);
    //   //     setFetchError(true); // Set the fetchError state to true
    //   //   });

    // fetchDataList2();

    CoreService.getEmployements()
      .then((response) => {
        setEmployementType(response.data.items);
      })
      .catch((error) => {
        console.log(error);
      });

    // CoreService.getGroups()
    //   .then((response) => {
    //     setGroupType(response.data.items);
    //   })
    //   .catch((error) => {
    //     console.log(error);
    //     setFetchError(true); // Set the fetchError state to true
    //   });
  };

  // const fetchData1 = async () => {
  //   console.log("fetchData1");
  //   // UserService.getAadEmployeeSync(FirstcurrPage, pageSize)
  //   //   .then((response) => {
  //   //     console.log("fetchData1:", response.data.items);
  //   //     setUserData1(response.data.items);
  //   //     setTotalItems(response.data.total);
  //   //   })
  //   //   .catch((error) => {
  //   //     console.error(error.message);
  //   //     console.log("Current Items Length:", currentItems.length);
  //   //     console.log("Items Per Page:", itemsPerPage);
  //   //     console.log("Total Data Length:", userData1.length);
  //   //     console.log("Current Page:", FirstcurrPage);
  //   //   });

  //   if (FirstcurrPage > 1) {
  //     fetchDataList();
  //   }

  //   CoreService.getEmployements()
  //     .then((response) => {
  //       if (!response.data.items.length) {
  //         setWasLastList(true);
  //         return;
  //       }
  //       setEmployementType(response.data.items);
  //     })
  //     .catch((error) => {
  //       console.log(error);
  //       setFetchError(true); // Set the fetchError state to true
  //     });

  //   CoreService.getGroups()
  //     .then((response) => {
  //       // console.log("ABC: ", JSON.stringify(response.data));
  //       setGroupType(response.data.items);
  //     })
  //     .catch((error) => {
  //       console.log(error);
  //     });
  // };

  // // const handlePageChange = (pageNumber) => {
  // //   setCurrentPage(pageNumber);
  // //   fetchData1();
  // // };

  // // Function to handle image error
  const handleImageError = (e) => {
    e.target.src = defaultAvatarURL; // Set the default image URL on error
  };

  useEffect(() => {
    fetchData();
  }, [modal2]);

  // const itemsArray = Array.from({ length: 8 }, (_, index) => index);

  const ProfilePicBase =
    "https://evolutyzblobimages.blob.core.windows.net/unmaze/";

  const defaultAvatarURL =
    "https://ezcorner.evolutyz.com/uploadimages/images/User.png";

  // const [isError, setisError] = useState(false);

  // // Function to handle the click event and change the value
  // const handleClickABV = (itemId) => {
  //   const updatedItems = userData.map((item) =>
  //     item.id === itemId ? { ...item, image: defaultAvatarURL } : item
  //   );
  //   setUserData([...updatedItems]); // Spread into a new array
  // };

  // const handleClickABC = (itemId) => {
  //   const updatedItems = userData1.map((item) =>
  //     item.id === itemId ? { ...item, image: defaultAvatarURL } : item
  //   );
  //   setUserData1([...updatedItems]); // Spread into a new array
  // };

  // // const shouldShowPagination = totalItems > itemsPerPage && !isLastPage;

  const handleRowsPerPageChange = (event) => {
    const newPageSize = parseInt(event.target.value, 10);
    setRowsPerPage(newPageSize);
    setCurrPage(1); // Reset current page to the first page
    // fetchBannerList(); // Trigger a new API call with the updated page size
    tableRef.current.onQueryChange();
  };

  const handleChnageRowsPerPage = (e) => {
    console.log("Im here");
    setRowsPerPage(e.target.value);

    tableRef.current.onChangePage(null, 0);

    // tableRef.current && tableRef.current.onQueryChange();
  };

  // const fetchData = async () => {
  //   setIsLoading(true);
  //   try {
  //     const response = await UserService.getUsers(
  //       page + 1,
  //       rowsPerPage,
  //       searchTerm
  //     );
  //     setUserList(response.data.items);
  //     setTotalItems(response.data.total);
  //     // setWasLastList(response.data.items.length < pageSize);
  //   } catch (error) {
  //     console.error("Error fetching users:", error);

  //     if (error.response) {
  //       // The request was made and the server responded with a status code >= 400
  //       console.error("Error:", error.response.data);
  //       console.error("Status Code:", error.response.status);
  //       FailedNotification(error.response.data.detail);
  //     } else if (error.request) {
  //       // The request was made but no response was received
  //       console.error("No response received:", error.request);
  //     } else {
  //       // Something else happened while setting up the request
  //       console.error("Error:", error.message);
  //       FailedNotification(error.message);
  //     }
  //   } finally {
  //     setIsLoading(false);
  //   }
  // };

  const handleSearch = useDebounce((term) => {
    // Perform search operation with the debounced term
    console.log("Searching for:", term);
  }, 500);

  const handleChange = (event) => {
    const { value } = event.target;

    console.log("handlrchnage: ", value);
    setSearchTerm(value);

    // Debounce the search callback
    handleSearch(value);
  };

  return (
    <>
      <div className="page-wrapper" style={{ backgroundColor: "white" }}>
        <div className="content">
          <div className="page-header">
            <div className="row align-items-center">
              <div className="col">
                <h3 className="page-title">Users</h3>
                <ul className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/home">
                      <span>
                        <i className="la la-home"></i> Home
                      </span>
                    </Link>
                  </li>
                  <li className="breadcrumb-item active">
                    <i className="la la-users-cog mr-1"></i>Users
                  </li>
                </ul>
              </div>
              <div className="col-auto float-right ml-auto">
                {toggleSpinner ? (
                  <button
                    // onClick={handleClick}
                    className="btn add-btn"
                    disabled
                  >
                    <Beatloader />
                  </button>
                ) : (
                  <button
                    type="submit"
                    onClick={handleClick}
                    className="btn add-btn"
                    style={{
                      textTransform: "capitalize",
                    }}
                  >
                    SYNC USERS
                    {/* <ToastContainer /> */}
                  </button>
                )}
              </div>
            </div>
          </div>
          <Tab.Container
            id="left-tabs-example"
            defaultActiveKey="listViewProfiles2"
          >
            {/* <div className="page-header">
              <div className="row justify-content-end">
                <div className="col-sm-6 col-md-4">
                  <div className="top-nav-search position-relative form-focus">
                    <input
                      className="form-control"
                      type="text"
                      placeholder="Search"
                      value={searchTerm}
                      onChange={(e) => setSearchTerm(e.target.value)}
                      style={{ borderColor: "#ff9b44" }} // Inline style for border color
                    />
                    <button className="btn" type="submit">
                      <i
                        className="fa fa-search"
                        style={{ color: "#ff9b44" }}
                      ></i>
                    </button>
                  </div>
                </div>

                <div
                  className="col-md-2 float-right"
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    alignItems: "center",
                  }}
                >
                  <Nav as="ul" variant="pills" className="view-icons">
                    <Nav.Item as="li">
                      <Nav.Link
                        eventKey="listViewProfiles2"
                        className="grid-view btn"
                      >
                        <i className="fa fa-bars"></i>
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item as="li">
                      <Nav.Link
                        eventKey="gridViewProfiles2"
                        className="grid-view btn"
                      >
                        <i className="fa fa-th"></i>
                      </Nav.Link>
                    </Nav.Item>
                  </Nav>
                </div>
              </div>
            </div> */}
            <Tab.Content>
              <Tab.Pane eventKey="listViewProfiles2">
                <div className="row">
                  <ThemeProvider theme={defaultMaterialTheme}>
                    <div style={{ width: "100%" }}>
                      <MaterialTable
                        title="Group Preview"
                        key={refreshKey}
                        tableRef={tableRef}
                        columns={columns}
                        data={(query) =>
                          new Promise((resolve, reject) => {
                            let url =
                              process.env.REACT_APP_BACKEND_URL + "/users";
                            url += "?page=" + (query.page + 1);
                            url += "&size=" + rowsPerPage;
                            // Add search query if available
                            if (query.search && query.search.length > 0) {
                              console.log("search: ", query.search);
                              url += "&search=" + query.search;
                            }
                            fetch(url, {
                              headers: {
                                Authorization: `Bearer ${getCustomToken()}`,
                              },
                            })
                              .then((response) => response.json())
                              .then((result) => {
                                console.info("RESULT:", result);
                                setIsLoading(false); // Hide loading indicator after fetching

                                resolve({
                                  data: result.items,
                                  page: result.page - 1,
                                  totalCount: result.total,
                                });
                              })
                              .catch((error) => {
                                console.error("Error fetching groups:", error);
                              })
                              .finally(() => {
                                setIsLoading(false);
                              });
                          })
                        }
                        icons={tableIcons}
                        options={{
                          headerStyle: { backgroundColor: "#ffedde" },
                          showTitle: false,
                          paging: true,
                          search: true,
                          sorting: false,
                          actionsColumnIndex: -1,

                          exportButton: false,
                          paginationType: "normal",
                          pageSize: rowsPerPage,
                          // pageSizeOptions: [5, 10, 15, 30],
                          emptyRowsWhenPaging: false,

                          actionsCellStyle: {
                            justifyContent: "center",
                          },
                        }}
                        localization={{
                          header: {
                            actions: "",
                          },
                        }}
                        // onChangeRowsPerPage={(event) => {
                        //   setRowsPerPage(event.target.value);
                        // }}
                        onSearchChange={handleChange}
                        searchText={searchTerm}
                        components={{
                          Pagination: (props) => (
                            <TablePagination
                              {...props}
                              rowsPerPageOptions={[5, 10, 15]}
                              rowsPerPage={rowsPerPage}
                              onRowsPerPageChange={handleChnageRowsPerPage}
                            />
                          ),
                        }}
                        actions={[
                          (rowData) => ({
                            icon: "edit",
                            tooltip: "edit user",
                            onClick: (event, rowData) => {
                              handleShow2(rowData);
                            },

                            disabled: rowData.id === currentUser?.id,
                          }),
                          {
                            icon: "refresh",
                            tooltip: "Refresh Data",
                            isFreeAction: true,
                            onClick: () =>
                              tableRef.current &&
                              tableRef.current.onQueryChange(),
                          },
                        ]}
                        // components={{
                        //   Pagination: (subProps) => {
                        //     return (
                        //       <Box
                        //         display="flex"
                        //         justifyContent="flex-end"
                        //         alignItems="center"
                        //       >
                        //         <div style={{ paddingRight: 10 }}>
                        //           Rows per page:{"  "}
                        //         </div>
                        //         <select
                        //           value={rowsPerPage}
                        //           disabled
                        //           onChange={handleRowsPerPageChange}
                        //         >
                        //           {[5, 10, 15, 30].map((pageSizeOption) => (
                        //             <option
                        //               key={pageSizeOption}
                        //               value={pageSizeOption}
                        //             >
                        //               {pageSizeOption}
                        //             </option>
                        //           ))}
                        //         </select>
                        //         <Box>
                        //           <MTablePagination {...subProps} />
                        //         </Box>
                        //       </Box>
                        //     );
                        //   },
                        // }}
                      />
                    </div>
                  </ThemeProvider>
                </div>
                {/* <nav
                        aria-label="Page navigation example"
                        className="pagination-container "
                      >
                        <div
                          className="pagination"
                          style={{ border: "1px solid orange" }}
                        >
                          <button
                            style={{ margin: "0px" }}
                            disabled={currentPage === 1}
                            onClick={() => setCurrentPage(currentPage - 1)}
                          >
                            {"<<"}
                          </button>

                          {pageNumbers.map((number) => (
                            <button
                              key={number}
                              style={{
                                margin: "0px",
                                backgroundColor:
                                  currentPage === number
                                    ? "#f58423"
                                    : "transparent",
                                color:
                                  currentPage === number ? "white" : "black",
                              }}
                              onClick={() => setCurrentPage(number)}
                            >
                              {number}
                            </button>
                          ))}

                          <button
                            style={{ margin: "0px" }}
                            disabled={currentPage === totalPages}
                            onClick={() => setCurrentPage(currentPage + 1)}
                          >
                            {">>"}
                          </button>
                        </div>
                      </nav> */}

                <nav
                  aria-label="Page navigation example"
                  className="pagination-container"
                >
                  {/* <Pagination
                    itemsPerPage={pageSize}
                    totalItems={SecondtotalItems}
                    paginateNext={SecondpaginateNext}
                    paginatePrevious={SecondpaginatePrevious}
                    currentPage={SecondcurrPage}
                  /> */}
                </nav>
              </Tab.Pane>

              <Tab.Pane eventKey="gridViewProfiles2">
                <div className="row staff-grid-row">
                  {/* <>
                    {SecondList.length > 0 &&
                      SecondList.map((req, index) => (
                        <>
                          <div
                            className="col-md-4 col-sm-6 col-12 col-lg-4 col-xl-3"
                            key={index}
                          >
                            <div className="profile-widget">
                              <div
                                className="profile-img"
                                onClick={() => {
                                  handleShow(req);
                                }}
                              >
                                <span className="avatar">
                                  <img
                                    src={
                                      ProfilePicBase +
                                      req.AADUser.employeeID +
                                      ".jpg"
                                    }
                                    alt={`${req?.AADUser.employeeID}'s profile picture`}
                                    onError={handleImageError} // Use the error handler
                                  />
                                </span>
                              </div>
                              <h4 className="user-name m-t-10 mb-0 text-ellipsis">
                                {req.User.display_name || "NA"}
                              </h4>
                              <div className="small text-muted">
                                {req.User.email_id}
                              </div>

                              <Dropdown className="profile-action" as="div">
                                <Dropdown.Toggle
                                  className="nav-link action-icon"
                                  as="a"
                                  id="dropdown-basic1"
                                >
                                  <i className="fa fa-ellipsis-v"></i>
                                </Dropdown.Toggle>

                                <Dropdown.Menu className="dropdown-menu-right">
                                  <Dropdown.Item
                                    href="#"
                                    data-toggle="modal"
                                    data-target="#edit_employee"
                                    onClick={() => {
                                      handleShow2(req);
                                    }}
                                  >
                                    <i className="fa fa-pencil m-r-5"></i> Edit
                                  </Dropdown.Item>
                                </Dropdown.Menu>
                              </Dropdown>
                            </div>
                          </div>
                        </>
                      ))}
                  </> */}
                </div>

                <nav
                  aria-label="Page navigation example"
                  className="pagination-container"
                >
                  {/* <Pagination
                    itemsPerPage={pageSize}
                    totalItems={SecondtotalItems}
                    paginateNext={SecondpaginateNext}
                    paginatePrevious={SecondpaginatePrevious}
                    currentPage={SecondcurrPage}
                  /> */}
                </nav>
              </Tab.Pane>
            </Tab.Content>
          </Tab.Container>

          {/* <Tab eventKey="employeeSync" title="Employee Sync">
              <Tab.Container
                id="left-tabs-example"
                defaultActiveKey="syncListView"
              >
                {/* <div className="page-header">
                  <div className="row align-items-center ">
                    <div className="col-auto float-right ml-auto">
                      {/* Adding search bar here */}
          {/* <div className="d-flex justify-content-end flex-grow-1">
                        <div className="form-group mb-0 mr-3 flex-grow-1">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Search Employees"
                            value={searchTerm}
                            onChange={handleSearchInputChange}
                          />
                        </div>
                      </div> */}
          {/* End of search bar */}
          {/* <Nav as="ul" variant="pills" className="view-icons">
                        <Nav.Item as="li">
                          <Nav.Link
                            eventKey="syncListView"
                            className="grid-view btn"
                          >
                            <i className="fa fa-th"></i>
                          </Nav.Link>
                        </Nav.Item>
                        <Nav.Item as="li">
                          <Nav.Link
                            eventKey="syncGridView"
                            className="grid-view btn"
                          >
                            <i className="fa fa-bars"></i>
                          </Nav.Link>
                        </Nav.Item>
                      </Nav>
                    </div>
                  </div>
                </div> */}
          {/* <SearchBar /> */}

          {/* <Tab.Content> */}
          {/* <Tab.Pane eventKey="syncListView">
                    <div className="row staff-grid-row">
                      {isLoading ? (
                        <div
                          className="row align-items-center justify-content-center"
                          style={{
                            flexDirection: "column",
                            // backgroundColor: "red",
                            width: "100%",
                          }}
                        >
                          <Load />
                        </div>
                      ) : (
                        <>
                          {Loader && FirstList.length === 0 && (
                            <div
                              className="row align-items-center justify-content-center"
                              style={{
                                flexDirection: "column",
                                width: "100%",
                                height: "60vh",
                              }}
                            >
                              <div
                                className="row align-items-center justify-content-center"
                                style={{ width: "100%" }}
                              >
                                <div
                                  className="col-md-6 text-center"
                                  style={{
                                    textTransform: "uppercase",
                                  }}
                                >
                                  <span style={{ color: "#ff9b44" }}>
                                    Sync users
                                  </span>
                                </div>
                              </div>

                              <div
                                className="row align-items-center justify-content-center"
                                style={{ width: "100%" }}
                              >
                                <div
                                  className="col-md-6 text-center"
                                  style={{ textTransform: "uppercase" }}
                                >
                                  <img
                                    src={require("../assets/img/undraw_No_data_re_kwbl.png")}
                                    alt="React Logo"
                                    height={100}
                                  />
                                </div>
                              </div>
                            </div>
                          )}

                          {isLoading &&
                            itemsArray.map((_, index) => (
                              <>
                                <div className="col-md-4 col-sm-6 col-12 col-lg-4 col-xl-3">
                                  <div className="profile-widget">
                                    <div className="profile-img">
                                      <Skeleton circle height={"100%"} />
                                    </div>
                                    <h4 className="user-name m-t-10 mb-0 text-ellipsis">
                                      <Skeleton />
                                    </h4>
                                  </div>
                                </div>
                              </>
                            ))}

                          {FirstList.length > 0 &&
                            FirstList.map((req, index) => {
                              console.log(`Hello ${index}`);

                              return (
                                <>
                                  <div
                                    className="col-md-4 col-sm-6 col-12 col-lg-4 col-xl-3"
                                    key={index}
                                  >
                                    <div className="profile-widget">
                                      <div>
                                        <div className="profile-img">
                                          <span className="avatar">
                                            <img
                                              src={
                                                ProfilePicBase +
                                                req.employeeID +
                                                ".jpg"
                                              }
                                              alt={`${req?.employeeID}'s profile picture`}
                                              onError={handleImageError} // Use the error handler
                                            />
                                          </span>
                                        </div>

                                        <h4 className="user-name m-t-10 mb-0 text-ellipsis">
                                          {req.display_name}
                                        </h4>
                                      </div>

                                      <Dropdown
                                        className="profile-action"
                                        as="div"
                                      >
                                        <Dropdown.Toggle
                                          className="nav-link action-icon"
                                          as="a"
                                          id="dropdown-basic1"
                                        >
                                          <i className="fa fa-ellipsis-v"></i>
                                        </Dropdown.Toggle>

                                        <Dropdown.Menu className="dropdown-menu-right">
                                          <Dropdown.Item
                                            href="#"
                                            onClick={() => {
                                              handleShow1(req);
                                            }}
                                          >
                                            <i className="fa fa-pencil m-r-5"></i>{" "}
                                            ADD GROUP
                                          </Dropdown.Item>
                                        </Dropdown.Menu>
                                      </Dropdown>
                                    </div>
                                  </div>
                                </>
                              );
                            })}
                        </>
                      )}
                    </div>
                    <nav
                      aria-label="Page navigation example"
                      className="pagination-container"
                    >
                      <Pagination
                        itemsPerPage={pageSize}
                        totalItems={FirsttotalItems}
                        paginateNext={FirstpaginateNext}
                        paginatePrevious={FirstpaginatePrevious}
                        currentPage={FirstcurrPage}
                      />
                    </nav>
                  </Tab.Pane> */}
          {/* <Tab.Pane eventKey="syncGridView">
                    <div className="staff-list-row">
                      <div className="table-responsive">
                        <div className="col-md-12 my-2">
                          {isLoading ? (
                            <div
                              className="row align-items-center justify-content-center"
                              style={{
                                flexDirection: "column",
                                // backgroundColor: "red",
                                width: "100%",
                              }}
                            >
                              <Load />
                            </div>
                          ) : (
                            <>
                              {Loader && FirstList.length === 0 && (
                                <div
                                  className="row align-items-center justify-content-center"
                                  style={{
                                    flexDirection: "column",
                                    width: "100%",
                                    height: "60vh",
                                  }}
                                >
                                  <div
                                    className="row align-items-center justify-content-center"
                                    style={{ width: "100%" }}
                                  >
                                    <div
                                      className="col-md-6 text-center"
                                      style={{
                                        textTransform: "uppercase",
                                      }}
                                    >
                                      <span style={{ color: "#ff9b44" }}>
                                        Sync users
                                      </span>
                                    </div>
                                  </div>

                                  <div
                                    className="row align-items-center justify-content-center"
                                    style={{ width: "100%" }}
                                  >
                                    <div
                                      className="col-md-6 text-center"
                                      style={{ textTransform: "uppercase" }}
                                    >
                                      <img
                                        src={require("../assets/img/undraw_No_data_re_kwbl.png")}
                                        alt="React Logo"
                                        height={100}
                                      />
                                    </div>
                                  </div>
                                </div>
                              )}

                              {isLoading &&
                                itemsArray.map((_, index) => (
                                  <>
                                    <div className="col-md-4 col-sm-6 col-12 col-lg-4 col-xl-3">
                                      <div className="profile-widget">
                                        <div className="profile-img">
                                          <Skeleton circle height={"100%"} />
                                        </div>
                                        <h4 className="user-name m-t-10 mb-0 text-ellipsis">
                                          <Skeleton />
                                        </h4>
                                      </div>
                                    </div>
                                  </>
                                ))}

                              {FirstList.length > 0 && (
                                <table className="table custom-table mb-0 datatable">
                                  <thead>
                                    <tr>
                                      <th></th>
                                      <th>Name</th>
                                      <th>Email</th>
                                      <th className="text-right no-sort">
                                        Action
                                      </th>
                                    </tr>
                                  </thead>

                                  {FirstList.length > 0 &&
                                    FirstList.map((req, index) => (
                                      <>
                                        <tbody key={index}>
                                          <tr
                                            style={{ backgroundColor: "white" }}
                                          >
                                            <td>
                                              <h2 className="table-avatar">
                                                <span className="avatar">
                                                  <img
                                                    src={
                                                      ProfilePicBase +
                                                      req.employeeID +
                                                      ".jpg"
                                                    }
                                                    alt={`${req?.employeeID}'s profile picture`}
                                                    onError={handleImageError} // Use the error handler
                                                  />
                                                </span>
                                              </h2>
                                            </td>

                                            <td>
                                              <span>{req.display_name}</span>
                                            </td>
                                            <td>{req.email}</td>

                                            <td className="text-right">
                                              <Dropdown
                                                className="dropdown-action"
                                                as="div"
                                              >
                                                <Dropdown.Toggle
                                                  className="nav-link action-icon"
                                                  as="a"
                                                  id="dropdown-basic1"
                                                >
                                                  <i className="material-icons">
                                                    more_vert
                                                  </i>
                                                </Dropdown.Toggle>

                                                <Dropdown.Menu className="dropdown-menu-right">
                                                  <Dropdown.Item
                                                    href="#"
                                                    data-toggle="modal"
                                                    data-target="#edit_employee"
                                                    onClick={() => {
                                                      handleShow1(req);
                                                    }}
                                                  >
                                                    <i className="fa fa-pencil m-r-5"></i>{" "}
                                                    ADD GROUP
                                                  </Dropdown.Item>
                                                </Dropdown.Menu>
                                              </Dropdown>
                                            </td>
                                          </tr>
                                        </tbody>
                                      </>
                                    ))}
                                </table>
                              )}
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                    <nav
                      aria-label="Page navigation example"
                      className="pagination-container"
                    >
                      <div
                        className="pagination"
                        style={{
                          border: "1px solid white",
                          alignContent: "center",
                        }}
                      >
                        {/* Previous button */}
          {/* <div>
                          <Pagination
                            itemsPerPage={pageSize}
                            totalItems={FirsttotalItems}
                            paginateNext={FirstpaginateNext}
                            paginatePrevious={FirstpaginatePrevious}
                            currentPage={FirstcurrPage}
                          />
                        </div>
                      </div>
                    </nav>
                  </Tab.Pane>
                </Tab.Content>
              </Tab.Container>
            </Tab>  */}

          {/* assign group modal */}
          {/* <Modal
            show={modal1}
            onHide={handleClose1}
            backdrop="static"
            keyboard={false}
            className="modal-right"
            style={{ width: "7000vh" }}
          >
            <div className="modal-header">
              <h5 className="modal-title">Assign Group &amp; Role</h5>
              <button
                type="button"
                className="close"
                onClick={handleClose1}
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <form className="fullStretch" onSubmit={validateForm}>
                <div className="form-group">
                  <label>&nbsp;</label>
                  <div className="col-md-12">
                    <div className="form-group form-border">
                      <label>Name:</label>

                      <div
                        className="form-control"
                        name="sel_reward_applicable"
                        style={{ paddingTop: "4%" }}
                      >
                        {ActiveItem1.display_name}
                      </div>
                    </div>
                  </div>

                  <div className="col-md-12">
                    <div className="form-group form-border">
                      <label>Email:</label>

                      <div
                        className="form-control"
                        name="sel_reward_applicable"
                        style={{ paddingTop: "4%" }}
                      >
                        {ActiveItem1.email}
                      </div>
                    </div>
                  </div>

                  <div className="col-md-12">
                    <div className="form-group form-border">
                      <label>
                        Group:<span style={{ color: "red" }}> * </span>
                        <span style={{ color: "red", fontSize: "12px" }}>
                          {errors.group && errors.group}
                        </span>
                      </label>

                      <select
                        className="form-control"
                        name="group"
                        value={formData.group}
                        onChange={changeHandler}
                      >
                        <option value={""}>{"SELECT".toUpperCase()}</option>

                        {GroupType.map((group, index) => (
                          <>
                            <option value={group.id} key={index}>
                              {group.name.toUpperCase()}
                            </option>
                          </>
                        ))}
                      </select>
                    </div>
                  </div>

                  <div className="col-md-12">
                    <div className="form-group form-border">
                      <label>
                        Employment:
                        <span style={{ color: "red" }}> * </span>
                        <span style={{ color: "red", fontSize: "12px" }}>
                          {errors.employment && errors.employment}
                        </span>
                      </label>

                      <select
                        className="form-control"
                        name="employment"
                        onChange={changeHandler}
                      >
                        <option value={""}>{"SELECT".toUpperCase()}</option>
                        {EmployementType.map((employment, index) => (
                          <>
                            <option value={employment.id} key={index}>
                              {employment.name.toUpperCase()}
                            </option>
                          </>
                        ))}
                      </select>
                    </div>
                  </div>

                  <div className="col-md-12">
                    <div className="form-group form-border">
                      <label>Is Admin</label>

                      <div
                        className="form-control"
                        name="is_admin"
                        style={{
                          paddingTop: "4%",
                          flexDirection: "row",
                          justifyContent: "space-between",
                          height: "10%",
                        }}
                      >
                        <div style={{ flexDirection: "row" }}>
                          <input
                            type="radio"
                            value="true"
                            checked={formData.is_admin === true}
                            name="is_admin"
                            onChange={changeHandler}
                          />{" "}
                          <span>Yes</span>
                        </div>

                        <div>
                          <input
                            type="radio"
                            value="false"
                            checked={formData.is_admin === false}
                            name="is_admin"
                            onChange={changeHandler}
                          />{" "}
                          <span>No</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="modal-footer text-center">
                  {toggleSpinner ? (
                    <button
                      // onClick={handleClick}
                      className="btn add-btn"
                      disabled
                    >
                      <Beatloader />
                    </button>
                  ) : (
                    <button
                      type="submit"
                      // onClick={handleClick}
                      className="btn add-btn"
                    >
                      Add
                    </button>
                  )}
                </div>
              </form>
            </div>
          </Modal> */}

          {/* Profile Show Modal */}
          {/* <Modal
            show={modal}
            onHide={handleClose}
            backdrop="static"
            keyboard={false}
            size="lg"
            centered
            style={{
              zIndex: 9999,
            }}
          >
            <div className="modal-backdrop">
              <div className="modal-content">
                <div className="modal-header">
                  <button onClick={handleClose} className="close-modal-button">
                    X
                  </button>
                </div>
                <div className="modal-body">
                  <div className="profile-center">
                    <div className="profile-avatar">
                      <img
                        src={
                          ProfilePicBase +
                          ActiveItem.AADUser?.employeeID +
                          ".jpg"
                        }
                        alt={`${ActiveItem.AADUser?.employeeeID}'s profile picture`}
                        onError={handleImageError} // Use the error handler
                      />
                    </div>
                    <h3>{ActiveItem?.display_name}</h3>
                    <div className="profile-details">
                      <p>
                        <i className="fa fa-envelope"></i> Email:{" "}
                        {ActiveItem.User?.email_id}
                      </p>
                      <p>
                        <i className="fa fa-mobile"></i> Phone:{" "}
                        {ActiveItem.AADUser?.mobile_number
                          ? ActiveItem.AADUser?.mobile_number
                          : "N/A"}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Modal> */}

          {/* update user group details */}
          <Modal
            show={modal2}
            onHide={handleClose2}
            backdrop="static"
            keyboard={false}
            dialogClassName="modal-90w"
            className="modal-right m-0"
          >
            <div className="modal-header">
              <h5 className="modal-title">Update user details</h5>
              <button
                type="button"
                className="close"
                onClick={handleClose2}
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <form className="fullStretch" onSubmit={validateForm2}>
                <div className="form-group">
                  <label>&nbsp;</label>
                  <div className="col-md-12">
                    <div className="form-group form-border">
                      <label>
                        Name:<span style={{ color: "red" }}> * </span>
                        <span style={{ color: "red", fontSize: "12px" }}>
                          {errors2.display_name && errors2.display_name}
                        </span>
                      </label>
                      <div
                        className="form-control"
                        name="sel_reward_applicable"
                        style={{ paddingTop: "4%" }}
                      >
                        {formData2.display_name || ""}
                      </div>

                      {/* <input
                        className="form-control"
                        name="display_name"
                        onChange={changeHandler2}
                        value={formData2.display_name || ""}
                      /> */}
                    </div>
                  </div>

                  <div className="col-md-12">
                    <div className="form-group form-border">
                      <label>Email:</label>

                      <div
                        className="form-control"
                        name="sel_reward_applicable"
                        style={{ paddingTop: "4%" }}
                      >
                        {formData2.email_id || ""}
                      </div>
                    </div>
                  </div>

                  {/* <div className="col-md-12">
                    <div className="form-group form-border">
                      <label>
                        Group:<span style={{ color: "red" }}> * </span>
                        <span style={{ color: "red", fontSize: "12px" }}>
                          {errors2.group_id && errors2.group_id}
                        </span>
                      </label>

                      <select
                        className="form-control"
                        name="group_id"
                        value={formData2.group_id}
                        onChange={changeHandler2}
                      >
                        <option value={""}>{"SELECT".toUpperCase()}</option>

                        {GroupType.map((group, index) => (
                          <>
                            <option value={group.id} key={index}>
                              {group.name.toUpperCase()}
                            </option>
                          </>
                        ))}
                      </select>
                    </div>
                  </div> */}

                  <div className="col-md-12">
                    <div className="form-group form-border">
                      <label>
                        Employment:
                        <span style={{ color: "red" }}> * </span>
                        <span style={{ color: "red", fontSize: "12px" }}>
                          {errors2.employment_id && errors2.employment_id}
                        </span>
                      </label>

                      <select
                        className="form-control"
                        name="employment_id"
                        onChange={changeHandler2}
                        value={formData2.employment_id}
                      >
                        <option value={""}>{"SELECT".toUpperCase()}</option>
                        {EmployementType.map((employment, index) => (
                          <>
                            <option value={employment.id} key={index}>
                              {employment.name.toUpperCase()}
                            </option>
                          </>
                        ))}
                      </select>
                    </div>
                  </div>

                  <div className="col-md-12">
                    <div className="form-group form-border">
                      <label>Is Admin</label>

                      <div
                        className="form-control"
                        name="is_admin"
                        style={{
                          paddingTop: "4%",
                          flexDirection: "row",
                          justifyContent: "space-between",
                          height: "10%",
                        }}
                      >
                        <div style={{ flexDirection: "row" }}>
                          <input
                            type="radio"
                            value={"true"}
                            checked={formData2.is_admin === true}
                            name="is_admin"
                            onChange={changeHandler2}
                          />{" "}
                          <span>Yes</span>
                        </div>

                        <div>
                          <input
                            type="radio"
                            value={"false"}
                            checked={formData2.is_admin === false}
                            name="is_admin"
                            onChange={changeHandler2}
                          />{" "}
                          <span>No</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="modal-footer text-center">
                  {toggleSpinner ? (
                    <button
                      // onClick={handleClick}
                      className="btn add-btn"
                      // disabled
                    >
                      <Beatloader />
                    </button>
                  ) : (
                    <button
                      type="submit"
                      // onClick={handleClick}
                      className="btn add-btn"
                    >
                      Update
                    </button>
                  )}
                </div>
              </form>
            </div>
          </Modal>
        </div>
      </div>
    </>
  );
}

export default Employees;
