import { useMsal } from "@azure/msal-react";
import React, { useEffect } from "react";
import { Cliploader } from "../components/Spinner";

function Logout() {
  const { instance } = useMsal();

  const clearCustomToken = () => {
    localStorage.removeItem("customToken");
  };

  useEffect(() => {
    clearCustomToken();
    instance.logoutRedirect({
      postLogoutRedirectUri: "/",
    });
  }, []);

  return (
    <div style={{ marginTop: "35%" }}>
      <div className="row">
        <div className="col-md-12 align-self-center text-center">
          <div className="d-flex justify-content-center align-items-center">
            <Cliploader />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Logout;
